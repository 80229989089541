import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Alert,
  AlertTitle,
  Snackbar,
} from "@mui/material";
import OtpInput from "react-otp-input";

import "../../login/styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";
import {
  setErrorMessage,
  setsuccessMessage,
} from "../../dataEntry/reducers/patientDataThunk";

import logo from "../../common/assets/images/avyantra_logo.png";
import prelogo from "../../common/assets/images/presco_logo.png";
import { VerifyRefDr,SendVerificationMessage } from "../reducers/signupDataThunk";
import { ToastContainer, toast } from "react-toastify";
//import { setsuccessMessage } from "../../Admin/reducers/wardThunk";

const EmailVerify = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [OTPForm, setOTPForm] = React.useState("");
  const [OTP, setOTP] = useState("");
  const patient = useSelector((state) => state.patient);
  const [seconds, setSeconds] = useState(0)
  const verifyKey = useParams();
  console.log('llllllllllll',verifyKey)
  const verificationKey = verifyKey.id;
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
  
        if (seconds === 0) {
            clearInterval(interval);
          } else {
            setSeconds(seconds - 1);
          }
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    });
  
    const resendOTP = () => {
      setSeconds(19);
      const req = { verification_key: verificationKey };
      //dispatch(SendVerificationMessage(req));
      dispatch(SendVerificationMessage(req)).then((resdata) => {
        toast.success(resdata.payload.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        //dispatch(setsuccessMessage(resdata.payload.message));
      });
    };

  useEffect(() => {
    if (verificationKey) {
      const req = { verification_key: verificationKey };
      dispatch(SendVerificationMessage(req));
    }
  }, [verificationKey]);
  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [patient.errorMessage]);

  useEffect(() => {
    if (patient.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [patient.successMessage]);

  const handleFormChange = (otp) => {
    // console.log("We are in handle form change");
    const fieldname = otp;
    // console.log("Field Name is ");
    // console.log(fieldname);

    const fieldvalue = otp;
    // console.log("Field Value is ");
    // console.log(fieldvalue);

    const newFormData = { ...OTPForm };
    newFormData[fieldname] = fieldvalue;
    setOTP(otp);
  };
  const loginSubmit = (e) => {
    e.preventDefault();

    const req = { verification_key: verificationKey, otp: OTP };
    dispatch(VerifyRefDr(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.error === false) {
          navigate("/email-verify-success/");
        } else {
          dispatch(setErrorMessage(resdata.message));
        }
      });
  };

  return (
    <React.Fragment>
          <ToastContainer />
      <Grid container>
        <Grid item xs={12} sm={12}>
        <Grid container style={{
              marginTop: "3%",
            }}>
            <Grid item xs={12} sm={12}>
              <img src={prelogo} className="pre_img" />
              <div className="pre_caption">
                Neonatal Sepsis Prediction Platform
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
            <Box className="login-container">
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                  marginTop: "3%",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  //columns={{ xs: 1, sm: 12, md: 6 }}
                >
                  <Card variant="outlined" className="login_radius">
                    <CardContent>
                      <p className="login_welcome">Verification Code</p>
                      <p className="forgot_cont">
                        Please enter the verification code sent to your Mobile
                      </p>

                      <form id="mother-medical-form" onSubmit={loginSubmit}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} className="OTP_Grid">
                            <OtpInput
                              numInputs={6}
                              separator={
                                <span style={{ paddingRight: 10 }}></span>
                              }
                              onChange={(otp) => handleFormChange(otp)}
                              otpType="number"
                              value={OTP}
                              size="Large"
                              id="username"
                              name="username"
                              inputStyle={{
                                width: "2.8em",
                                height: "2.8em",
                                borderRadius: "7px",
                                background: "#F6F5FA",
                                border: "0.75px",
                                fontSize: "15px",
                                fontWeight: "600",
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={12} style={{ marginBottom: 20 }}>
                            <p className="newAccReDirect">
                            {seconds > 0 ? (
                                <p>
                                  Time Remaining: 
                                  {seconds < 20 ? `${seconds}` : seconds}
                                </p>
                              ) : (
                                <p>Didn't recieve code?</p>
                              )}
                              <Link
                                onClick={() => seconds > 0 ? "" : resendOTP()}
                                disabled={seconds > 0}
                                style={{
                                  color: seconds > 0 ? "#gray" : ""
                                }}
    
                                className="signup_link"
                              >
                                Resend
                              </Link>
                            </p>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            className="OTP_Grid"
                            style={{ marginBottom: "10%" }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              className="sendcode_button sign-up-button"
                            >
                              Confirm
                            </Button>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <p className="newAccReDirect">
                              Don't have an account?
                              <Link to="/Signup">Sign Up</Link>
                            </p>
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <div className="signUpBg">
            <Grid className="copy-posi">
              <p className="Copyright">
                Copyright © 2019-2022 Avyantra Health Technologies. All Rights
                Reserved
              </p>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {patient.errorMessage && (
        <Snackbar
          open={patient.errorMessage}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {patient.errorMessage}
          </Alert>
        </Snackbar>
      )}
      {patient.successMessage && (
        <Snackbar
          open={patient.successMessage}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {patient.successMessage}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default EmailVerify;
