import { createAsyncThunk } from "@reduxjs/toolkit";
import PatientDataService from "../../api/apiCommonService";

export const createPatientProfile = createAsyncThunk(
  "patientCreation",
  async (formData) => PatientDataService.patientCreate(formData)
);
export const setRefDocData = createAsyncThunk(
  "refDocSelect",
  async (data) => data
);

export const setBabyData = createAsyncThunk("babyData", async (data) => data);

export const setMotherData = createAsyncThunk(
  "motherData",
  async (data) => data
);

export const setBabyHealthParameters = createAsyncThunk(
  "babyHealthParameters",
  async (data) => data
);

export const setBabyCNSData = createAsyncThunk(
  "babyCNSData",
  async (data) => data
);
export const setBabyGITData = createAsyncThunk(
  "babyGITData",
  async (data) => data
);
export const setBabyApperanceData = createAsyncThunk(
  "babyApperanceData",
  async (data) => data
);
export const setBabyRespiratoryData = createAsyncThunk(
  "babyRespiratoryData",
  async (data) => data
);

export const setBabyInvData = createAsyncThunk(
  "babyInvData",
  async (data) => data
);

export const setBabyAntibioticData = createAsyncThunk(
  "babyAntibioticData",
  async (data) => data
);

export const setBMRNData = createAsyncThunk("bmrnData", async (data) => data);

export const showBabyRecordForm = createAsyncThunk(
  "showBabyRecordForm",
  async (data) => data
);

export const setFormTab = createAsyncThunk("formTab", async (data) => data);

export const seteditBmrnData = createAsyncThunk(
  "isBmrnEdit",
  async (data) => data
);

export const setMotherEdit = createAsyncThunk(
  "isMotherEdit",
  async (data) => data
);
export const setBabyCVEdit = createAsyncThunk(
  "isBabyCVEdit",
  async (data) => data
);
export const setBabyFinalDiagEdit = createAsyncThunk(
  "isBabyFinalDiagEdit",
  async (data) => data
);
export const setBabyEdit = createAsyncThunk("isBabyEdit", async (data) => data);
export const setBabyCNSEdit = createAsyncThunk(
  "isBabyCNS",
  async (data) => data
);
export const setAddReferralData = createAsyncThunk(
  "referralDoctorData",
  async (data) => data
);
export const setBabyGITEdit = createAsyncThunk(
  "isBabyGIT",
  async (data) => data
);

export const setBabyApperanceEdit = createAsyncThunk(
  "isBabyApperance",
  async (data) => data
);
export const setBabyRespiratoryEdit = createAsyncThunk(
  "isBabyRespiratory",
  async (data) => data
);

export const setBabyInvEdit = createAsyncThunk(
  "isBabyInv",
  async (data) => data
);

export const setBabyAntibioticEdit = createAsyncThunk(
  "isBabyAntibiotic",
  async (data) => data
);

export const setErrorMessage = createAsyncThunk(
  "errorMessage",
  async (data) => data
);

export const setsuccessMessage = createAsyncThunk(
  "successMessage",
  async (data) => data
);

export const patientProfileSignUp = createAsyncThunk(
  "patientSignup",
  async (formData) => {
    const res = await PatientDataService.patientSignUp(formData);
    return res.data;
  }
);

export const setPatientDetails = createAsyncThunk(
  "patientDetailsSelected",
  async (data) => data
);

export const getPatientDetails = createAsyncThunk(
  "patientDetailsFetch",
  async (req) => {
    const res = await PatientDataService.getPatients(req);
    return res.data;
  }
);
export const getPatientBasicInfo = createAsyncThunk(
  "getPatientBasicInfo",
  async (req) => {
    const res = await PatientDataService.getPatientBasicInfo(req);
    return res.data;
  }
);

export const getIndividualBabyMedicalRecord = createAsyncThunk(
  "getIndividualBabyMedicalRecord",
  async (req) => {
    const res = await PatientDataService.getIndividualBabyMedicalRecord(req);
    return res.data;
  }
);

export const getDashboardListing = createAsyncThunk(
  "dashboardListing",
  async (req) => {
    const res = await PatientDataService.getDashboardListing(req);
    return res.data;
  }
);

export const getDashboardListingBySearch = createAsyncThunk(
  "dashboardListingBySearch",
  async (req) => {
    const res = await PatientDataService.getDashboardListingBySearch(req);
    return res.data;
  }
);

export const getPatientLevel = createAsyncThunk(
  "patientLevelFetch",
  async (patient_id) => {
    const res = await PatientDataService.getLevel(patient_id);
    return res.data;
  }
);

export const patientBasicInfoUpdated = createAsyncThunk(
  "patientBasicInfoUpdation",
  async (data) => {
    const res = await PatientDataService.patientBasicInfoUpdatedpost(data);
    return res.data;
  }
);

export const patientBasicInfoDupUpdation = createAsyncThunk(
  "patientBasicInfoDupUpdation",
  async (data, hospital_id) => {
    const res = await PatientDataService.patientBasicInfoDupUpdated(
      data,
      hospital_id
    );
    return res.data;
  }
);

export const patientGeneralInfoUpdation = createAsyncThunk(
  "patientGeneralInfoUpdation",
  async (data, loggedIdUserId) => {
    const res = await PatientDataService.patientGeneralInfoUpdated(
      data,

      loggedIdUserId
    );
    return res.data;
  }
);
export const maternalAddition = createAsyncThunk(
  "patientMotherInfoAddition",
  async (newFormData) => {
    const res = await PatientDataService.maternalAdd(newFormData);
    return res.data;
  }
);
export const createBabyHealthParameters = createAsyncThunk(
  "createBabyHealthParameters",
  async (req) => {
    const res = await PatientDataService.createBabyHealthParameters(req);
    return res.data;
  }
);

export const babyGitAddition = createAsyncThunk(
  "patientGITCreate",
  async (data) => {
    const res = await PatientDataService.babyGitAdd(data);
    return res.data;
  }
);
export const babyAppearAddition = createAsyncThunk(
  "patientAppearanceCreate",
  async (data) => {
    const res = await PatientDataService.babyAppearAdd(data);
    return res.data;
  }
);

export const babyRespAddition = createAsyncThunk(
  "patientRespCreate",
  async (data) => {
    const res = await PatientDataService.babyRespAdd(data);
    return res.data;
  }
);

export const babyCnsAddition = createAsyncThunk(
  "patientCnsCreate",
  async (data) => {
    const res = await PatientDataService.babyCnsAdd(data);
    return res.data;
  }
);

export const babyCvAddition = createAsyncThunk(
  "patientCvCreate",
  async (data) => {
    const res = await PatientDataService.babyCvAdd(data);
    return res.data;
  }
);
export const setBabyCVData = createAsyncThunk(
  "babyCVData",
  async (data) => data
);

export const setBabyFinalDiagData = createAsyncThunk(
  "babyFinalDiagData",
  async (data) => data
);

export const setCheckedBabyFinalDiag = createAsyncThunk(
  "checkedFinalDiagnosis",
  async (data) => data
);
export const setSelectedreading = createAsyncThunk(
  "setSelectedreading",
  async (data) => data
);

export const antibioticAddition = createAsyncThunk(
  "patientAntibioticCreate",
  async (data) => {
    const res = await PatientDataService.antibioticAdd(data);
    return res.data;
  }
);

export const babyInvestigationAddition = createAsyncThunk(
  "patientInvestigationCreate",
  async (data) => {
    const res = await PatientDataService.babyInvestigationAdd(data);
    return res.data;
  }
);

export const finalAddition = createAsyncThunk(
  "patientFinalCreate",
  async (data) => {
    const res = await PatientDataService.finalAdd(data);
    return res.data;
  }
);
export const generateScore = createAsyncThunk("generateScore", async (req) => {
  const res = await PatientDataService.finalAdd(req);
  return res.data;
});

export const updateScore = createAsyncThunk("updateScore", async (req) => {
  const res = await PatientDataService.updateScore(req);
  return res.data;
});

export const searchPatientDetails = createAsyncThunk(
  "patientDetailsSearch",
  async (obj) => {
    const res = await PatientDataService.searchPatient(obj);
    return res.data;
  }
);

export const createPatientNewReading = createAsyncThunk(
  "patientNewReadingCreate",
  async (data, loggedInUserId) => {
    const res = await PatientDataService.createNewReading(data, loggedInUserId);
    return res.data;
  }
);

export const getPatientNewReading = createAsyncThunk(
  "patientNewReadingFetch",
  async (study_id) => {
    const res = await PatientDataService.getNewReading(study_id);
    return res.data;
  }
);

export const getReadingid = createAsyncThunk(
  "getReadingid",
  async (studyId) => {
    const res = await PatientDataService.getReadingid(studyId);
    return res.data;
  }
);

export const updatePatientFormData = createAsyncThunk(
  "patientFormDataUpdate",
  async (req) => {
    const res = await PatientDataService.updateFormData(req);
    return res.data;
  }
);

export const getPatientLastReadingData = createAsyncThunk(
  "patientLastReadingDataFetch",
  async (study_id) => {
    const res = await PatientDataService.getLastReadingData(study_id);
    return res.data;
  }
);

export const babyProfileUpdate = createAsyncThunk(
  "patientProfileUpdate",
  async (bdgBmrn) => {
    const res = await PatientDataService.updateBabyProfile(bdgBmrn);
    return res.data;
  }
);

export const maternalProfileUpdate = createAsyncThunk(
  "patientMotherProfileUpdate",
  async (mpBmrn) => {
    const res = await PatientDataService.updateMaternalProfile(mpBmrn);
    return res.data;
  }
);

export const babyHealthParametersUpdate = createAsyncThunk(
  "patientHealthParametersUpdate",
  async (req) => PatientDataService.updateBabyHealthParameters(req)
);

export const babyPredictionScore = createAsyncThunk(
  "babyPredictionScore",
  async (req) => {
    const res = await PatientDataService.getScore(req);
    return res.data;
  }
);
export const babyNonInvasivePredictionScore = createAsyncThunk(
  "babyNonInvasivePredictionScore",
  async (req) => {
    const res = await PatientDataService.NonInvasiveScore(req);
    return res.data;
  }
);

export const babyQuickSepsisScorePrediction = createAsyncThunk(
  "babyQuickSepsisScorePrediction",
  async (req) => {
    const res = await PatientDataService.QuickSepsisScore(req);
    return res.data;
  }
);
export const babySemiInvasiveScorePrediction = createAsyncThunk(
  "babySemiInvasiveScorePrediction",
  async (req) => {
    const res = await PatientDataService.SemiInvasiveScore(req);
    return res.data;
  }
);
export const DownloadBabyBMRReport = createAsyncThunk(
  "downloadBabyBMRReport",
  async (req) => {
    const res = await PatientDataService.downloadBmrReport(req);
    return res.data;
  }
);
export const reqRefferralOpinion = createAsyncThunk(
  "reqRefferralOpinion",
  async (req) => {
    const res = await PatientDataService.sendForOpinion(req);
    return res.data;
  }
);

export const linkReferralDoctor = createAsyncThunk(
  "linkReferralDoctor",
  async (req) => {
    const res = await PatientDataService.linkReferralDoctor(req);
    return res.data;
  }
);

export const sendForRefDrOpinion = createAsyncThunk(
  "sendForRefDrOpinion",
  async (req) => {
    const res = await PatientDataService.sendForRefDrOpinion(req);
    return res.data;
  }
);

export const submitDrOpinion = createAsyncThunk(
  "submitDrOpinion",
  async (req) => {
    const res = await PatientDataService.submitDrOpinion(req);
    return res.data;
  }
);

export const babyAddMedicalRecord = createAsyncThunk(
  "babyAddMedicalRecord",
  async (req) => {
    const res = await PatientDataService.addMedicalRecord(req);
    return res.data;
  }
);

export const babyMedicalRecordAdd = createAsyncThunk(
  "babyMedicalRecordAdd",
  async (req) => {
    const res = await PatientDataService.babyMedicalRecordAdd(req);
    return res.data;
  }
);
export const babydataInfo = createAsyncThunk("babydataInfo", async (req) => {
  return req;
});

export const addNewGlobalRecord = createAsyncThunk(
  "addNewGlobalRecord",
  async (req) => {
    const res = await PatientDataService.addNewGlobalRecord(req);
    return res.data;
  }
);

export const getPatientBasicDetails = createAsyncThunk(
  "getPatientBasicDetails",
  async (req) => {
    const res = await PatientDataService.getPatientBasicDetails(req);
    return res.data;
  }
);

export const setSelectedBMRN = createAsyncThunk(
  "setSelectedBMRN",
  async (req) => {
    // const res = await PatientDataService.setSelectedBMRN(req);
    return req;
  }
);

export const headerBMRN = createAsyncThunk("headerBMRN", async (req) => {
  // const res = await PatientDataService.setSelectedBMRN(req);
  return req;
});

export const saveglobalrecordid = createAsyncThunk(
  "saveglobalrecordid",
  async (req) => {
    const res = await PatientDataService.saveglobalrecordid(req);
    return res.data;
  }
);

export const getNewReading = createAsyncThunk(
  "getReadingIdByStudyId",
  async (study_id) => {
    const res = await PatientDataService.getNewReading(study_id);
    return res.data;
  }
);

export const updateBabyMedicalRecord = createAsyncThunk(
  "updateBabyMedicalRecord",
  async (req) => {
    const res = await PatientDataService.updateBabyMedicalRecord(req);
    return res.data;
  }
);

export const multiPartFileUpload = createAsyncThunk(
  "multiPartFileUpload",
  async (req) => {
    const res = await PatientDataService.multiPartFileUpload(req);
    return res.data;
  }
);
export const motherProfileFileUpload = createAsyncThunk(
  "motherProfileFileUpload",
  async (req) => {
    const res = await PatientDataService.motherProfileFileUpload(req);
    return res.data;
  }
);

export const getFilesDetailsFromTable = createAsyncThunk(
  "getFilesDetailsFromTable",
  async (req) => {
    const res = await PatientDataService.getFilesDetailsFromTable(req);
    return res.data;
  }
);

export const getFilesPrescriptionsFromTable = createAsyncThunk(
  "getFilesPrescriptionsFromTable",
  async (req) => {
    const res = await PatientDataService.getFilesPrescriptionsFromTable(req);
    return res.data;
  }
);
export const getSendOpinion = createAsyncThunk(
  "getSendOpinion",
  async (shareKey) => {
    const res = await PatientDataService.getSendOpinion(shareKey);
    return res.data;
  }
);
export const getDrOpinionByReading = createAsyncThunk(
  "getDrOpinionByReading",
  async (req) => {
    const res = await PatientDataService.getDrOpinionByReading(req);
    return res.data;
  }
);

export const getWardDetailbyBranch = createAsyncThunk(
  "getWardDetailbyBranch",
  async (req) => {
    const res = await PatientDataService.getWardDetailbyBranch(req);
    return res.data;
  }
);
export const getPatientDetailsByStudyId = createAsyncThunk(
  "getPatientDetailsByStudyId",
  async (req) => {
    const res = await PatientDataService.getPatientDetailsByStudyId(req);
    return res.data;
  }
);
export const getPatientDetailsView = createAsyncThunk(
  "getPatientDetailsView",
  async (req) => {
    const res = await PatientDataService.getPatientDetailsView(req);
    return res.data;
  }
);

export const downloadReport = createAsyncThunk(
  "downloadReport",
  async (req) => {
    const res = await PatientDataService.downloadReport(req);
    return res.data;
  }
);

export const deleteReport = createAsyncThunk("deleteReport", async (req) => {
  const res = await PatientDataService.deleteReport(req);
  return res.data;
});

export const addReferralDoctorToTable = createAsyncThunk(
  "addReferralDoctorToTable",
  async (req) => {
    const res = await PatientDataService.addReferralDoctorToTable(req);
    return res.data;
  }
);

export const getVerifiedReferralDoctors = createAsyncThunk(
  "getVerifiedReferralDoctors",
  async (hospital_id) => {
    const res = await PatientDataService.getVerifiedReferralDoctors(
      hospital_id
    );
    return res.data;
  }
);

export const getRefDoctorsFromTable = createAsyncThunk(
  "getRefDoctorsFromTable",
  async (req) => {
    const res = await PatientDataService.getRefDoctorsFromTable(req);
    return res.data;
  }
);

export const addOtpToTable = createAsyncThunk("addOtpToTable", async (req) => {
  const res = await PatientDataService.addOtpToTable(req);
  return res.data;
});

export const verifyOTP = createAsyncThunk("verifyOTP", async (req) => {
  const res = await PatientDataService.verifyOTP(req);
  return res.data;
});

export const ResendOTP = createAsyncThunk("ResendOTP", async (req) => {
  const res = await PatientDataService.ResendOTP(req);
  return res.data;
});

export const getbmrnlist = createAsyncThunk("getbmrnlist", async () => {
  sessionStorage.setItem("ExistBmrn", "ExistBmrn");
  sessionStorage.removeItem("BmrnScreen");
  sessionStorage.removeItem("NewBmrn");
});

export const getListingByBranch = createAsyncThunk(
  "getListingByBranch",
  async (req) => {
    const res = await PatientDataService.getListingByBranch(req);
    return res.data;
  }
);
export const deletePatientReading = createAsyncThunk(
  "deletePatientReading",
  async (req) => {
    const res = await PatientDataService.deletePatientReading(req);
    return res.data;
  }
);

export const getPatientReadings = createAsyncThunk(
  "getPatientReadings",
  async (req) => {
    const res = await PatientDataService.getPatientReadings(req);
    return res.data;
  }
);

export const getbabyAppearbyreadingid = createAsyncThunk(
  "getbabyAppearbyreadingid",
  async (req) => {
    const res = await PatientDataService.getbabyAppearbyreadingid(req);
    return res.data;
  }
);

export const getBabyAppearByReadingIdScore = createAsyncThunk(
  "getBabyAppearByReadingIdScore",
  async (req) => {
    const res = await PatientDataService.getbabyAppearbyreadingid(req);
    return res.data;
  }
);

export const getBabyProfileByReadingId = createAsyncThunk(
  "getBabyProfileByReadingId",
  async (req) => {
    const res = await PatientDataService.getBabyProfileByReadingId(req);
    return res.data;
  }
);

export const getBabyProfile = createAsyncThunk(
  "getBabyProfile",
  async (req) => {
    const res = await PatientDataService.getBabyProfileByReadingId(req);
    return res.data;
  }
);

export const getMotherProfileScore = createAsyncThunk(
  "getMotherProfileScore",
  async (req) => {
    const res = await PatientDataService.getMotherProfileByReadingId(req);
    return res.data;
  }
);
export const getBabyProfileScore = createAsyncThunk(
  "getBabyProfileScore",
  async (req) => {
    const res = await PatientDataService.getBabyProfileByReadingId(req);
    return res.data;
  }
);

export const getMotherProfile = createAsyncThunk(
  "getMotherProfile",
  async (req) => {
    const res = await PatientDataService.getMotherProfileByReadingId(req);
    return res.data;
  }
);

export const getMotherProfileByReadingId = createAsyncThunk(
  "getMotherProfileByReadingId",
  async (req) => {
    const res = await PatientDataService.getMotherProfileByReadingId(req);
    return res.data;
  }
);
export const getBabyHealthParametersByReadingId = createAsyncThunk(
  "getBabyHealthParametersByReadingId",
  async (req) => {
    const res = await PatientDataService.getBabyHealthParametersByReadingId(
      req
    );
    return res.data;
  }
);

export const getBabyRespiratoryByReadingId = createAsyncThunk(
  "getBabyRespiratoryByReadingId",
  async (req) => {
    const res = await PatientDataService.getBabyRespiratoryByReadingId(req);
    return res.data;
  }
);
export const getBabyCNSDataByReadingId = createAsyncThunk(
  "getBabyCNSDataByReadingId",
  async (req) => {
    const res = await PatientDataService.getBabyCNSDataByReadingId(req);
    return res.data;
  }
);
export const getBabyCVFByReadingId = createAsyncThunk(
  "getBabyCVFByReadingId",
  async (req) => {
    const res = await PatientDataService.getBabyCVFByReadingId(req);
    return res.data;
  }
);

export const getBabyGITByReadingId = createAsyncThunk(
  "getBabyGITByReadingId",
  async (req) => {
    const res = await PatientDataService.getBabyGITByReadingId(req);
    return res.data;
  }
);

export const getBabyRespiratoryByReadingIdScore = createAsyncThunk(
  "getBabyRespiratoryByReadingIdScore",
  async (req) => {
    const res = await PatientDataService.getBabyRespiratoryByReadingId(req);
    return res.data;
  }
);
export const getBabyCNSDataByReadingIdScore = createAsyncThunk(
  "getBabyCNSDataByReadingIdScore",
  async (req) => {
    const res = await PatientDataService.getBabyCNSDataByReadingId(req);
    return res.data;
  }
);
export const getBabyCVFByReadingIdScore = createAsyncThunk(
  "getBabyCVFByReadingIdScore",
  async (req) => {
    const res = await PatientDataService.getBabyCVFByReadingId(req);
    return res.data;
  }
);
export const getBabyGITByReadingIdScore = createAsyncThunk(
  "getBabyGITByReadingIdScore",
  async (req) => {
    const res = await PatientDataService.getBabyGITByReadingId(req);
    return res.data;
  }
);
export const getBabyAntibioticDataByReadingId = createAsyncThunk(
  "getBabyAntibioticDataByReadingId",
  async (req) => {
    const res = await PatientDataService.getBabyAntibioticDataByReadingId(req);
    return res.data;
  }
);
export const getBabyInvDataByReadingId = createAsyncThunk(
  "getBabyInvDataByReadingId",
  async (req) => {
    const res = await PatientDataService.getBabyInvDataByReadingId(req);
    return res.data;
  }
);

export const getBabyInvDataByReadingIdScore = createAsyncThunk(
  "getBabyInvDataByReadingIdScore",
  async (req) => {
    const res = await PatientDataService.getBabyInvDataByReadingId(req);
    return res.data;
  }
);
export const getBabyFinalDiagByReadingId = createAsyncThunk(
  "getBabyFinalDiagByReadingId",
  async (req) => {
    const res = await PatientDataService.getBabyFinalDiagByReadingId(req);
    return res.data;
  }
);

export const getDataEntryScreenByStudyID = createAsyncThunk(
  "getDataEntryScreenByStudyID",
  async (req) => {
    const res = await PatientDataService.getDataEntryScreenByStudyID(req);
    return res.data;
  }
);

export const getBabyBranch = createAsyncThunk("getBabyBranch", async (req) => {
  const res = await PatientDataService.getBabyBranch(req);
  return res.data;
});

export const getWardsByBranch = createAsyncThunk(
  "getWardsByBranch",
  async (req) => {
    const res = await PatientDataService.getWardsByBranch(req);
    return res.data;
  }
);

export const clearBasicInfos = createAsyncThunk(
  "clearBasicInfos",
  async (req) => {
    return;
  }
);

export const getBabyQuickSepsis = createAsyncThunk(
  "getBabyQuickSepsis",
  async (req) => PatientDataService.getBabyQuickSepsis(req)
);
export const createBabyQuickSepsis = createAsyncThunk(
  "createBabyQuickSepsis",
  async (req) => PatientDataService.createBabyQuickSepsis(req)
);
export const updateBabyQuickSepsis = createAsyncThunk(
  "updateBabyQuickSepsis",
  async (req) => PatientDataService.updateBabyQuickSepsis(req)
);
/* export const setQuickSepsisParameters = createAsyncThunk(
  "quickSepsisParameters",
  async (data) => data
); */
export const getBabySemiInvasive = createAsyncThunk(
  "getBabySemiInvasive",
  async (req) => PatientDataService.getBabySemiInvasive(req)
);
export const createBabySemiInvasive = createAsyncThunk(
  "createBabySemiInvasive",
  async (req) => PatientDataService.createBabySemiInvasive(req)
);
export const updateBabySemiInvasive = createAsyncThunk(
  "updateBabySemiInvasive",
  async (req) => PatientDataService.updateBabySemiInvasive(req)
);
