import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import Reports from "./Reports";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
const ALPHA = /^[a-zA-Z]+$/;
import AddReferralDoctor from "./AddReferralDoctor";
import {
  Grid,
  Card,
  CardContent,
  Button,
  DialogTitle,
  TextField,
  InputAdornment,
  Box,
  SvgIcon,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import "../styles/DataEntry.scss";
import {
  getVerifiedReferralDoctors,
  linkReferralDoctor,
} from "../reducers/patientDataThunk";

import {
  setErrorMessage,
  setsuccessMessage,
} from "../reducers/patientDataThunk";
import {
  getRefDoctorsFromTable,
  addReferralDoctorToTable,
} from "../../Admin/reducers/wardThunk";

const ReferralDoctorOpinion = (props) => {
  const user = useSelector((state) => state.user);
  const patient = useSelector((state) => state.patient);
  const hospital_id = user.userProfile.hospital_id;
  const study_id = patient.bmrndata?.study_id;
  const reading = patient.bmrndata?.reading_id;
  const [addRefDoctorData, setAddRefDoctorData] = useState();

  const baby_medical_record_number =
    patient.bmrndata?.baby_medical_record_number;
  const [selectedFiles, setSelectedFiles] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addReferralData, setaddReferralData] = useState(
    patient.referralDoctorData
  );

  const [allDoctors, setAllDoctors] = useState([]);
  const [doctorListFromTable, setDoctorListFromTable] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("");

  React.useEffect(() => {
    dispatch(getVerifiedReferralDoctors(hospital_id))
      .unwrap()
      .then((resdata) => {
        setAllDoctors(resdata.response);
        setDoctorListFromTable(resdata.response);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const [open, setOpen] = React.useState(false);

  const handleClick = (newState) => () => {
    const req = { study_id, reading, referral_doctor_id: selectedValue };

    dispatch(linkReferralDoctor(req)).then((resdata) => {
      dispatch(setsuccessMessage(resdata.payload.message));
      props.onClose();
      //navigate("/add-opinion");
    });
  };

  const handleClick1 = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = (newState) => () => {
    setState({ open: true, ...newState });
  };

  React.useEffect(() => {
    if (props.open) setaddReferralData(patient.referralDoctorData);
  }, [patient.referralDoctorData, props.open]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;

    const fieldvalue = event.target.value;
    const newFormData = { ...addRefDoctorData };
    newFormData[fieldname] = fieldvalue;
    setAddRefDoctorData(newFormData);
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    const newFormData = { ...addRefDoctorData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }

    newFormData.hospital_id = hospital_id;
    const req = newFormData;
    dispatch(addReferralDoctorToTable(req))
      .unwrap()
      .then((resdata) => {
        if (resdata) {
          dispatch(getVerifiedReferralDoctors(hospital_id))
            .unwrap()
            .then((resdata) => {
              setAllDoctors(resdata.response);
              setDoctorListFromTable(resdata.response);
              setOpen(false);
            });
          dispatch(setsuccessMessage(resdata.message));
          setOpen(false);
        } else {
          dispatch(setErrorMessage(resdata.message));
        }
      });
  };

  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [patient.errorMessage]);

  useEffect(() => {
    if (patient.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [patient.successMessage]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const closeDialog = (e) => {
    e.preventDefault();
    props.onClose();
  };

  const searchReferralDoctors = (value) => {
    const searchString = value.toLowerCase();
    const filteredList = [];
    allDoctors.map((doctor) => {
      if (
        doctor.doctor_name.toLowerCase().indexOf(searchString) != -1 ||
        doctor.email_address.toLowerCase().indexOf(searchString) != -1
      ) {
        filteredList.push(doctor);
      }
    });
    setDoctorListFromTable(filteredList);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.open}
        onClose={props.onClose}
      >
        <DialogTitle style={{ borderBottom: "1px solid #000" }}>
          <Link
            to="/"
            className="FIle-left"
            onClick={closeDialog}
            style={{ marginRight: "20px" }}
          >
            <img
              src={Back}
              className="file-img-left"
              alt=""
              style={{ width: "12px" }}
            />
          </Link>
          <span>Referral Doctor Opinion</span>
          <Link to="/" className="FIle-right-extreme" onClick={closeDialog}>
            <img src={Close} className="file-img-right-extreme" alt="" />
          </Link>
        </DialogTitle>
        <DialogContent>
          <form id="bmrn-form">
            <div style={{ marginLeft: "10px" }}>
              <p className="ref-p">
                Select the required reports and send them for referral doctor
                opinion
              </p>
            </div>
            <div>
              <h4 className="UploadReportsTitle">Upload Reports</h4>
            </div>
            <div className="ref-doctor-overflow">
              <Reports />
            </div>
            <div>
              <div>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <form>
                        <TextField
                          style={{
                            width: "96%",
                            marginTop: "10px !important",
                            padding: "10px",
                          }}
                          size="small"
                          className="dataentry_input"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon fontSize="small" color="action">
                                  <SearchIcon />
                                </SvgIcon>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Search a referral doctor by name or email address"
                          onChange={(e) => {
                            searchReferralDoctors(e.target.value);
                          }}
                        />
                      </form>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container>
                    <Grid item xs={12} sm={9}>
                      Select a Referral Doctor
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                      <Button variant="outlined" onClick={handleClickOpen}>
                        + Add New Doctor
                      </Button>
                    </Grid> */}
                  </Grid>
                </Grid>
                <div className="ref-doctor-overflow">
                  <Grid item xs={12} sm={12}>
                    <Card variant="none">
                      <CardContent>
                        <form>
                          {doctorListFromTable.map((index) => (
                            <Grid container>
                              <Grid item xs={12} sm={6} className="Doctors">
                                <FormControl>
                                  <RadioGroup
                                    name="RadioButton1"
                                    id="radio-button-id"
                                    value={selectedValue}
                                    onChange={handleChange}
                                  >
                                    <FormControlLabel
                                      name="doctor_name"
                                      id="doctor_name"
                                      value={index.referral_id}
                                      control={<Radio />}
                                      label={index.doctor_name}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                className="doctor-details"
                              >
                                {index.email_address}
                              </Grid>
                            </Grid>
                          ))}
                        </form>
                      </CardContent>
                    </Card>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="formSection">
              <Grid container>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    className="back"
                    onClick={props.onClose}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    className="purple-add"
                    type="button"
                    onClick={handleClick({
                      vertical: "top",
                      horizontal: "center",
                    })}
                  >
                    <span>Send Reports</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>
          <Link
            className="FIle-left"
            onClick={handleClose1}
            style={{ marginRight: "20px" }}
          >
            <img
              src={Back}
              className="file-img-left"
              alt=""
              style={{ width: "12px" }}
            />
          </Link>
          Add Referral Doctor
          <Link className="FIle-right-extreme" onClick={handleClose1}>
            <img src={Close} style={{ paddingLeft: "400px" }} alt="" />
          </Link>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={HandleAddFormSubmit} id="bmrn-form">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <label
                  htmlFor="Name"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Name
                </label>
                <TextField
                  id="doctor_name"
                  name="doctor_name"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  onChange={handleAddFormChange}
                  //value={addBranchData.doctor_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label
                  htmlFor="Email ID"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Email Id
                </label>
                <TextField
                  id="email_address"
                  name="email_address"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  type="email"
                  onChange={handleAddFormChange}
                  //value={addBranchData.email_id}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <label
                  htmlFor="Phone Number"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Phone Number
                </label>
                <TextField
                  id="contact_number"
                  name="contact_number"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  onChange={handleAddFormChange}
                  //value={addBranchData.contace_number}
                  type="number"
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 10);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label
                  htmlFor="City"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  City
                </label>
                <TextField
                  id="city"
                  name="city"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  onChange={handleAddFormChange}
                  //value={addBranchData.city}
                  onKeyDown={(event) => {
                    if (!ALPHA.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
            </Grid>

            <div className="formSection">
              <Grid container>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    className="back"
                    onClick={handleClose1}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    className="purple-add"
                    type="submit"
                    onClick={handleClick1({
                      vertical: "top",
                      horizontal: "center",
                    })}
                  >
                    <span>Add Doctor</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default ReferralDoctorOpinion;
