import { createSlice } from "@reduxjs/toolkit";
import * as Adminthunk from "./adminThunk";
import { initialState } from "./admininitialstate";
//import * as AdminDataThunk from "./adminDataThunk";

const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: {
    /* [AdminDataThunk.getBranchDetail.fulfilled]: (state, action) => {
        state = action.payload;
      },
    }, */

    [Adminthunk.addAdminDetails.fulfilled]: (state, action) => {
      state.admindata = action.payload.response;
      state.isAdminEdit = true;
    },

    [Adminthunk.getAdminDetail.fulfilled]: (state, action) => {
      state.adminList = action.payload.response;
    },

    [Adminthunk.getAdminsByRole.fulfilled]: (state, action) => {
      state.adminListByRole = action.payload.response;
    },

    [Adminthunk.getmapedAdminDetail.fulfilled]: (state, action) => {
      state.adminMaping = action.payload.response;
    },
    [Adminthunk.getUserHierarchyDetail.fulfilled]: (state, action) => {
      state.UserHierarchyList = action.payload.response;
    },
    
    [Adminthunk.getUserPermissionDetail.fulfilled]: (state, action) => {
      state.UserPermissionList = action.payload.response;
    },
    
    [Adminthunk.getUserPermissions.fulfilled]: (state, action) => {
      state.getUserPermissions = action.payload.response;
    },
    

    [Adminthunk.getBranchDetail.fulfilled]: (state, action) => {
      state.branchList = action.payload.response;
    },

    [Adminthunk.getUserRoles.fulfilled]: (state, action) => {
      state.userRolesList = action.payload.response;
    },

    [Adminthunk.mapAdminWithBranch.fulfilled]: (state, action) => {
      state.mapData = action.payload.response;
    },
    [Adminthunk.mapUserHierarchy.fulfilled]: (state, action) => {
      state.UserHierarchyData = action.payload.response;
    },

    [Adminthunk.setEditAdminData.fulfilled]: (state, action) => {
      const record = action.payload;
      state.admindata = record;
      state.isAdminEdit = action.payload;
    },

    [Adminthunk.updateAdminDetails.fulfilled]: (state, action) => {
      state.admindata = action.payload.response;
    },
  },
});
export default adminSlice.reducer;
