import React, { useState, useEffect } from "react";
import {
  Alert,
  Snackbar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import OtpInput from "react-otp-input";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";
import logo from "../assets/images/avyantra_logo.png";
import prelogo from "../assets/images/presco_logo.png";

import { setErrorMessage } from "../../dataEntry/reducers/patientDataThunk";
import Loginform from "./loginform";

const ForgotOTP = (props) => {
  const [OTPForm, setOTPForm] = React.useState("");
  const [OTP, setOTP] = useState("");

  const user = useSelector((state) => state.user.userProfile);
  const patient = useSelector((state) => state.patient);
  const [errMsg, setErrMsg] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 3000);
    }
  }, [patient.errorMessage]);

  const loginSubmit = (e) => {
    e.preventDefault();
  };

  const handleFormChange = (otp) => {
    const fieldname = otp;
    const fieldvalue = otp;

    const newFormData = { ...OTPForm };
    newFormData[fieldname] = fieldvalue;
    setOTPForm(newFormData);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className="avy_logo">
                <img src={logo} className="avy-img" />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="login_contact">
                <Button className="login_contact_content">Contact us</Button>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <img src={prelogo} className="pre_img" />
              <div className="pre_caption">
                Neonatal Sepsis Prediction Platform
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="signUpBg">
        <Box className="login-container">
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              marginTop: "3%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={2.6}
              //columns={{ xs: 1, sm: 12, md: 6 }}
            >
              <Card variant="outlined" className="login_radius">
                <CardContent>
                  <p className="login_welcome">Forgot your password?</p>
                  <p className="forgot_cont">
                    Please enter the verification code sent to you
                  </p>
                  <form id="mother-medical-form" onSubmit={loginSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <OtpInput
                          numInputs={6}
                          separator={<span>-</span>}
                          onChange={(otp) => handleFormChange(otp)}
                          otpType="number"
                          value={OTP}
                          size="Medium"
                          id="username"
                          name="username"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          className="sendcode_button"
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <p>
                          Don't have an account?
                          <Link to="/Signup">Sign Up</Link>
                        </p>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Grid className="copy-posi">
          <p className="Copyright">
            Copyright © 2019-2024 Avyantra Health Technologies. All Rights
            Reserved
          </p>
        </Grid>
      </div>
      {patient.errorMessage && (
        <Snackbar
          open={patient.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {patient.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default ForgotOTP;
