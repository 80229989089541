import moment from "moment";

export const BMRNFormMock = {
  baby_medical_record_number: "",
  baby_mother_medical_record_number: "",
  baby_name: "",
  Identification_marks: "",
  mother_name: "",
  father_name: "",
  primary_contact_no: "",
  educational_level: "",
  address: "",
  city: "",
  state: "",
  pincode: "",
  nationality: "",
  email_id: "",
  status: "1",
  medico_legal_case: "Yes",
  entityTypeSel: "",
};
export const babyFormMock = {
  baby_mother_medical_record_number: "",
  record_type: "NA",
  baby_admission_type: "Inborn",
  birth_facility: "NA",
  baby_ward: "",
  baby_place_of_birth_pin_code: "",
  baby_birth_date: "",
  baby_birth_time_hours: "",
  baby_birth_time_minit: "",
  baby_gender: "Male",
  study_id: "",
  baby_date_of_admission: moment().format("MM/DD/YYYY"),
  baby_age_of_admission: "",
  baby_apgar_score_one_min: "",
  baby_apgar_score_five_min: "",
  baby_apgar_score_ten_min: "",
  baby_place_of_birth_name: "",
  baby_gestational_age: "",
  baby_preterm: "NA",
  baby_weight_at_birth: "",
  baby_weight_at_birth_unit: "Kgs",
  baby_weight_at_admission_unit: "kgs",
  baby_weight_at_admission: "",
  baby_day_of_event: "",
  baby_condition_suspect: "NA",
  baby_condition_yes_eos_los: "NA",
  meningitis: "NA",
  umblical_sepsis: "NA",
  skin_pustules: "NA",
  diagMRDS: "NA",
  baby_condition_jaundice_suspect: "NA",
  baby_condition_lbw_suspect: "NA",
  baby_condition_ttnb_suspect: "NA",
  baby_lga_sga_aga_suspect: "NA",
  diagCongenitalHeartDisease: "NA",
  baby_condition_anemia_suspect: "NA",
  prelim_diagnosis_perinatal: "NA",
  prelim_diagnosis_hypoglycemia: "NA",
  prelim_diagnosis_hypocalcemia: "NA",
  prelim_diagnosis_feeding_intolerence: "NA",
  thrombocytopenia: "NA",
  seizures: "NA",
  place_of_delivery: "HOSPITAL",
};
export const motherFormMock = {
  study_id: "",
  mother_age: "",
  mother_weight_unit: "kgs",
  mother_weight: "",
  mother_height: "",
  mother_height_unit: "Ft",
  mother_haemoglobin: "",
  mother_bmi: "",
  maternal_blood_pressure: "",
  maternal_blood_pressure_diastolic: "",
  maternal_diabetes: "NA",
  maternal_fever: "",
  maternal_fever_unit: "Fahrenheit",
  maternal_fever_basic: "NA",
  maternal_thyroid_function: "NA",
  maternal_thyroid_function_basic: "NA",
  maternal_thyroid_function_unit_basic: "",
  maternal_thyroid_t3: "",
  maternal_thyroid_t4: "",
  maternal_thyroid_function_unit_basic_unit: "",
  more_than_3_vaginal_examinations_during_labor: "NA",
  rupture_of_membranes_rom_two: "NA",
  rupture_of_membranes_rom_one: "NA",
  leaking_pv: "NA",
  rupture_of_membranes_rom: "NA",
  smelly_amniotic_fluid: "NA",
  chorioamnionitis: "NA",
  gbs_infection: "NA",
  colonisation_or_urinary_tract_infection: "NA",
  torch_infections: "NA",
  type_of_delivery: "NA",
  delayed_cord_clamping: "NA",
  vaginal_swab_culture: "NA",
  vaginal_swab_culture_two: "NA",
  vaginal_swab_culture_three: "",
  amniotic_fluid_culture: "NA",
  amniotic_fluid_culture_three: "",
  amniotic_fluid_culture_two: "NA",
  maternal_fever_duration: "",
  pih: "NA",
};
export const babyHealthParametersFormMock = {
  baby_appearance: "NA",
  baby_skin_colour: "",
  baby_cry_sound_status: "",
  breathing_rate: "",
  baby_chest_indrawing: "NA",
  heart_rate: "",
  baby_blood_pressure_upper_limb: "",
  baby_blood_pressure_lower_limb: "",
  baby_blood_pressure_mean_arterial_bp: "",
  frequency_of_stools: "",
  baby_respiratory_support: "NA",
  baby_movement: "NA",
};

export const babyCNSDataMock = {
  study_id: "",
  features_of_encephalopathy: "NA",
  seizures: "NA",
  abnormal_movements_like_tonic_posturing: "NA",
  af_bulge: "NA",
  baby_movement: "NA",
};

export const babyGITDataMock = {
  study_id: "",
  abdominal_dystension: "NA",
  frequency_of_stools: "",
  diarrhea: "NA",
  vomiting: "NA",
  feeding_intolerance: "NA",
};

export const referralDoctorDataMock = {
  study_id: "",
  document_type: "",
  patient_unique_id: "",
  s3_filename: "",
  uploaded_time_in_s3: "",
};
export const babyCVDataMock = {
  study_id: "",
  urine_output: "",
  baby_blood_pressure_mean_arterial_bp: "",
  baby_blood_pressure_upper_limb: "",
  baby_blood_pressure_lower_limb: "",
  capillary_refill: "",
  capillary_refill_unit: "",
  low_peripheral_pulse_volume: "NA",
  cool_peripheries: "NA",
  two_d_echo_done: "NA",
  two_d_echo_done_if_yes: "",
  baby_on_ionotropes: "NA",
  central_line: "NA",
  skin_pustules: "",
  infusion_of_blood_products: "NA",
  reading: "",
  central_line_if_applicable: "",
  central_line_insert_date: "",
  central_line_removed_date: "",
  central_line_value: "",
};
export const babyFinalDiagnosis = {
  study_id: "",
  reading: "",
  days_of_stay_in_hospital: "",
  final_diagnosis_sepsis: "NA",
  final_diagnosis_rds: "NA",
  final_diagnosis_ttnb: "NA",
  final_diagnosis_jaundice: "NA",
  final_diagnosis_lbw: "NA",
  final_diagnosis_lga: "NA",
  final_diagnosis_aga: "NA",
  final_diagnosis_sga: "NA",
  final_diagnosis_anemia: "NA",
  final_diagnosis_dextochordia: "NA",
  final_diagnosis_hypoglycemia: "NA",
  final_diagnosis_hypocalcemia: "NA",
  final_diagnosis_gastroenteritis: "NA",
  final_diagnosis_perinatal_respiratory_depression: "NA",
  final_diagnosis_shock: "NA",
  final_diagnosis_feeding_intolerence: "NA",
  baby_discharge_date: "",
  final_diagnosis_pulmonary_hemerrage: "NA",
  final_diagnosis_thrombocytopenia: "NA",
  final_diagnosis_eos_los: "NA",
  final_diagnosis_other: "",
  final_diagnosis_meningitis: "NA",
  final_diagnosis_hypoxia: "NA",
  final_diagnosis_metabolic_acidosis: "NA",
  final_diagnosis_asphyxia: "NA",
  final_diagnosis_endocarditis: "NA",
  final_diagnosis_peritonitis: "NA",
  final_diagnosis_soft_tissue_abscess: "NA",
  final_diagnosis_coagulopathy: "NA",
  final_diagnosis_uti: "NA",
  final_diagnosis_umblical_sepsis: "NA",
  final_diagnosis_bleeding_manifestation: "NA",
  final_diagnosis_central_peripheral: "NA",
  final_lga_sga_aga_suspect: "NA",
  discharge_status: "DISCHARGED",
  final_diagnosis_septic_arthritis: "NA",
  final_diagnosis_pneumonia: "NA",
  final_diagnosis_seizures: "NA",
  final_diagnosis_pulmonary_hemorrhage: "NA",
  final_diagnosis_skin_pustules: "NA",
};

export const refDocSelectMock = {
  doctor_name: "",
  contact_number: "",
  email_address: "",
  doctorDetails: "",
};

export const babyappearance = {};
export const ACC_FORM_INCOMP = "FIll and Submit Account Creation Form";
export const ADDI_FORM_INCOMP = "FIll and Submit Additional Information Form";
export const BABY_FORM_INCOMP = "FIll and Submit Baby Form";
export const MOTHER_FORM_INCOMP = "FIll and Submit Mother Form";
export const BABY_HEALTH_PARAMS_FORM_INCOMP =
  "FIll and Submit Baby Health Parameters Form";
export const AddBmrn_FORM_INCOMP = "Patient Data Insertion Failed";
export const AddBmrn_FORM_Success = "Patient Data Inserted Successfully";
export const DATA_FAIL = "Data Insertion Failed";
export const DATA_SUCCESS = "Data Submiited Successfully";
export const INVALID_CREDENTIALS = "Username or Password  is invalid";
export const PASSWORD_MISMATCH =
  "New Password & Confirm Password Should be same";

export const INVASIVE = "Invasive";
export const NON_INVASIVE = "Non-Invasive";
export const SEMI_INVASIVE = "Semi-Invasive";
export const QUICK_SEPS = "Quick-Sepsis-Calculator";

export const ENTITY_TYPES = [NON_INVASIVE, SEMI_INVASIVE, INVASIVE];

export const DATECREATED = { label: "DATE CREATED", value: "createdAt" };
export const DBDATEFILTER = [DATECREATED];

export const ASCENDING = { label: "Ascending", value: "asc" };
export const DESCENDING = { label: "Descending", value: "desc" };

export const FILTERORDER = [ASCENDING, DESCENDING];
export const NA = "NA";

export const UNILATERALHAZINESS = "Unilateral Haziness";
export const BILATERALHAZINESS = "Bilateral Haziness";
export const PNENUMONIA = "Pneumonia";
export const PULMONARYHEMORRAGE = "Pulmonary Hemorrage";
export const TTNB = "TTNB";
export const MAS = "MAS";
export const RDS = "RDS";
export const OTHER = "Other";

export const XRAY_DIAGNOSIS = [
  UNILATERALHAZINESS,
  BILATERALHAZINESS,
  PNENUMONIA,
  PULMONARYHEMORRAGE,
  TTNB,
  MAS,
  RDS,
  OTHER,
  NA,
];

export const LOWPITCH = "Low Pitch";
export const HIGHPITCH = "High Pitch";
export const NORMAL = "Normal";
export const NOTCRIED = "Not Cried";
export const LOW = "Low";
export const HIGH = "High";

export const CRYSOUND = [LOWPITCH, HIGHPITCH, NORMAL, NOTCRIED, NA];

export const URINE_OUTPUT = [LOW, HIGH, NORMAL, NA];

export const LETHARGY = "Lethargy";
export const STUPOR = "Stupor";
export const COMA = "Coma";

export const LESS_THAN_3_SECONDS = "< 3 Sec";
export const GREATER_THAN_3_SECONDS = "> 3 Sec";
export const EQUAL_TO_3_SECONDS = "= 3 Sec";
export const EQUAL_TO_0_SECONDS = "= 0 Sec";

export const FEATURES_OF_ENCEPHALOPATHY = [LETHARGY, STUPOR, COMA, NORMAL, NA];

export const CAPILLARY_REFILL = [
  GREATER_THAN_3_SECONDS,
  LESS_THAN_3_SECONDS,
  EQUAL_TO_3_SECONDS,
  EQUAL_TO_0_SECONDS,
  NA,
];

export const UMBLICAL_LINE = "Umbilical line";
export const PERIPHERAL_LINE = "Peripheral line";

export const CENTRAL_LINE_VALUE = [UMBLICAL_LINE, PERIPHERAL_LINE, NA];

export const HYPO = "Hypo";
export const HYPER = "Hyper";

export const THYROID_FUNCTION_IF_ABNORMAL = [HYPO, HYPER, NA];

export const PROM = "PROM";
export const SROM = "SROM";
export const ARM = "ARM";

export const RUPTURE_OF_MEMBRANES_TYPE = [PROM, SROM, ARM, NA];

export const LESS_THAN_18HOURS = "< 18hours";
export const GREATER_THAN_18HOURS = "> 18hours";

export const RUPTURE_OF_MEMBRANES_IF_PROM = [
  LESS_THAN_18HOURS,
  GREATER_THAN_18HOURS,
  NA,
];

export const CENTRAL_APENA = "Central Apnea";
export const OBSTRUCTIVE_APENA = "Obstructive Apnea";
export const MIXED_APENA = "Mixed Apnea";

export const APENA = [CENTRAL_APENA, OBSTRUCTIVE_APENA, MIXED_APENA, NA];

export const VENTILATOR = { id: 1, itemName: "Ventilator" };
export const CPAP = { id: 2, itemName: "CPAP" };
export const HFNC = { id: 3, itemName: "HFNC" };
export const IMV = { id: 4, itemName: "IMV" };
export const NHF = { id: 5, itemName: "NASAL HIGH FLOW" };
export const NP = { id: 6, itemName: "NASAL PRONGS " };
export const NIPPV = { id: 7, itemName: "NIPPV" };
export const RESP_NA = { id: 8, itemName: "NA" };

export const RESP_SUPPORT = [
  VENTILATOR,
  CPAP,
  HFNC,
  IMV,
  NHF,
  NP,
  NIPPV,
];

export const COCCI = { id: 1, itemName: "Cocci" };
export const STAP_AUREUS = { id: 2, itemName: "Staphylococcus aureus" };
export const STAP_EPID = { id: 3, itemName: "Staphylococcus epidermidis" };
export const STAP_HOMINS = {
  id: 4,
  itemName: "Staphylococcus hominis hominis",
};
export const OTHERS = { id: 5, itemName: "Others" };
export const GP_NA = { id: 6, itemName: "NA" };

export const GRAM_POSITIVE = [
  COCCI,
  STAP_AUREUS,
  STAP_EPID,
  STAP_HOMINS,
  OTHERS,
  //GP_NA,
];

export const ACIN_BAUM = { id: 1, itemName: "Acinetobacter baumanii" };
export const ACIN_HAEMO = {
  id: 2,
  itemName: "Acinetobacter haemolyticus aureus",
};
export const URKH_CEPA = { id: 3, itemName: "urkholderia cepacia" };
export const ECOLI = { id: 4, itemName: "E Coli" };
export const ENTER_SPP = { id: 5, itemName: "Enterobacter spp" };
export const KLEB_SPP = { id: 6, itemName: "Klebsiella_spp_10_5_CFU_ml" };
export const KLEB_PNE = { id: 7, itemName: "Klebsiella pneumoniae" };
export const NON_FERM = {
  id: 8,
  itemName: "Non fermenting Gram negative bacilli",
};
export const PSEU = { id: 9, itemName: "Pseudomonas aeruginosa" };
export const SKIN_FLORA = { id: 10, itemName: "Skin flora" };
export const SPHIN = { id: 11, itemName: "Sphingomonas paucimobilis" };
export const CANDIDA_PELI = { id: 12, itemName: "Candida Pelliculosa" };
export const OTHER_NEG = { id: 13, itemName: "Others" };
export const GN_NA = { id: 14, itemName: "NA" };

export const GRAM_NEGATIVE = [
  ACIN_BAUM,
  ACIN_HAEMO,
  URKH_CEPA,
  ECOLI,
  ENTER_SPP,
  KLEB_SPP,
  KLEB_PNE,
  NON_FERM,
  PSEU,
  SKIN_FLORA,
  SPHIN,
  CANDIDA_PELI,
  OTHER_NEG,
  //GN_NA,
];

export const CANDIA_TROP = { id: 1, itemName: "Candida Tropicalis" };
export const CANDIA_AURIS = { id: 2, itemName: "Candida auris" };
export const CANDIA_NON = { id: 3, itemName: "Candida non albicans spp" };
export const CANDIA_SPP = { id: 4, itemName: "Candida spp" };
export const CANDIA_NA = { id: 5, itemName: "NA" };

export const FUNGI = [
  CANDIA_TROP,
  CANDIA_AURIS,
  CANDIA_NON,
  CANDIA_SPP,
  //CANDIA_NA,
];

export const NORMAL_ORG = "Normal Orophayngeal flora";
export const OTHERS_ORG = "Others";
export const NA_ORG = "NA";

export const OTH_ORG = [NORMAL_ORG, OTHERS_ORG, NA];

export const ven = { id: 1, itemName: "Ventilator" };
export const Amikacin = { id: 2, itemName: "Amikacin" };
export const Amoxyclav = { id: 3, itemName: "Amoxyclav" };
export const AmphotericinB = { id: 4, itemName: "AmphotericinB" };
export const Ampicillin = { id: 5, itemName: "Ampicillin" };
export const Aztreonam = { id: 6, itemName: "Aztreonam" };
export const Caspofungin = { id: 7, itemName: "Caspofungin" };
export const Cefepime = { id: 8, itemName: "Cefepime" };
export const cefixime = { id: 9, itemName: "cefixime" };
export const CefuroximeAxetil = { id: 10, itemName: "CefuroximeAxetil" };
export const Cephepime = { id: 11, itemName: "Cephepime" };
export const Cephoperazone = { id: 12, itemName: "Cephoperazone" };
export const Ciprofloxacin = { id: 13, itemName: "Ciprofloxacin" };
export const Clavulanicacid = { id: 14, itemName: "Clavulanicacid" };
export const Clindamycin = { id: 15, itemName: "Clindamycin" };
export const Colistin = { id: 16, itemName: "Colistin" };
export const Comoxicillin = { id: 17, itemName: "Comoxicillin" };
export const Cotrimoxazole = { id: 18, itemName: "Cotrimoxazole" };
export const Erythromycin = { id: 19, itemName: "Erythromycin" };
export const Fluconazole = { id: 20, itemName: "Fluconazole" };
export const Gentamicin = { id: 21, itemName: "Gentamicin" };
export const Imipenem = { id: 22, itemName: "Imipenem" };
export const Levofloxacin = { id: 23, itemName: "Levofloxacin" };
export const Linezolid = { id: 24, itemName: "Linezolid" };
export const Meropenem = { id: 25, itemName: "Meropenem" };
export const Micafungin = { id: 26, itemName: "Micafungin" };
export const Netilmicin = { id: 27, itemName: "Netilmicin" };
export const Ofloxacin = { id: 28, itemName: "Ofloxacin" };
export const Oxacillin = { id: 29, itemName: "Oxacillin" };
export const Pencillin = { id: 30, itemName: "Pencillin" };
export const Piperacillin = { id: 31, itemName: "Piperacillin" };
export const PolymyzinB = { id: 32, itemName: "PolymyzinB" };
export const Sulbactam = { id: 33, itemName: "Sulbactam" };
export const Sulfamethoxazole = { id: 34, itemName: "Sulfamethoxazole" };
export const Tazobactam = { id: 35, itemName: "Tazobactam" };
export const Tetracycline = { id: 36, itemName: "Tetracycline" };
export const Tigecycline = { id: 37, itemName: "Tigecycline" };
export const Tobramycin = { id: 38, itemName: "Tobramycin" };
export const Trimethoprim = { id: 39, itemName: "Trimethoprim" };
export const Vancomycin = { id: 40, itemName: "Vancomycin" };
export const Voriconalzole = { id: 41, itemName: "Voriconalzole" };
export const Tericoplanin = { id: 42, itemName: "Tericoplanin" };
export const Cloxacillin = { id: 43, itemName: "Cloxacillin" };
export const Methicillin = { id: 44, itemName: "Methicillin" };
export const Cefotaxime = { id: 45, itemName: "Cefotaxime" };
export const Ceftriazone = { id: 46, itemName: "Ceftriazone" };
export const Chloranpenicol = { id: 47, itemName: "Chloranpenicol" };
export const Teicoplanin = { id: 48, itemName: "Teicoplanin" };
export const Ceftazibime = { id: 49, itemName: "Ceftazibime" };
export const Chlorampenicol = { id: 50, itemName: "Chlorampenicol" };
export const Nalidicacid = { id: 51, itemName: "Nalidicacid" };
export const Nitrofuranton = { id: 52, itemName: "Nitrofuranton" };
export const Norfloxacin = { id: 53, itemName: "Norfloxacin" };
export const Anti_NA = { id: 54, itemName: "NA" };

export const ANTI_SENSITIVE = [
  ven,
  Amikacin,
  Amoxyclav,
  AmphotericinB,
  Ampicillin,
  Aztreonam,
  Caspofungin,
  Cefepime,
  cefixime,
  CefuroximeAxetil,
  Cephepime,
  Cephoperazone,
  Ciprofloxacin,
  Clavulanicacid,
  Clindamycin,
  Colistin,
  Comoxicillin,
  Cotrimoxazole,
  Erythromycin,
  Fluconazole,
  Gentamicin,
  Imipenem,
  Levofloxacin,
  Linezolid,
  Meropenem,
  Micafungin,
  Netilmicin,
  Ofloxacin,
  Oxacillin,
  Pencillin,
  Piperacillin,
  PolymyzinB,
  Sulbactam,
  Sulfamethoxazole,
  Tazobactam,
  Tetracycline,
  Tigecycline,
  Tobramycin,
  Trimethoprim,
  Vancomycin,
  Voriconalzole,
  Tericoplanin,
  Cloxacillin,
  Methicillin,
  Cefotaxime,
  Ceftriazone,
  Chloranpenicol,
  Teicoplanin,
  Ceftazibime,
  Chlorampenicol,
  Nalidicacid,
  Nitrofuranton,
  Norfloxacin,
  //Anti_NA,
];

export const ANTI_RESISTANT = [
  Amikacin,
  Amoxyclav,
  AmphotericinB,
  Ampicillin,
  Aztreonam,
  Caspofungin,
  Cefepime,
  cefixime,
  CefuroximeAxetil,
  Cephepime,
  Cephoperazone,
  Ciprofloxacin,
  Clavulanicacid,
  Clindamycin,
  Colistin,
  Comoxicillin,
  Cotrimoxazole,
  Erythromycin,
  Fluconazole,
  Gentamicin,
  Imipenem,
  Levofloxacin,
  Linezolid,
  Meropenem,
  Micafungin,
  Netilmicin,
  Ofloxacin,
  Oxacillin,
  Pencillin,
  Piperacillin,
  PolymyzinB,
  Sulbactam,
  Sulfamethoxazole,
  Tazobactam,
  Tetracycline,
  Tigecycline,
  Tobramycin,
  Trimethoprim,
  Vancomycin,
  Voriconalzole,
  Tericoplanin,
  Cloxacillin,
  Methicillin,
  Cefotaxime,
  Ceftriazone,
  Chloranpenicol,
  Teicoplanin,
  Ceftazibime,
  Chlorampenicol,
  Nalidicacid,
  Nitrofuranton,
  Norfloxacin,
  //Anti_NA,
];

export const ANTI_INTERMEDIATE = [
  Amikacin,
  Amoxyclav,
  AmphotericinB,
  Ampicillin,
  Aztreonam,
  Caspofungin,
  Cefepime,
  cefixime,
  CefuroximeAxetil,
  Cephepime,
  Cephoperazone,
  Ciprofloxacin,
  Clavulanicacid,
  Clindamycin,
  Colistin,
  Comoxicillin,
  Cotrimoxazole,
  Erythromycin,
  Fluconazole,
  Gentamicin,
  Imipenem,
  Levofloxacin,
  Linezolid,
  Meropenem,
  Micafungin,
  Netilmicin,
  Ofloxacin,
  Oxacillin,
  Pencillin,
  Piperacillin,
  PolymyzinB,
  Sulbactam,
  Sulfamethoxazole,
  Tazobactam,
  Tetracycline,
  Tigecycline,
  Tobramycin,
  Trimethoprim,
  Vancomycin,
  Voriconalzole,
  Tericoplanin,
  Cloxacillin,
  Methicillin,
  Cefotaxime,
  Ceftriazone,
  Chloranpenicol,
  Teicoplanin,
  Ceftazibime,
  Chlorampenicol,
  Nalidicacid,
  Nitrofuranton,
  Norfloxacin,
  //Anti_NA,
];

export const AMIKACIN = { id: 1, itemName: "Amikacin" };
export const PIPTAZ = { id: 2, itemName: "Piptaz" };
export const VANCOMYCIN = { id: 3, itemName: "Vancomycin" };
export const AUGMENTIC = { id: 4, itemName: "Augmentic" };
export const CEFOTAXIM = { id: 5, itemName: "Cefotaxim" };
export const TOZOBACTUM = { id: 6, itemName: "Tozobactum" };
export const ANTI_OTHER = { id: 7, itemName: "Other" };
export const ANTINA = { id: 8, itemName: "NA" };

export const ANTIBIOTIC_NAME = [
  AMIKACIN,
  PIPTAZ,
  VANCOMYCIN,
  AUGMENTIC,
  CEFOTAXIM,
  TOZOBACTUM,
  ANTI_OTHER,
  //ANTINA,
];

export const PINK = "Pink";
export const CENTRAL_CYANOSIS = "Central Cyanosis";
export const PERIPHERAL_DUSKINESS = "Peripheral Duskiness";
export const ACROYANOSIS = "Acrocyanosis";
export const PALE = "Pale";

export const SKIN_COLOR = [
  PINK,
  CENTRAL_CYANOSIS,
  PERIPHERAL_DUSKINESS,
  ACROYANOSIS,
  PALE,
  NA,
];
