import React, { useState, useEffect } from "react";
import {
  Alert,
  Snackbar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";
import logo from "../assets/images/avyantra_logo.png";
import prelogo from "../assets/images/presco_logo.png";
import { forgotPassword } from "../reducers/loginThunk";
import { ToastContainer, toast } from "react-toastify";
import { INVALID_CREDENTIALS } from "../../dataEntry/utils/mockData";
import {
  setErrorMessage,
  setsuccessMessage,
} from "../../dataEntry/reducers/patientDataThunk";
import Loginform from "./loginform";

const ForgotPassword = (props) => {
  const [ForgotPwdForm, setForgotPwdForm] = React.useState({});
  const user = useSelector((state) => state.user.userProfile);
  const patient = useSelector((state) => state.patient);
  const [errMsg, setErrMsg] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [patient.errorMessage]);

  useEffect(() => {
    if (patient.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [patient.successMessage]);

  const loginSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotPassword(ForgotPwdForm))
      .unwrap()
      .then((resdata) => {
        if (resdata.status == "200") {
          toast.success(resdata.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(resdata.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const handleFormChange = (event) => {
    const fieldname = event.target.getAttribute("name");

    const fieldvalue = event.target.value;
    const newFormData = { ...ForgotPwdForm };
    newFormData[fieldname] = fieldvalue;
    setForgotPwdForm(newFormData);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Grid container>
        <Grid item xs={12} sm={12}>
         
        <Grid container style={{
              marginTop: "3%",
            }}>
            <Grid item xs={12} sm={12}>
              <img src={prelogo} className="pre_img" />
              <div className="pre_caption">
                Neonatal Sepsis Prediction Platform
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
        <Box className="login-container">
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              marginTop: "3%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              //columns={{ xs: 1, sm: 12, md: 6 }}
            >
              <Card variant="outlined" className="login_radius">
                <CardContent>
                  <p className="login_welcome" style={{ fontSize: "1.6rem" }}>
                    Forgot your password?
                  </p>
                  <p className="forgot_cont" style={{ marginTop: "0.1rem" }}>
                    Please enter your email ID or phone number to <br /> receive
                    a verification code
                  </p>
                  <form id="mother-medical-form" onSubmit={loginSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label
                          htmlFor="Email Address | Phone Number"
                          className="login_label"
                          style={{ display: "block", textAlign: "left" }}
                        >
                          Email Address | Phone Number
                        </label>
                        <TextField
                          id="email"
                          name="email"
                          label=""
                          variant="outlined"
                          className="dataentry_input2"
                          size="small"
                          onChange={handleFormChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "10%",
                          marginBottom: "10%",
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          className="sendcode_button sign-up-button"
                        >
                          Send Code
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <p className="newAccReDirect">
                          Don't have an account?
                          <Link to="/Signup" className="signup_link">
                            Sign Up
                          </Link>
                        </p>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <div className="signUpBg">
        <Grid className="copy-posi">
          <p className="Copyright">
            Copyright © 2019-2024 Avyantra Health Technologies. All Rights
            Reserved
          </p>
        </Grid>
      </div>
      {patient.errorMessage && (
        <Snackbar
          open={patient.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {patient.errorMessage}
          </Alert>
        </Snackbar>
      )}
      {patient.successMessage && (
        <Snackbar
          open={patient.successMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {patient.successMessage}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default ForgotPassword;
