import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  TextField,
  Grid,
  Card,
  CardContent,
  Autocomplete,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "../../Dashboard/components/DashboardScreen";
import moment from "moment";

import "../../dataEntry/styles/DataEntry.scss";

import { GetCrpVSBloodCultureData } from "../reducers/ReportsDataThunk";
import Stack from "@mui/material/Stack";
import { Chart as ChartJS, registerables } from "chart.js";

import { getPatientDetails } from "../../dataEntry/reducers/patientDataThunk";

const CrpVsBloodCulture = () => {
  ChartJS.register(...registerables);
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dbbranchdata = useSelector((state) => state.dashboard);
  const [addBMRNData, setaddBMRNData] = useState(
    patient.bmrndata?.baby_medical_record_number
  );

  const hospital_id = user.userProfile.hospital_id;
  const branch = useSelector((state) => state.Reports?.SelectedBranchdata);
  const hospitalId = user.userProfile.hospital_id;
  const dispatch = useDispatch();
  const [bmrn, setbmrn] = React.useState();
  const [CrpVSBloodCultureRecords, setCrpVSBloodCultureRecords] = useState([]);
  const [sepsisStatus, setSepsisStatus] = React.useState("yes");
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(1, "Year").format()
  );
  const [toDate, setToDate] = React.useState(moment().format());
  const [isLoading, setIsLoading] = React.useState(false);
  const onBmrnSelected = (e, value) => {
    const bmrn = value.baby_medical_record_number;
    setbmrn(bmrn);
    setaddBMRNData(value);
  };

  useEffect(() => {
    getReports();
  }, [fromDate, toDate, branch?.hospital_branch_id, bmrn, sepsisStatus]);
  const getReports = () => {
    setIsLoading(true);
    let params;
    params = {
      sepsisStatus: sepsisStatus,
      bmrn: bmrn,
      start: moment(fromDate).format("MM-DD-YYYY"),
      end: moment(toDate).format("MM-DD-YYYY"),
    };
    const req = { hospital_id, branch_id: branch?.hospital_branch_id, params };
    dispatch(GetCrpVSBloodCultureData(req))
      .unwrap()
      .then((resdata) => {
        setCrpVSBloodCultureRecords(resdata.response.readings);
        setIsLoading(false);
      });
  };
  const [bmrnOptions, setBmrnOptions] = React.useState([]);

  useEffect(() => {
    const req = { hospitalId, hospitalBranchId: branch?.hospital_branch_id };
    dispatch(getPatientDetails(req));
  }, []);

  useEffect(() => {
    setBmrnOptions([{ baby_medical_record_number: "" }, ...patient.bmrnList]);
  }, [patient.bmrnList]);

  const columns = [
    {
      field: "baby_medical_record_number",
      headerName: "BMRN",
      flex: 1,
      width: 100,
      renderCell: (params) => (
        <span className="dadata">{params.row.baby_medical_record_number}</span>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date Of Admission",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">
          {moment(params.row.createdAt).format("DD - MM -YY")}
        </span>
      ),
    },

    {
      field: "hospital_name",
      headerName: "Hospital Name",
      flex: 1,
      width: 140,
      renderCell: (params) => (
        <span className="dadata">{params.row.hospital_name}</span>
      ),
    },
    {
      field: "branch_name",
      headerName: "Branch Name",
      width: 140,
      flex: 1,

      renderCell: (params) => (
        <span className="dadata">{params.row.branch_name}</span>
      ),
    },
    {
      field: "baby_c_reactive_protien_levels",
      headerName: "CRP Value",
      flex: 1,
      width: 85,

      renderCell: (params) => (
        <span className="dadata">
          {params.row.baby_c_reactive_protien_levels}
        </span>
      ),
    },
    {
      field: "baby_c_reactive_protien_result",
      headerName: "CRP Status",
      width: 85,
      flex: 1,

      renderCell: (params) => (
        <span className="dadata">
          {params.row.baby_c_reactive_protien_result}
        </span>
      ),
    },
    {
      field: "baby_condition_suspect",
      headerName: "Sepsis Status",
      width: 100,
      flex: 1,

      renderCell: (params) => <span className="dadata">{sepsisStatus}</span>,
    },
    {
      field: "score",
      headerName: "Sepsis Score",
      width: 70,
      flex: 1,

      renderCell: (params) => (
        <span className="dadata">{params.row.score}</span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <Card variant="none">
            <CardContent>
              <div>
                <div className="reports_chart_title">
                  {/* <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        margin: "0px auto",
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      CRP Vs Blood Culture
                    </Grid>
                  </Grid>
                  <hr /> */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      style={{
                        marginTop: 30,
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      CRP Vs Blood Culture
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Grid container>
                        <Grid item xs={12} sm={3}>
                          <label
                            htmlFor="Sepsis Status"
                            className="dataentry_label1"
                            style={{ display: "block" }}
                          >
                            Sepsis Status
                          </label>
                          <Select
                            labelId="sepsis_status"
                            id="sepsis_status"
                            name="sepsis_status"
                            label=""
                            size="small"
                            className="dataentry_input"
                            value={sepsisStatus}
                            onChange={(e) => {
                              setSepsisStatus(e.target.value);
                            }}
                            style={{
                              fontSize: "14px",
                              padding: "2px 0px",
                            }}
                          >
                            <MenuItem value="Positive">Positive</MenuItem>
                            <MenuItem value="Negative">Negative</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label
                            htmlFor="SORT BY"
                            className="dataentry_label1"
                            style={{ display: "block" }}
                          >
                            Select bmrn
                          </label>
                          <Autocomplete
                            style={{
                              fontSize: "14px",
                              padding: "0px 0px",
                            }}
                            className="dataentry_input"
                            id="baby_medical_record_number"
                            name="baby_medical_record_number"
                            options={bmrnOptions}
                            getOptionLabel={(option) =>
                              option.baby_medical_record_number || ""
                            }
                            onChange={onBmrnSelected}
                            value={
                              addBMRNData?.baby_medical_record_number
                                ? addBMRNData
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                id="bmrn"
                                name="bmrn"
                                value={
                                  addBMRNData?.baby_medical_record_number
                                    ? addBMRNData
                                    : null
                                }
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <label
                            htmlFor="From"
                            className="dataentry_label1"
                            style={{ display: "block" }}
                          >
                            From
                          </label>
                          <DatePicker
                            id="from_month"
                            name="from_month"
                            className="dataentry_input"
                            inputFormat="DD-MM-YYYY"
                            minDate="01-01-2020"
                            maxDate={toDate}
                            value={fromDate}
                            onChange={(date) => {
                              setFromDate(date["$d"]);
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                helperText={null}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                               }}
                               autocomplete="off"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label
                            htmlFor="To"
                            className="dataentry_label1"
                            style={{ display: "block" }}
                          >
                            To
                          </label>
                          <DatePicker
                            id="to_month"
                            name="to_month"
                            size="small"
                            className="dataentry_input"
                            inputFormat="DD-MM-YYYY"
                            minDate={fromDate}
                            maxDate={moment().format()}
                            value={toDate}
                            onChange={(date) => {
                              setToDate(date["$d"]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                helperText={null}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                               }}
                               autocomplete="off"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <hr className="tag-line-border" />
                {isLoading ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      height: "480px",
                    }}
                  >
                    <Grid sm={1}>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                ) : (
                  <div style={{ height: 480, width: "100%" }}>
                    <DataGrid
                      rows={CrpVSBloodCultureRecords}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      showCellRightBorder={true}
                      getRowId={(row) => row.study_id}
                      className="tableborder babyHealthTable"
                      components={{
                        Footer: CustomPagination,
                        NoRowsOverlay: () => (
                          <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            No Data Found
                          </Stack>
                        ),
                        NoResultsOverlay: () => (
                          <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            No Data Found
                          </Stack>
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default CrpVsBloodCulture;
