import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import { Link } from "react-router-dom";
import Back from "../../../common/assets/images/Back.svg";
//import Back from "../../../common/assets/images/Back.svg";
import Close from "../../../common/assets/images/Close.svg";
import AdminEditImg from "../../../common/assets/images/components/admineditsvg";
import DeleteImg from "../../../common/assets/images/components/Deleteimg";
import DeleteWard from "./deleteWard";
import {
  addWardDetails,
  updateWardDetails,
  getWardDetail,
  setErrorMessage,
  setsuccessMessage,
  setAddWardFormData,
  activateWard,
} from "../../reducers/wardThunk";
import "../../../dataEntry/styles/DataEntry.scss";
import { DATA_FAIL, DATA_SUCCESS } from "../../../dataEntry/utils/mockData";
import CheckIcon from "@mui/icons-material/Check";
import { ToastContainer, toast } from "react-toastify";
import { WARD_FORM_MOCK } from "../../reducers/wardInitialState";

import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "../../../Dashboard/components/DashboardScreen";
const AddWard = (props) => {
  const dispatch = useDispatch();
  const addWard = useSelector((state) => state.ward);
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);
  const [addWardData, setAddWardData] = useState(addWard.warddata);
  const [Warddata, setWardData] = React.useState([]);
  const [filteredWards, setFilteredWards] = useState([]);
  const [errorFields, setErrorFields] = useState({});
  const [showWardNameErrorMessage, setWardNameErrorMessage] = useState();

  const [deleteSelectedWard, setDelete] = React.useState(false);
  const [SelectedWard, setSelectedWard] = useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState(-1);
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  const hospital_id = user.userProfile.hospital_id;

  useEffect(() => {
    //const hospital_id = 162;
    dispatch(getWardDetail(hospital_id));
  }, []);

  useEffect(() => {
    if (addWard && addWard.wardList) setWardData(addWard?.wardList);
    else setWardData([]);
  }, [addWard?.wardList]);

  useEffect(() => {
    updateFilteredWards(selectedStatus);
  }, [Warddata]);

  const updateFilteredWards = (status) => {
    if (status == -1) {
      setFilteredWards([].concat(Warddata));
    } else {
      setFilteredWards(
        Warddata.filter((wards) => wards.deleted_flag == status)
      );
    }
  };

  const filterByStatus = (status) => {
    setSelectedStatus(status);
    updateFilteredWards(status);
  };

  const [open, setOpen] = React.useState(false);

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;
    const fieldvalue = event.target.value;
    const newFormData = { ...addWardData };
    newFormData[fieldname] = fieldvalue;
    setAddWardData(newFormData);
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    const newFormData = { ...addWardData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    //const hospital_id = 162;
    if (!addWardData?.ward_id) {
      const req = { hospital_id, newFormData };
      dispatch(addWardDetails(req))
        .unwrap()
        .then((resdata) => {
          dispatch(getWardDetail(hospital_id));
          if(resdata.status == "200"){
          toast.success(resdata.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        else {
          toast.error(resdata.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
          setOpen(false);
        });
    } else if (addWardData?.ward_id) {
      const ward_id = addWardData?.ward_id;
      const req = { branch_id: ward_id, hospital_id, newFormData };
      dispatch(updateWardDetails(req))
        .unwrap()
        .then((resdata) => {
          setAddWardData({});
          dispatch(getWardDetail(hospital_id));
          if(resdata.status == "200"){
          toast.success(resdata.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        else{
          toast.error(resdata.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
          setOpen(false);
        });
    }
  };

  useEffect(() => {
    if (addWard.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 2000);
    }
  }, [addWard.errorMessage]);

  useEffect(() => {
    if (addWard.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 2000);
    }
  }, [addWard.successMessage]);

  const closeDialog = () => {
    setOpen(false);
    setDelete(false);
  };

  const handleClose = () => {
    setOpen(false);
    setAddWardData();
  };

  const handleClickOpen = () => {
    setOpen(true);
    setAddWardData();
  };

  const handleOpen = (params) => {
    const data = addWard.wardList.filter(
      (d) => d.ward_id === params.row.ward_id
    );
    setAddWardData(data[0]);
    dispatch(setAddWardFormData(data[0]));
    setOpen(true);
  };

  const activateWardRecord = (params) => {
    dispatch(activateWard(params.row.ward_id)).then(() => {
      toast.success("Ward Activated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(getWardDetail(hospital_id));
    });
  };

  const deleteWardData = (params) => {
    const data = addWard.wardList.filter(
      (d) => d.ward_id === params.row.ward_id
    );
    setSelectedWard(data[0]);
    setDelete(true);
  };

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const verifyWardName = (event) => {
    if (event.target.value.length >= "51" || event.target.value.length <= "2") {
      setWardNameErrorMessage("Ward Name Should be Between 3 to 50 Characters");
      setErrorFields(Object.assign({}, errorFields, { ward_name: true }));
      handleAddFormChange(event);
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { ward_name: false }));
      setWardNameErrorMessage("");
    }
  };

  const columns = [
    {
      field: "ward_name",
      headerName: <span className="gridheader_font">Ward Name</span>,
      width: 500,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.ward_name}</span>
      ),
    },
    {
      field: "deleted_flag",
      flex: 1,
      headerName: <span className="gridheader_font">Status</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">
          {params.row.deleted_flag ? "Deleted" : "Active"}
        </span>
      ),
    },

    {
      field: "Edit",
      headerName: <span className="gridheader_font">Actions</span>,
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              background: "#6572E4",
              //background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            onClick={() => handleOpen(params)}
          >
            Edit
          </Button>

          {params.row.deleted_flag ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#008000",
                //background: "#F98F60",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => activateWardRecord(params)}
            >
              Activate
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#FF6347",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => deleteWardData(params)}
            >
              Delete
            </Button>
          )}
        </strong>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="branchcontent">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Card variant="none">
              <CardContent>
                <form id="mother-medical-form">
                  <Grid
                    container
                    style={{
                      borderBottom: "0.8px solid rgba(101, 114, 228, 0.2)",
                    }}
                  >
                    <Grid item xs={12} sm={8} className="header">
                      <h4 className="branch-header">List Of Wards</h4>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <label
                        htmlFor="Sort"
                        className="dataentry_label"
                        style={{
                          display: "block",
                          fontSize: "16px",
                          padding: "0px 20px",
                          marginTop: "0px",
                        }}
                      >
                        Status
                        <Select
                          labelId="Role"
                          id="status"
                          name="status"
                          label=""
                          size="small"
                          className="select_usertype"
                          value={selectedStatus}
                          onChange={(event) => {
                            filterByStatus(event.target.value);
                          }}
                        >
                          <MenuItem value="-1">All</MenuItem>
                          <MenuItem value="0">Active</MenuItem>
                          <MenuItem value="1">Deleted</MenuItem>
                        </Select>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={1} className="header">
                      <Button
                        variant="outlined"
                        className="branch_Add"
                        onClick={handleClickOpen}
                      >
                        + Add Ward
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: "10px" }}>
                    <Grid item xs={12} sm={12} className="header">
                      <div style={{ height: 550, width: "100%" }}>
                        <DataGrid
                          rows={filteredWards}
                          columns={columns}
                          pageSize={8}
                          rowsPerPageOptions={[10]}
                          showCellRightBorder={true}
                          showColumnRightBorder={true}
                          getRowId={(row) => row.ward_id}
                          cellClassName="customCell"
                          components={{
                            Footer: CustomPagination,
                            NoRowsOverlay: () => (
                              <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center"
                              >
                                No Wards Found
                              </Stack>
                            ),
                            NoResultsOverlay: () => (
                              <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center"
                              >
                                No Wards Found
                              </Stack>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </form>

                {/* <Grid container>
            {Warddata?.map((num) => (
              <Grid item xs={12} sm={12} className="formSection">
                <Button
                  variant="contained"
                  className="wardlist"
                  type="submit"
                  onClick={() => handleOpen(props)}
                >
                  <span key={num?.ward_id}>{num?.ward_name}</span>
                </Button>
              </Grid>
            ))}
          </Grid> */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box>
          <Dialog
            open={open}
            onClose={handleClose}
            className="dialogWidth1"
            //style={{ minWidth: "400px" }}
          >
            <DialogTitle
              style={{
                borderBottom: "0.8px solid",
                borderColor: "rgba(101, 114, 228, 0.4)",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>
                <Link
                  className="FIle-left"
                  onClick={closeDialog}
                  style={{ marginRight: "20px" }}
                >
                  <img
                    src={Back}
                    className="file-img-left"
                    alt=""
                    style={{ width: "12px" }}
                  />
                </Link>
                {!addWardData?.ward_id ? "Add" : "Update"} Ward
              </span>
              <Link
                className="FIle-right-extreme"
                onClick={closeDialog}
                style={{ marginRight: "2px" }}
              >
                <img
                  src={Close}
                  className="file-img-right-extreme2"
                  style={{
                    alignItems: "flex-end",
                    marginRight: "20px",
                  }}
                  alt=""
                />
              </Link>
            </DialogTitle>
            <DialogContent>
              <form id="mother-medical-form" onSubmit={HandleAddFormSubmit}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <label
                      htmlFor="Ward Name"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Ward Name
                      <span style={{ paddingLeft: "2px", color: "red" }}>
                        *
                      </span>
                    </label>
                    <TextField
                      id="ward_name"
                      name="ward_name"
                      label=""
                      required
                      variant="outlined"
                      className="dataentry_input2"
                      size="small"
                      value={addWardData?.ward_name}
                      error={errorFields.ward_name && showWardNameErrorMessage}
                      helperText={
                        errorFields.ward_name && showWardNameErrorMessage
                          ? showWardNameErrorMessage
                          : ""
                      }
                      onChange={(e) => {
                        handleAddFormChange(e);
                        verifyWardName(e);
                      }}
                    />
                  </Grid>
                </Grid>

                <div className="formSection">
                  <Grid container style={{ justifyContent: "center" }}>
                    {!addWardData?.ward_id ? (
                      <Grid item xs={12} sm={6}>
                        {isValid ? (
                          <Button
                            variant="contained"
                            className="purple-add1"
                            type="submit"
                          >
                            <span>Add Ward</span>
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            className="back1"
                            disabled
                          >
                            <span>Add Ward</span>
                          </Button>
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6}>
                        {isValid ? (
                          <Button
                            variant="contained"
                            className="purple-add1"
                            type="submit"
                          >
                            <span>Update Ward</span>
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            className="back1"
                            disabled
                          >
                            <span>Update Ward</span>
                          </Button>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
      {addWard.errorMessage && (
        <Snackbar
          open={addWard.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {addWard.errorMessage}
          </Alert>
        </Snackbar>
      )}
      {addWard.successMessage && (
        <Snackbar
          open={addWard.successMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {addWard.successMessage}
          </Alert>
        </Snackbar>
      )}

      {deleteSelectedWard && (
        <DeleteWard
          SelectedWard={SelectedWard}
          open={deleteSelectedWard}
          onClose={closeDialog}
        />
      )}
    </React.Fragment>
  );
};

export default AddWard;
