import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { json, useNavigate, Link } from "react-router-dom";
import {
  Box,
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  MenuItem,
  Select,
  Button,
  Stack,
  CardHeader,
  colors,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import ReactSpeedometer from "react-d3-speedometer";

import "../styles/DataEntry.scss";
import Reports from "./Reports";
import Close from "../../common/assets/images/Close.svg";
import Back from "../../common/assets/images/Back.svg";
import {
  INVASIVE,
  NON_INVASIVE,
  QUICK_SEPS,
  SEMI_INVASIVE,
} from "../utils/mockData";
import {
  generateScore,
  NonInvasiveScoreData,
  QuickSepsisScore,
  SemiInvasiveScoreData,
} from "../utils/generateScore";
import TrendView from "./TrendView";

import {
  babyPredictionScore,
  reqRefferralOpinion,
  multiPartFileUpload,
  updateScore,
  babyNonInvasivePredictionScore,
  babyQuickSepsisScorePrediction,
  babySemiInvasiveScorePrediction,
} from "../reducers/patientDataThunk";
import { ColorizeSharp } from "@mui/icons-material";
import DoctorOpinion from "./DrOpinion";
import ReferralDoctorOpinion from "./ReferralDoctorOpinion";
import GauzeChart from "./GauzeChart";

const GenerateScore = (props) => {
  const textColor = "#000000";
  const patient = useSelector((state) => state.patient);
  const studyId = patient.bmrndata?.study_id;
  const study_id = patient.bmrndata?.study_id;
  const reading = patient.bmrndata?.reading_id;
  const entity_type = patient.globalRecord?.entity_type;


  console.log('jgjngjgnjgbg',props)


  const entityTypeSel1 = patient.bmrndata?.entity_type;
  const baby_medical_record_number =
    patient.bmrndata?.baby_medical_record_number;
  let reportName;
  //let score, color, risk;
  // let selectedFiles="";
  const [babyInvDataScore, setBabyInvData] = useState("");
  const [selectedFiles, setSelectedFiles] = useState("");
  const [downloadFiles, setDownloadFiles] = useState("");
  const [addScoreData, setAddScoreData] = useState({});
  const [listFiles, setListFiles] = useState([]);
  const [aiExplanation, setExplanation] = useState([]);
  const [requestJson, setRequestJson] = useState({
    referral_id: "",
    study_id: "",
    reading: "",
    fileNames: "",
  });

  const [explainableAIOpen, setExplainableAI] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [prescDoc, setPrescDoc] = React.useState(false);
  const [DrOpinion, setDrOpinion] = React.useState(false);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [genScoreValue, setGenScore] = React.useState(false);
  const [babyInvScore, setBabyInvScore] = React.useState();
  const [scoreResult, setScoreResult] = useState(null);
  console.log('11111111111111111',babyInvScore,scoreResult)
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const handleClose = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const openDialog = () => {
    setOpen(true);
  };

  const ExplainableAI = () => {
    setExplainableAI(true);
  };

  const PrescriptionDoc = () => {
    navigate("/PrescDoc");
  };

  const DrPrescription = () => {
    navigate("/DrPrescription");
  };
  const closeDialog = () => {
    setOpen(false);
    setPrescDoc(false);
    setExplainableAI(false);
  };
  /* useEffect(() => {
      const req = { study_id };
      dispatch(getMotherProfile(req));
      dispatch(getBabyProfile(req));
    }, []); */
  const genscore = () => {
    const formDataScore = generateScore(patient);
    dispatch(babyPredictionScore(formDataScore))
      .then((resdata) => {
        let scoreData = resdata.payload.score;
        const explanationData = prepareExplanationData(
          resdata.payload.explanation
        );
        setExplanation(explanationData);
        let ColorCode = "";
        scoreData = parseFloat(scoreData.toFixed(1));
        const generateScore1 = {
          scoreData,
          color: getColorCode(scoreData),
          risk: riskAssess(getColorCode(scoreData)) + ` (${scoreData})`,
        };

        const req = {
          study_id,
          reading,
          score: scoreData,
          explanation: JSON.stringify(explanationData),
        };
        dispatch(updateScore(req));

        setAddScoreData(generateScore1);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const NonInvasiveScore = () => {
    setTimeout(() => {
    const formData = NonInvasiveScoreData(patient);
    dispatch(babyNonInvasivePredictionScore(formData))
      .then((resdata) => {
        let scoreData = resdata.payload.score;
        const explanationData = prepareExplanationData(
          resdata.payload.explanation
        );
        setExplanation(explanationData);
        scoreData = parseFloat(scoreData.toFixed(1));
        const generateScore1 = {
          scoreData,
          color: getColorCode(scoreData),
          risk: riskAssess(getColorCode(scoreData)) + ` (${scoreData})`,
        };

        const req = {
          study_id,
          reading,
          score: scoreData,
          explanation: JSON.stringify(explanationData),
        };
        dispatch(updateScore(req));

        setAddScoreData(generateScore1);
      })
      .catch((e) => {
        console.log(e);
      });
    }, 1000);
  };
  const quickSepsisScore = () => {
    const formData = QuickSepsisScore(patient);
    dispatch(babyQuickSepsisScorePrediction(formData))
      .then((resdata) => {
        let scoreData = resdata.payload.score;
        const explanationData = prepareExplanationData(
          resdata.payload.explanation
        );
        setExplanation(explanationData);
        scoreData = parseFloat(scoreData.toFixed(1));
        const generateScore1 = {
          scoreData,
          color: getColorCode(scoreData),
          risk: riskAssess(getColorCode(scoreData)) + ` (${scoreData})`,
        };

        const req = {
          study_id,
          reading,
          score: scoreData,
          explanation: JSON.stringify(explanationData),
        };
        dispatch(updateScore(req));

        setAddScoreData(generateScore1);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const SemiInvasiveScore = () => { setTimeout(() => {
    const formData = SemiInvasiveScoreData(patient);
    dispatch(babySemiInvasiveScorePrediction(formData))
      .then((resdata) => {
        let scoreData = resdata.payload.score;
        const explanationData = prepareExplanationData(
          resdata.payload.explanation
        );
        setExplanation(explanationData);
        scoreData = parseFloat(scoreData.toFixed(1));
        const generateScore1 = {
          scoreData,
          color: getColorCode(scoreData),
          risk: riskAssess(getColorCode(scoreData)) + ` (${scoreData})`,
        };
        const req = {
          study_id,
          reading,
          score: scoreData,
          explanation: JSON.stringify(explanationData),
        };
        dispatch(updateScore(req));
        setAddScoreData(generateScore1);
      })
      .catch((e) => {
        console.log(e);
      }); 
    }, 1000);
  };

  const prepareExplanationData = (explanationData) => {
    const positiveValues = {},
      negativeValues = {};
    const fields = Object.keys(explanationData);
    fields.forEach((field) => {
      explanationData[field] >= 0
        ? (positiveValues[field] =
            Math.round(explanationData[field] * 100) / 100)
        : (negativeValues[field] =
            Math.round(explanationData[field] * 100) / 100);
    });

    return { positiveValues, negativeValues };
  };


    React.useEffect(() => {
    if (props && props.fromDashboard) {
      const scoreData = {
        scoreData: props.score,
        color: getColorCode(props.score),
        risk: riskAssess(getColorCode(props.score)),
      };
      setAddScoreData(scoreData);
      setExplanation(JSON.parse(props.explanation));
    } 
    else if(props && props.genScore){
      
      if (entityTypeSel1 === INVASIVE) 
        { 
          console.log('babyInvScorebabyInvScorebabyInvScorebabyInvScorebabyInvScore',babyInvScore)
        const formDataScore = generateScore(patient,babyInvScore);
        dispatch(babyPredictionScore(formDataScore))
        .then((resdata) => {
          let scoreData = resdata.payload.score;
          const explanationData = prepareExplanationData(
            resdata.payload.explanation
          );
          setExplanation(explanationData);
          let ColorCode = "";
          scoreData = parseFloat(scoreData.toFixed(1));
          const generateScore1 = {
            scoreData,
            color: getColorCode(scoreData),
            risk: riskAssess(getColorCode(scoreData)) + ` (${scoreData})`,
          };

          const req = {
            study_id,
            reading,
            score: scoreData,
            explanation: JSON.stringify(explanationData),
          };
          dispatch(updateScore(req));

          setAddScoreData(generateScore1);
        })
        .catch((e) => {
          console.log(e);
        });
      } else if (entityTypeSel1 === NON_INVASIVE) {
        NonInvasiveScore();
      } else if (entityTypeSel1 === QUICK_SEPS) {
        quickSepsisScore();
      } else if (entityTypeSel1 === SEMI_INVASIVE) {
        SemiInvasiveScore();
      }
  }}, []);

  const getColorCode = (score) => {
    if (score >= 0 && score <= 5) {
      return "#74D976";
    }
    if (score > 5 && score <= 8) {
      return "#F9D372";
    }
    if (score > 8 && score <= 10) {
      return "#F56D6D";
    }
  };

  const riskAssess = (colorCode) => {
    if (colorCode === "#74D976") {
      return "Low Risk";
    }
    if (colorCode === "#F9D372") {
      return "Medium Risk";
    }
    if (colorCode === "#F56D6D") {
      return "High Risk";
    }
  };

  const uploadFile = (event) => {
    setSelectedFiles(event.target.files);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    let currentFile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", currentFile);
    dispatch(multiPartFileUpload(formData))
      .unwrap()
      .then((resdata) => {
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const downloadReport = (event) => {};

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Card variant="none">
            <CardContent>
              <form id="score-generation">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} className="header">
                    <h4>Predictive Score</h4>
                    <hr className="tag-line-border" />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} className="score-grid">
                    <p className="BMRNScoreGeneration">
                      BMRN: <span>{baby_medical_record_number}</span>
                    </p>
                    <div
                      // className="GauzeChart"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "1rem",
                      }}
                    >
                      {/*  <ReactSpeedometer
                        width={330}
                        height={250}
                        forceRender={true}
                        maxSegmentLabels={1}
                        customSegmentStops={[0, 5, 8, 10]}
                        segmentColors={["#74d976", "#f9d372", "#f56d6d"]}
                        needleColor={addScoreData?.color}
                        currentValueText={addScoreData?.risk}
                        minValue={0}
                        maxValue={10}
                        value={addScoreData?.scoreData}
                        textColor={textColor}
                        ringWidth={27}
                        needleHeightRatio={0.7}
                      /> */}
                      <GauzeChart data={addScoreData} />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} className="table-grid">
                    <h4>Score Risk Range</h4>
                    <TableContainer
                      style={{
                        borderRadius: "0.6rem",
                        border: "1px solid #C1C6F4",
                      }}
                    >
                      <Table aria-label="simple table">
                        <TableHead className="TableHead">
                          <TableRow className="TableRow">
                            <TableCell
                              align="center"
                              id="table-cell"
                              style={{ fontWeight: "600" }}
                            >
                              Risk Type
                            </TableCell>
                            <TableCell
                              align="center"
                              id="table-cell"
                              style={{ fontWeight: "600" }}
                            >
                              Score Range
                            </TableCell>
                            <TableCell
                              align="center"
                              id="table-cell"
                              style={{ fontWeight: "600" }}
                            >
                              Color
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              id="table-cell"
                              className="tableFontColor"
                            >
                              Low Risk
                            </TableCell>
                            <TableCell align="center" id="table-cell">
                              0 - 5.0
                            </TableCell>
                            <TableCell align="center" id="table-cell">
                              <span className="color-indicator bg-green"></span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" id="table-cell">
                              Medium Risk
                            </TableCell>
                            <TableCell align="center" id="table-cell">
                              5.1 - 8.0
                            </TableCell>
                            <TableCell align="center" id="table-cell">
                              <span className="color-indicator bg-orange"></span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" id="table-cell">
                              High Risk
                            </TableCell>
                            <TableCell align="center" id="table-cell">
                              8.1 - 10
                            </TableCell>
                            <TableCell align="center" id="table-cell">
                              <span className="color-indicator bg-red"></span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                    <label htmlFor="btn-upload">
                      <Button
                        className="DownloadReports"
                        variant="contained"
                        component="span"
                        onClick={ExplainableAI}
                        size="large"
                      >
                        Explainable AI
                      </Button>
                    </label>
                    {explainableAIOpen && (
                      <Dialog open={explainableAIOpen} onClose={closeDialog}>
                        <DialogTitle
                          style={{
                            borderBottom:
                              "0.5px solid rgba(101, 114, 228, 0.5)",
                          }}
                        >
                          <Link
                            className="FIle-left"
                            onClick={closeDialog}
                            style={{ marginRight: "20px" }}
                          >
                            <img
                              src={Back}
                              className="file-img-left"
                              alt=""
                              style={{ width: "12px" }}
                            />
                          </Link>
                          <span>Explainable AI</span>
                          <Link
                            className="FIle-right-extreme"
                            onClick={closeDialog}
                          >
                            <img
                              src={Close}
                              className="file-img-right-extreme"
                              alt=""
                            />
                          </Link>
                        </DialogTitle>
                        <DialogContent>
                          {aiExplanation && (
                            <Card
                              variant="none"
                              style={{
                                marginTop: "20px",
                                border: "0.5px solid rgba(101, 114, 228, 0.5)",
                              }}
                            >
                              <CardContent>
                                {aiExplanation.positiveValues && (
                                  <Grid container>
                                    <Grid
                                      md={6}
                                      style={{
                                        paddingRight: "5px",
                                        borderRight:
                                          "1px solid rgba(101, 114, 228, 0.5)",
                                        textAlign: "right",
                                      }}
                                    >
                                      <h4>Negative</h4>
                                    </Grid>
                                    <Grid md={6}>
                                      <div style={{ paddingLeft: "5px" }}>
                                        <h4>Positive</h4>
                                        {Object.keys(
                                          aiExplanation.positiveValues
                                        ).map((key) => {
                                          return (
                                            <div>
                                              {key}:
                                              {
                                                aiExplanation?.positiveValues[
                                                  key
                                                ]
                                              }
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </Grid>
                                  </Grid>
                                )}
                                {aiExplanation.negativeValues && (
                                  <Grid container>
                                    <Grid md={6}>
                                      <div
                                        style={{
                                          textAlign: "right",
                                          paddingRight: "5px",
                                          borderRight:
                                            "1px solid rgba(101, 114, 228, 0.5)",
                                        }}
                                      >
                                        {Object.keys(
                                          aiExplanation.negativeValues
                                        ).map((key) => {
                                          return (
                                            <div>
                                              {key}:
                                              {
                                                aiExplanation.negativeValues[
                                                  key
                                                ]
                                              }
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </Grid>
                                  </Grid>
                                )}
                              </CardContent>
                            </Card>
                          )}
                        </DialogContent>
                      </Dialog>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                    <label htmlFor="btn-upload">
                      <Button
                        className="DownloadReports"
                        variant="contained"
                        component="span"
                        onClick={PrescriptionDoc}
                        size="large"
                      >
                        Download Reports
                      </Button>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                    <Button
                      variant="contained"
                      className="ReferralDoctor"
                      size="medium"
                      onClick={openDialog}
                    >
                      Referral Doctor Opinion
                    </Button>
                    {open && (
                      <ReferralDoctorOpinion
                        open={open}
                        onClose={closeDialog}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                    <Button
                      className="PrescriptionDocument"
                      color="primary"
                      variant="contained"
                      component="span"
                      size="medium"
                      onClick={DrPrescription}
                    >
                      Prescription Document
                    </Button>
                    {open && (
                      <ReferralDoctorOpinion
                        open={open}
                        onClose={closeDialog}
                      />
                    )}
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="none">
            <CardContent>
              <form id="reports-generation">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <h4>Reports</h4>
                    <hr className="tag-line-border" />
                  </Grid>
                </Grid>
                <Reports />
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default GenerateScore;
