import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import { Link } from "react-router-dom";
import Back from "../../../common/assets/images/Back.svg";
//import Back from "../../../common/assets/images/Back.svg";
import Close from "../../../common/assets/images/Close.svg";
import AdminEditImg from "../../../common/assets/images/components/admineditsvg";
import DeleteImg from "../../../common/assets/images/components/Deleteimg";
import DeleteWard from "./deleteWard";
import {
  //updateWardDetails,
  getHospitalRoles,
  updateRoleDetails,
} from "../../reducers/wardThunk";
import "../../../dataEntry/styles/DataEntry.scss";
import { DATA_FAIL, DATA_SUCCESS } from "../../../dataEntry/utils/mockData";
import CheckIcon from "@mui/icons-material/Check";
import { ToastContainer, toast } from "react-toastify";
import { WARD_FORM_MOCK } from "../../reducers/wardInitialState";

import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "../../../Dashboard/components/DashboardScreen";
const RolesList = (props) => {
  const Ward = useSelector((state) => state.ward);
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);
  const [SelectedWard, setSelectedWard] = useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState(-1);
  const [RolesList, setRolesLoaded] = React.useState([]);
  const [roleData, setRoleData] = useState();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const hospital_id = user.userProfile.hospital_id;

  useEffect(() => {
    dispatch(getHospitalRoles(hospital_id))
      .unwrap()
      .then((resdata) => {
        setRolesLoaded(resdata?.response);
      });
  }, [hospital_id]);

  const closeDialog = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpen = (params) => {
    const data = RolesList.filter((d) => d.role_id === params.row.role_id);
    setRoleData(data[0]);
    setOpen(true);
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;
    const fieldvalue = event.target.value;
    const newFormData = { ...roleData };
    newFormData[fieldname] = fieldvalue;
    setRoleData(newFormData);
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    const newFormData = { ...roleData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    const role_id = roleData?.role_id;
    const alias_name = newFormData?.alias_name;
    const req = { role_id, hospital_id, alias_name };
    dispatch(updateRoleDetails(req))
      .unwrap()
      .then((resdata) => {
        setRoleData({});
        dispatch(getHospitalRoles(hospital_id))
          .unwrap()
          .then((resdata) => {
            setRolesLoaded(resdata?.response);
          });
        toast.success("Role Details Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setOpen(false);
      });
  };

  const columns = [
    {
      field: "role_name",
      headerName: <span className="gridheader_font">Role Name</span>,
      width: 300,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.role_name}</span>
      ),
    },
    {
      field: "alias_name",
      headerName: <span className="gridheader_font">Alias Name</span>,
      width: 300,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.alias_name}</span>
      ),
    },
    {
      field: "Edit",
      headerName: <span className="gridheader_font">Actions</span>,
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              background: "#6572E4",
              //background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            onClick={() => handleOpen(params)}
          >
            Edit
          </Button>
        </strong>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="branchcontent">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Card variant="none">
              <CardContent>
                <form id="mother-medical-form">
                  <Grid
                    container
                    style={{
                      borderBottom: "0.8px solid rgba(101, 114, 228, 0.2)",
                    }}
                  >
                    <Grid item xs={12} sm={8} className="header">
                      <h4 className="branch-header">List Of Roles</h4>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: "10px" }}>
                    <Grid item xs={12} sm={12} className="header">
                      <div style={{ height: 550, width: "100%" }}>
                        <DataGrid
                          rows={RolesList}
                          columns={columns}
                          pageSize={8}
                          rowsPerPageOptions={[10]}
                          showCellRightBorder={true}
                          showColumnRightBorder={true}
                          getRowId={(row) => row.role_id}
                          cellClassName="customCell"
                          components={{
                            Footer: CustomPagination,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box>
          <Dialog
            open={open}
            onClose={handleClose}
            className="wardDialogWidth"
            
            //style={{ minWidth: "400px" }}
          >
            <DialogTitle
              style={{
                borderBottom: "0.8px solid",
                borderColor: "rgba(101, 114, 228, 0.4)",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>
                <Link
                  className="FIle-left"
                  onClick={closeDialog}
                  style={{ marginRight: "20px" }}
                >
                  <img
                    src={Back}
                    className="file-img-left"
                    alt=""
                    style={{ width: "12px" }}
                  />
                </Link>
                Update Role
              </span>
              <Link className="FIle-right-extreme" onClick={closeDialog}>
                <img
                  src={Close}
                  //className="file-img-right-extreme"
                  style={{
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                  alt=""
                />
              </Link>
            </DialogTitle>
            <DialogContent>
              <form id="mother-medical-form" onSubmit={HandleAddFormSubmit}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <label
                      htmlFor="Ward Name"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Role Name
                    </label>
                    <TextField
                      id="role_name"
                      name="alias_name"
                      label=""
                      required
                      variant="outlined"
                      className="dataentry_input2"
                      size="small"
                      value={roleData?.alias_name}
                      onChange={(e) => {
                        handleAddFormChange(e);
                      }}
                    />
                  </Grid>
                </Grid>

                <div className="formSection">
                  <Grid container style={{ justifyContent: "center" }}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        className="purple-add1"
                        type="submit"
                      >
                        <span>Update Role</span>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default RolesList;
