import React, { useState, useEffect } from "react";
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  IconButton,
  Stack,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "../styles/DataEntry.scss";
import "../../common/styles/header.scss";
import AddImg from "../../common/assets/images/components/addImg";
import DeleteImgIcon from "../../common/assets/images/components/DeleteImgIcon";
import moment from "moment";
import DeleteRec from "../../Dashboard/components/DeleteRec";
import DeleteSelReading from "./DeleteSelReading";

import { useDispatch, useSelector } from "react-redux";
import {
  setCheckedBabyFinalDiag,
  getPatientReadings,
  setSelectedreading,
  deletePatientReading,
  getDataEntryScreenByStudyID,
  getBabyBranch,
  addNewGlobalRecord,
  getPatientBasicDetails,
  setFormTab,
  getIndividualBabyMedicalRecord,
} from "../reducers/patientDataThunk";
import { getdataentryscreen } from "../../Dashboard/reducers/Dashboardthunk";

import { initialState } from "../reducers/InitialState";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
const BabyBMRNo = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const loggedUserId = user.userProfile?.user_id;
  const hospitalId = user.userProfile.hospital_id;
  const readingdata = useSelector((state) => state.readingdata);
  let studyId = patient.bmrndata?.study_id || patient.basicData?.study_id;
  const dbbranchdata = useSelector((state) => state.dashboard);
  const [ptReadings, setPtReadings] = useState([]);
  const ptbmrn = patient.bmrndata?.baby_medical_record_number || patient.basicData?.baby_medical_record_number;
  const [readingDate, setReadingDate] = useState();
  const [dischargeFlag, setDischargeFlag] = useState("1");
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [selectedPatient1, setSelectedPatient1] = useState([]);
  const [deleteselrec, setDelete] = React.useState(false);
  const [deleteselreading, setDelete1] = React.useState(false);
  const [readingLoaded, setReadingLoaded] = React.useState(true);
  const [selectedReadingId, setSelectedReadingId] = useState(0);
  const [branch, setBranch] = useState({});
  const userdata = sessionStorage.getItem("user");
  const allowedPermissions = userdata ? JSON.parse(userdata).permissions : {};
  const [finalDiagDiagnosis, setFinalDiagnosis] = React.useState(false);
  console.log('user',user)

  const handleChange = (event) => {
    setFinalDiagnosis(event.target.checked);
  };
 useEffect(() => {
    console.log('llllllllllllllllllllll',ptbmrn,patient)
   if(ptbmrn){
    const req = { baby_medical_record_number: ptbmrn,hospitalId };

    dispatch(getPatientBasicDetails(req)).unwrap()
    .then((resdata) => {
      const disFlag = resdata.response.discharge_flag;
      setDischargeFlag(disFlag);
    });
  }
}, [ptbmrn]);

useEffect(() => {
  //setSelectedReadingId(patient?.bmrndata?.reading_id);
  let dataEntryData = sessionStorage.getItem("dataEntry");
  if (dataEntryData) {
    dataEntryData = JSON.parse(dataEntryData);
    setSelectedReadingId(dataEntryData.reading);
    if (dataEntryData.baby_medical_record_number) {
      dataEntryData.baby_medical_record_number
        ? dispatch(getPatientBasicDetails(dataEntryData))
        : undefined;
      dataEntryData.study_id
        ? dispatch(getIndividualBabyMedicalRecord(dataEntryData))
        : undefined;
      dataEntryData.activeTab
        ? dispatch(setFormTab(dataEntryData.activeTab))
        : undefined;
        dataEntryData.reading
          ? dispatch(setSelectedreading(dataEntryData.reading))
          : undefined;
          const req = { reading : dataEntryData.reading, studyid : dataEntryData?.study_id };
          dispatch(getDataEntryScreenByStudyID(req));

    }
  }
}, []);
  const handleReadingHistory = (e) => {
    const readingId = e.target.value;
    setSelectedReadingId(readingId);
    dispatch(setSelectedreading(readingId));

    const reading = e.target.value;

    const req = { reading, studyid: studyId };
    dispatch(getDataEntryScreenByStudyID(req))
      .unwrap()
      .then((resdata) => {
        sessionStorage.setItem(
          "dataEntry",
          JSON.stringify({
            baby_medical_record_number:
              patient.bmrndata.baby_medical_record_number,
            study_id: patient.bmrndata.study_id,
            reading: reading,
            activeTab: 3,
          })
        );
        navigate("/dataentry");
      });
  };

  const addnewreading = (e, ptbmrn) => {
    e.preventDefault();
    const bmrn = patient.basicData;
    const hospitalBranchId = branch?.hospital_branch_id;
    const req = {
      hospitalId,
      hospitalBranchId,
      addBMRNData: { ...bmrn, created_by: loggedUserId },
    };
    dispatch(addNewGlobalRecord(req)).then((resdata) => {
      const readingId = resdata.payload.response.reading_id;
      setSelectedReadingId(readingId);
      dispatch(setSelectedreading(readingId));

      navigate("/dataentry");
    });
  };

  const deleteReading = (e, bmrndata) => {
    setSelectedPatient1(bmrndata);
    setDelete1(true);
    e.preventDefault();
    const readingId = selectedReadingId;
    const req = { readingId, studyId };

    dispatch(deletePatientReading(req)).then(() => {
      getExistingReadings();
    }); 
  };

  const deleteBMRN = (e, bmrndata) => {
    e.preventDefault();
    setSelectedPatient(bmrndata);
    setDelete(true);
  };

  const closeAndReloadDialog = () => {
    closeDialog();
  };

  const closeDialog = () => {
    setDelete(false);
    setDelete1(false);
  };

  useEffect(() => {
    getExistingReadings();
  }, [patient?.bmrndata?.study_id || patient?.basicData?.study_id, location]);

  useEffect(() => {
    if (patient.bmrndata?.study_id || patient.basicData?.study_id) {
      dispatch(getBabyBranch({ studyid: patient.bmrndata?.study_id || patient.basicData?.study_id })).then(
        (data) => {
          if (data?.payload?.response && data?.payload?.response[0]) {
            setBranch(data?.payload?.response[0]);
          }
        }
      );
    }
  }, [patient?.bmrndata?.study_id || patient?.basicData?.study_id]);

  const getExistingReadings = () => {
    studyId = patient.bmrndata?.study_id || patient.basicData?.study_id;
    const req = { studyId };
    dispatch(getPatientReadings(req)).then((resdata) => {
      const ptReadings = resdata?.payload?.response;
      setPtReadings(ptReadings);
    });
  };

  useEffect(() => {
    if(patient?.bmrndata?.reading_id){
    console.log('llllllllllllllllllllll',patient?.bmrndata)
    setSelectedReadingId(patient?.bmrndata?.reading_id);
    setReadingLoaded(false);
    setTimeout(() => {
      setReadingLoaded(true);
    });
  }
  }, [patient?.bmrndata?.reading_id]);

  useEffect(() => {
    if (ptReadings && ptReadings.length && selectedReadingId) {
      ptReadings.map((reading) => {
        if (reading.reading_id == selectedReadingId)
          setReadingDate(reading.createdAt);
      });
    }
  }, [selectedReadingId, ptReadings]);

  const today = new Date(),
    time = today.getHours() + ":" + today.getMinutes();
  const values = {
    someDate: moment().format("DD - MM - YY"),
  };

  return (
    <div className="dataoutlet">
      <Grid container>
        <Grid item xs={12} sm={12}>
          <div className="bmrncontent">
            <Card variant="none">
              <CardContent className="bmrn-data">
                <div>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {location?.pathname === "/AddBMRN" && (
                      <Grid item xs={6} sm={1}>
                        <div>
                          <Link to={"/NewBMRN"}>
                            <img src={Back} alt="" />
                          </Link>
                        </div>
                      </Grid>
                    )}
                  {location?.pathname === "/dataentry" && (
                      <Grid item xs={6} sm={1}>
                        <div>
                          <Link to={"/Dashboard"}>
                            <img src={Back} alt="" />
                          </Link>
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Grid container>
                        {patient.formTab && (
                          <Grid item xs={12} sm={4} className="bmrn">
                            BMRN
                            <br />
                            <span>
                              {patient.bmrndata?.baby_medical_record_number || patient.basicData?.baby_medical_record_number}
                            </span>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={2} className="bmrn">
                          {location?.pathname != "/NewBMRN" &&
                            location?.pathname != "/AddBMRN" && (
                              <>
                                DATE
                                <br />
                                <span>
                                  {readingDate
                                    ? moment(readingDate).format("DD - MM - YY")
                                    : ""}
                                </span>
                              </>
                            )}
                        </Grid>

                        <Grid item xs={12} sm={2} className="bmrn">
                          {location?.pathname != "/NewBMRN" &&
                            location?.pathname != "/AddBMRN" && (
                              <>
                                Time
                                <br />
                                <span>
                                  {readingDate
                                    ? moment(readingDate).format("HH:MM")
                                    : ""}
                                </span>
                              </>
                            )}
                        </Grid>
                        {patient.formTab && (
                          <Grid item xs={12} sm={2} className="bmrn">
                            Branch
                            <br />
                            <span>{branch?.branch_name}</span>
                          </Grid>
                        )}
                        {patient.formTab && (
                          <Grid item xs={12} sm={2} className="bmrn">
                            Entry Type
                            <br />
                            <span>{patient.bmrndata?.entity_type || patient.basicData?.entity_type}</span>
                          </Grid>
                        )}
                        {/* {patient.formTab && (
                          <Grid item xs={12} sm={1} className="bmrn">
                            <img
                              src={editIcon}
                              className="editicon"
                              onClick={props.editBmrnData}
                            />
                          </Grid>
                        )} */}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      {patient.formTab && (
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid item xs={12} sm={4}>
                            {location?.pathname != "/NewBMRN" &&
                              location?.pathname != "/AddBMRN" && (
                                <FormGroup>
                                  <FormControlLabel
                                    value="end"
                                    control={
                                      <Switch
                                        color="primary"
                                        checked={finalDiagDiagnosis}
                                        onChange={handleChange}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        name="finalDiagDiagnosis"
                                      />
                                    }
                                    label="Final Reading"
                                    labelPlacement="end"
                                  />
                                </FormGroup>
                              )}
                          </Grid>
                          {location?.pathname != "/NewBMRN" &&
                              location?.pathname != "/AddBMRN" && 
                            allowedPermissions &&
                            allowedPermissions.bmrn && (
                              <Grid item xs={12} sm={2}>
                                <p className="reading_History">
                                  READING HISTORY
                                </p>
                              </Grid>
                            )}
                          {
                            allowedPermissions &&
                            allowedPermissions.bmrn && (
                              <Grid item xs={12} sm={2}>
                                {readingLoaded && (
                                  <Select
                                    labelId="Reading_id"
                                    id="reading_id"
                                    name="reading_id"
                                    label=""
                                    size="small"
                                    className="select_usertype3"
                                    value={selectedReadingId}
                                    onChange={handleReadingHistory}
                                  >
                                    <MenuItem value="Select Readings" disabled>
                                      Select Readings
                                    </MenuItem>
                                    {ptReadings && ptReadings.length
                                      ? ptReadings.map((num) => (
                                          <MenuItem value={num.reading_id}>
                                            R{num.reading_id}
                                          </MenuItem>
                                        ))
                                      : undefined}
                                  </Select>
                                )}
                              </Grid>
                            )}
                          {location?.pathname == "/NewBMRN" &&
                            location?.pathname == "/AddBMRN" &&
                            allowedPermissions &&
                            allowedPermissions.bmrn && (
                              <Grid
                                style={{
                                  alignItems: "center",
                                  display: "grid",
                                }}
                                item
                                xs={12}
                                sm={2}
                              >
                                <IconButton
                                  className="addicon acrossBtn"
                                  onClick={(e) =>
                                    deleteBMRN(e, patient.bmrndata)
                                  }
                                >
                                  <DeleteImgIcon />
                                </IconButton>
                                <p className="add_reading sign-up-button">
                                  Delete BMRN
                                </p>
                              </Grid>
                            )}
                          {location?.pathname != "/NewBMRN" &&
                          location?.pathname != "/AddBMRN" &&
                          allowedPermissions &&
                          allowedPermissions.bmrn ? ( 
                          <>
                             {dischargeFlag == "1" && (
                            <Grid
                              style={{
                                alignItems: "center",
                                display: "grid",
                              }}
                              item
                              xs={12}
                              sm={2}
                            >
                              <IconButton
                                className="addicon acrossBtn"
                                onClick={(e) =>
                                  deleteReading(e, patient.bmrndata)
                                }
                              >
                                <DeleteImgIcon />
                              </IconButton>
                              <p className="add_reading sign-up-button">
                                Delete Reading
                              </p>
                            </Grid>
                            )}
                            </> 
                          ) : (
                            <Grid
                              style={{
                                alignItems: "center",
                                display: "grid",
                              }}
                              item
                              xs={12}
                              sm={2}
                            >
                              <IconButton
                                className="addicon acrossBtn"
                                onClick={(e) => deleteBMRN(e, patient.bmrndata)}
                              >
                                <DeleteImgIcon />
                              </IconButton>
                              <p className="add_reading sign-up-button">
                                Delete BMRN
                              </p>
                            </Grid>
                          )}
                          {location?.pathname != "/NewBMRN" &&
                            allowedPermissions &&
                            allowedPermissions.bmrn && (
                             <>
                             {dischargeFlag == "1" && (
                              <>
                              {patient && patient.basicData && patient.basicData.study_id && (
                              <Grid
                                style={{
                                  alignItems: "center",
                                  display: "grid",
                                }}
                                item
                                xs={12}
                                sm={2}
                              >
                                <IconButton
                                  className="addicon acrossBtn"
                                  onClick={(e) => addnewreading(e, ptbmrn)}
                                >
                                  <AddImg />
                                </IconButton>
                                <p className="add_reading sign-up-button">
                                  Add Reading
                                </p>
                              </Grid>
                              )}
                              </>
                             )}
                              </>
                            )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    color: "red",
                  }}
                >
                  <div></div>
                  <b>
                    {location?.pathname != "/NewBMRN" && (
                      <i>
                        * Leave fields unfilled if values/readings are not
                        available
                      </i>
                    )}
                  </b>
                </div>

                {deleteselrec && (
                  <DeleteRec
                    selectedPatient={selectedPatient}
                    open={deleteselrec}
                    onClose={closeAndReloadDialog}
                  />
                )}

                {deleteselreading && (
                  <DeleteSelReading
                    selectedPatient={selectedPatient1}
                    open={deleteselreading}
                    onClose={closeAndReloadDialog}
                  />
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BabyBMRNo;
