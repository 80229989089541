import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { Link } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import {
  TextField,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import "../styles/DataEntry.scss";
import * as _ from "lodash";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const PatientLabels = (props) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);
  const Entity_Type = props.SelectedPatientDetails.params;

  const closeDialog = (e) => {
    e.preventDefault();
    props.onClose();
  };

  const handleClose = () => {
    props.onClose();
  };
  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          fullWidth={props.fullWidth}
          maxWidth={props.maxWidth}
          open={props.open}
          onClose={props.onClose}
        >
          <DialogTitle>
            <Link
              className="FIle-left"
              onClick={closeDialog}
              style={{ marginRight: "20px" }}
            >
              <img
                src={Back}
                className="file-img-left"
                alt=""
                style={{ width: "12px" }}
              />
            </Link>
            Patient Records
            <Link className="FIle-right-extreme" onClick={closeDialog}>
              <img src={Close} style={{ paddingLeft: "77%" }} alt="" />
            </Link>
          </DialogTitle>
          <DialogContent>
            <div className="datacontent">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ background: "#F0F1FC" }}
                >
                  <Typography>Baby Profile</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Type of Record"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Type Of Record
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Admission Type"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Admission Type
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="POB"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Place Of Birth(Name)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="DOB"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Date Of Birth
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="POBpin"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Place Of Birth(Pincode)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="TOB"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Time Of Birth
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Gender"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Gender
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="DOA"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Date Of Admission
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="AAA"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Age at Admission
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ApgarScore1Min"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Apgar Score 1 Min
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ApgarScore5Min"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Apgar Score 5 Min
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ApgarScore10Min"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Apgar Score 10 Min
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ApgarScore1Min"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Place Of Delivery
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Gestational Age"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Gestational Age
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Pretern"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Pretern
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Weight At Birth"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Weight at Birth
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Weight At Admission"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Weight at Admission
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Day of Sepsis Event"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Day of Sepsis Event
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Probable Sepsis)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Probable Sepsis)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (EOS/LOS/NA)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (EOS/LOS/NA)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Meningitis)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Meningitis)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Umbical Sepsis)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Umbical Sepsis)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Skin Pustules)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Skin Pustules)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (RDS)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (RDS)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Jaundice)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Jaundice)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (LBW)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (LBW)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (TTNB)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (TTNB)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (LGA/AGA/SGA/NA)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (LGA/AGA/SGA/NA)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Congenital Heart Disease)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Congenital Heart Disease)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Anemia)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Anemia)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Perinatal Respiratory Depression)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Perinatal Respiratory Depression)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Hypoglycemia)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Hypoglycemia)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Hypocalcemia)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Hypocalcemia)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Feeding Intolerance)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Feeding Intolerance)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Thrombocytopenia)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Thrombocytopenia)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (Seizures)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diagnosis (Seizures)
                      </label>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ background: "#F0F1FC" }}
                >
                  <Typography>Mother Profile</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Mother Age"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Age
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Weight of the mother"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Weight
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Height"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Height
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="BMI"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        BMI
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Haemoglobin"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Haemoglobin
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="BPSystolic"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Blood Pressure(Systolic)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="BPDiastolic"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Blood Pressure (Diastolic)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diabetes"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Diabetes (Present)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Fever_present"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Fever (Present)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="MaternalFevereDurationCheckBox"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Fever Duration
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ThyroidFunction"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Thyroid Function
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ThyroidFunctionIfAbnormal"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Thyroid Function (If ABNORMAL)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ThyroidFunctionUnits"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Thyroid Function (TSH)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ThyroidFunctionUnits"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Thyroid Function (T3)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ThyroidFunctionUnits"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Thyroid Function (T4)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="MoreThan3VaginalExaminationDuringLabor"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        More than 3 Vaginal Examinations During Labor
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="LeakingPV"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Leaking PV
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Rupture of Membranes"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Rupture Of Membranes (ROM)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="PIH"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Pregnancy-induced hypertension (PIH)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ROM Type"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Rupture Of Membranes (Type)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="ROM If PROM"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Rupture Of Membranes(IF PROM)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis (EOS/LOS/NA)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Smelly Amniotic Fluid
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Diagnosis Chorioamnionitis"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Chorioamnionitis
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="GBS Infection"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        GBS Infection
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Urinary Tract Infection"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Urinary Tract Infection
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Torch Infection"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Torch Infection
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Type of Delivery"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Type Of Delivery
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Delayed Cord Clamping"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Delayed Cord Clamping
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Vaginal Swab Culture (Done)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Vaginal Swab Culture (Done)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Vaginal Swab Culture (Result)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Vaginal Swab Culture (Result)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Vaginal Swab Culture"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Vaginal Swab Culture (pH Value)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Amniotic Fluid Culture (Done)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Amniotic Fluid Culture (Done)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor="Amniotic Fluid Culture (Result)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        Amniotic Fluid Culture (Result)
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label
                        htmlFor=" AF Culture (If Positive)"
                        style={{ display: "block" }}
                        className="dataentry_label"
                      >
                        AF Index Culture (if Positive)
                      </label>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              {Entity_Type == "Invasive" ? (
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ background: "#F0F1FC" }}
                  >
                    <Typography>Baby Apperance</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor=" Reading Date"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Reading Date
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Admission Type"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Time of Reading (0-23 Hours)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Record Id"
                          className="padetais_label"
                          style={{ display: "block", color: "#878787" }}
                        >
                          Weight of Baby
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label htmlFor="Record Id" className="padetais_label">
                          Appearance
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Skin Colour"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Skin Colour
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Cry Sound"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Cry Sound
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Cry Sound"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Duration(If Not Cried)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Hypotonia Muscular Response (1 min after birth)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Hypotonia Muscular Response <br />
                          (1 min after birth)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Hypotonia Muscular Response (5 min after birth)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Hypotonia Muscular Response <br />
                          (5 min after birth)
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Hypothermia"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Hypothermia
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Weight At Birth"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Hypothermia (Units)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Excessive Sleeping"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Excessive Sleeping
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="baby_feeding_status"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Feeding Status
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="baby_presence_of_convulsions"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Presence Of Convulsions
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="baby_jaundice"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Jaundice (Present)
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="breast_feeding_initiation"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Breast Feeding Initiation
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Kangaroo Mother Care"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Kangaroo Mother Care
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="umbilical_redness"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Umbilical Redness
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="umbilical_enduration"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Umbilical Enduration
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="umbilical_discharge"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Umbilical Discharge
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="skin_pustules"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Skin Pustules {">"} 5
                        </label>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}
              {Entity_Type == "Invasive" ? (
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                    style={{ background: "#F0F1FC" }}
                  >
                    <Typography>Baby Respiratory</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Groaning"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Groaning
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Grunting"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Grunting
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Stridor"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Stridor
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Retraction"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Retraction
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Fast Breathing"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Fast Breathing
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Oxygen Saturation"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Oxygen Saturation
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Breathing Rate"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Breathing Rate (Breaths Per Minute)
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Chest In-drawing"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Chest In-drawing
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="X-Ray Status"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          X-Ray Status (Done)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="X-Ray Result"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          X-Ray Result
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="X-Ray Diagnosis (if Abnormal)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          X-Ray Diagnosis (if Abnormal)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Apnea Status (Presence)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Apnea Status (Presence)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Apnea Diagnosis"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Apnea Diagnosis
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Respiratory Support"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Respiratory Support
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Respiratory Support (Type)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Respiratory Support (Type)
                        </label>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}
              {Entity_Type == "Invasive" ? (
                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                    style={{ background: "#F0F1FC" }}
                  >
                    <Typography>Baby Cardio Vascular Function</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="HeartRate"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Heart Rate
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="UrineOutput"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Urine Output
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BPSystolic"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          BP (Systolic)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BPDiastolic"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          BP (Diastolic)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BPMeanArterial"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          BP (Mean Arterial)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="CapillaryRefill"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Capillary Refill
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="LowPeripheralPulseVolume"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Low Peripheral Pulse Volume
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="CoolPeripheries"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Cool Peripheries
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="2DEchoDone"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          2D Echo (Done)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="2DEchoResultIfYes"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          2D Echo Result (If Yes)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Ionotropes"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Ionotropes
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BabyOnCentralLines"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Central Lines(Yes/No/NA)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="CentralLineValue"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Central Lines
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor=" Central Lines Insert Date"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Central Lines(Insert Date)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor=" Central Lines Removed Date"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Central Lines(Removed Date)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Infusion of Blood Products"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Infusion of Blood Products
                        </label>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}
              {Entity_Type == "Invasive" ? (
                <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleChange("panel6")}
                >
                  <AccordionSummary
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                    style={{ background: "#F0F1FC" }}
                  >
                    <Typography>Baby CNS Function</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="FeaturesOfEncephalopathy"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Features of Encephalopathy
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Seizures"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Seizures
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="AbnormalMovementsLikeTonicPosturing"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Abnormal Movements like Tonic Posturing
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="AFBulge"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          AF Bulge
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BabyMovementWithStimulation"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Baby Movement with Stimulation
                        </label>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}
              {Entity_Type == "Invasive" ? (
                <Accordion
                  expanded={expanded === "panel7"}
                  onChange={handleChange("panel7")}
                >
                  <AccordionSummary
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                    style={{ background: "#F0F1FC" }}
                  >
                    <Typography>Baby GI Tract Function</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="AbdominalDistension"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Abdominal Distension
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="FrequencyOfStools"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Frequency of Stools
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Diarrhea"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Diarrhea
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Vomiting"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Vomiting
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="FeedingIntolerance"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Feeding Intolerance
                        </label>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}
              {Entity_Type == "Invasive" ? (
                <Accordion
                  expanded={expanded === "panel8"}
                  onChange={handleChange("panel8")}
                >
                  <AccordionSummary
                    aria-controls="panel6d-content"
                    id="panel6d-header"
                    style={{ background: "#F0F1FC" }}
                  >
                    <Typography>Baby Investigations</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Thyroid Status"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Thyroid Status
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Thyroid Result"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Thyroid Result (TSH)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Thyroid Result (T3)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Thyroid Result (T3)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Thyroid Result (T4)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Thyroid Result (T4)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Blood Glucose"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Blood Glucose
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Haemoglobin Level"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Haemoglobin Level
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="C-Reactive Protein Result"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          C-Reactive Protein Result
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="C-Reactive Protein Level"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          C-Reactive Protein Level
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Micro ESR"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Micro ESR Level (1st Hr)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Procalcitonin"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Procalcitonin Level
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Prothrombin Type(PT)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Prothrombin Time (PT)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Activated Partial Prothrombin"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Activated Partial Prothrombin
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Total Leucocytes Count"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Total Leucocytes Count
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Absolute Neutrophil Count"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Absolute Neutrophil Count
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Immature To Mature Neutrophil(I/T) Ratio"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Immature to mature neutrophil ratios {">"} 0.2
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Thrombocytopenia (Platelet Count)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Thrombocytopenia (Platelet Count)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Urine test for Pus Cells"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Urine test for Pus Cells (If {">"} 4 pus cells/HPF)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Urine culture test"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Urine Culture Test Result
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Blood Culture Result"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Blood Culture Test Result (BCTR)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Gram Positive Bacteria"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Gram Positive Bacteria (If BCTR Positive)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Gram Positive Bacteria"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Gram Positive Bacteria (If Other)
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Gram Negative Bacteria"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Gram Negative Bacteria (If BCTR Positive)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Gram Negative Bacteria (Other)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Gram Negative Bacteria (Other)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="FUNGI"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          FUNGI (If BCTR Positive)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Other Organism"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Other Organism (If BCTR Positive)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Antibiotic Status Sensitive"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Antimicrobial Testing ( Sensitive)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Antibiotic Status Resisitant"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Antimicrobial Testing ( Resisitant )
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Antibiotic Status Intermediate"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Antimicrobial Testing ( Intermediate )
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Sodium (Na)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Sodium Level (Na)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Potassium (K)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Potassium Level(K)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Chloride"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Chloride Level
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Calcium (Ca)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Calcium Level(Ca)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Phosphate (PO4)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Phosphate Level(PO4)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Magnesium (Mg)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Magnesium Level(Mg)
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Urea"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Urea Level
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Creatinine"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Creatinine Level
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Lactate level"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Lactate level
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Bilirubin level"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Bilirubin level
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Cord pH"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Cord pH
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="ECG - Arrhythmia (Present)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          ECG - Arrhythmia (Present)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="CSF Culture Value"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          CSF Culture Result
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="TSB Value (mg/dl)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          TSB Value
                        </label>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}
              {Entity_Type == "Invasive" ? (
                <Accordion
                  expanded={expanded === "panel9"}
                  onChange={handleChange("panel9")}
                >
                  <AccordionSummary
                    aria-controls="panel7d-content"
                    id="panel7d-header"
                    style={{ background: "#F0F1FC" }}
                  >
                    <Typography>Antiobiotic Administration</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Antibiotic Given"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Antibiotic Given
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Date of administration"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Date of Anti Biotic Administration
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Time of Administration"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Time of Anti Biotic Administration (0 - 23 Hrs)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Antibiotic Name"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Antibiotic Name
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Antibiotic Name (Other)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Antibiotic Name (Other)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Blood Samples for Culture test"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Blood Sample Collection Date
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Time of Blood Samples"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Blood Sample Collection Time
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Antibiotic Given"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Antibiotic Given Prior to Blood Sample Collection
                        </label>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}
              {Entity_Type == "Non-Invasive" ? (
                <Accordion
                  expanded={expanded === "panel10"}
                  onChange={handleChange("panel10")}
                >
                  <AccordionSummary
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                    style={{ background: "#F0F1FC" }}
                  >
                    <Typography>Non Invasive Parameters</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Appearance"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Appearance
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Skin Color"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Skin Color
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Cry Sound"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Cry Sound
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Feeding Status"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Feeding Status
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="
                          Breast Feeding Initiation"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Breast Feeding Initiation
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Groaning"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Groaning
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Grunting"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Grunting
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Fast Breathing"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Fast Breathing
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Oxygen Saturation"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Oxygen Saturation
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Breathing Rate"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Breathing Rate
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Heart Rate"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Heart Rate
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Baby Movement with Stimulation"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Baby Movement with Stimulation
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="AF Bulge"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          AF Bulge
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Abdominal Distension"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Abdominal Distension
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Frequency of Stools"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Frequency of Stools
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BP (Systolic)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          BP (Systolic)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BP (Diastolic)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          BP (Diastolic)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BP (Mean Arterial)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          BP (Mean Arterial)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Seizures"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Seizures
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Absolute Neutrophil Count"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Absolute Neutrophil Count
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Jaundice (Present)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Jaundice (Present)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Capillary Refill"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Capillary Refill
                        </label>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}

              {Entity_Type == "Semi-Invasive" ? (
                <Accordion
                  expanded={expanded === "panel11"}
                  onChange={handleChange("panel11")}
                >
                  <AccordionSummary
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                    style={{ background: "#F0F1FC" }}
                  >
                    <Typography>Semi Invasive Parameters</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      
                    <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Groaning"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Groaning
                        </label>
                      </Grid>
                      

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Breathing Rate"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Breathing Rate
                        </label>
                      </Grid>
                      
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Heart Rate"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Heart Rate
                        </label>
                      </Grid>
                      
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BP (Systolic)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          BP (Systolic)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BP (Diastolic)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          BP (Diastolic)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="BP (Mean Arterial)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          BP (Mean Arterial)
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Oxygen Saturation"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Oxygen Saturation
                        </label>
                      </Grid>
                      
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="
                          Abdominal Distension"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Abdominal Distension
                        </label>
                      </Grid>
                      
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Frequency of Stools"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Frequency of Stools
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Thrombocytopenia (Platelet Count)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Thrombocytopenia (Platelet Count)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Total Leucocytes Count"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Total Leucocytes Count
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Absolute Neutrophil Count"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Absolute Neutrophil Count
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="
                          Blood Glucose"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Blood Glucose
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Haemoglobin Level"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Haemoglobin Level
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="C-Reactive Protein Result"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          C-Reactive Protein Result
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Bilirubin level"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Bilirubin level
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Chloride Level"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Chloride Level
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Calcium Level(Ca)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Calcium Level(Ca)
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Sodium Level (Na)"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Sodium Level (Na)
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Potassium Level(K)"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Potassium Level(K)
                        </label>
                      </Grid>
                      

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Urea Level"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Urea Level
                        </label>
                      </Grid>
                      

                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Creatinine Level"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Creatinine Level
                        </label>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}

              {Entity_Type == "Quick-Sepsis-Calculator" ? (
                <Accordion
                  expanded={expanded === "panel11"}
                  onChange={handleChange("panel11")}
                >
                  <AccordionSummary
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                    style={{ background: "#F0F1FC" }}
                  >
                    <Typography>Quick Sepsis Parameters</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Skin Color"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Skin Color
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Cry Sound"
                          className="padetais_label"
                          style={{ display: "block" }}
                        >
                          Cry Sound
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Feeding Status"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Feeding Status
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Breathing Rate"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Breathing Rate
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Oxygen Saturation"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Oxygen Saturation
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Heart Rate"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Heart Rate
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label
                          htmlFor="Frequency of Stools"
                          style={{ display: "block" }}
                          className="padetais_label"
                        >
                          Frequency of Stools
                        </label>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}
            </div>
          </DialogContent>
        </Dialog>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default PatientLabels;
