import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: "https://api.presco.avyantra.com",
  //baseURL: "http://localhost:8080/",
});

axiosInstance.interceptors.request.use(
  function (config) {
    if (config.url.indexOf("signup") > -1 || config.url.indexOf("login") > -1) {
      return config;
    }
    const access_token = sessionStorage.getItem("access_token");

    const token = access_token ? access_token : "";
    if (token) {
      config.headers["access_token"] = token;
    }

    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// response parse
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.warn("Error status", error);
    // return Promise.reject(error)
    if (error.response) {
      if (
        error.response?.status === 401 &&
        window.location.pathname != "/login"
      ) {
        sessionStorage.clear();
        window.location = "/login";
      }
      console.warn("Error status", error.response);
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);
