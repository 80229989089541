import { createAsyncThunk } from "@reduxjs/toolkit";
import PatientDataService from "../../api/apiCommonService";

export const addAdminDetails = createAsyncThunk("addAdmindata", async (req) => {
  const res = await PatientDataService.addAdminDetails(req);
  return res.data;
});

export const getAdminDetail = createAsyncThunk(
  "hospitalAdminFetch",
  async (req) => {
    const res = await PatientDataService.getAdminDetail(req);
    return res.data;
  }
);

export const getUserRoles = createAsyncThunk("getUserRoles", async () => {
  const res = await PatientDataService.getUserRoles();
  return res.data;
});

export const getUserPermissions = createAsyncThunk("getUserPermissions", async () => {
  const res = await PatientDataService.getUserPermissions();
  return res.data;
});

export const getmapedAdminDetail = createAsyncThunk(
  "hospitalMappedAdminFetch",
  async (req) => {
    const res = await PatientDataService.getmapedAdminDetail(req);
    return res.data;
  }
);

export const getMappedAdminDetailsByUser = createAsyncThunk(
  "hospitalMappedAdminFetch",
  async (req) => {
    const res = await PatientDataService.getMappedAdminDetailsByUser(req);
    return res.data;
  }
);

export const getUserHierarchyDetail = createAsyncThunk(
  "UserHierarchyFetch",
  async (req) => {
    const res = await PatientDataService.getUserHierarchyDetail(req);
    return res.data;
  }
);

export const getUserPermissionDetail = createAsyncThunk(
  "UserPermissionFetch",
  async (req) => {
    const res = await PatientDataService.getUserPermissionDetail(req);
    return res.data;
  }
);

export const getBranchDetail = createAsyncThunk(
  "hospitalBranchFetch",
  async (req) => {
    const res = await PatientDataService.getBranchDetail(req);
    return res.data;
  }
);

export const getAdminsByRole = createAsyncThunk(
  "getAdminsByRole",
  async (req) => {
    const res = await PatientDataService.getAdminsByRole(req);
    return res.data;
  }
);

export const mapAdminWithBranch = createAsyncThunk(
  "mapAdminWithBranch",
  async (req) => {
    const res = await PatientDataService.mapAdminWithBranch(req);
    return res.data;
  }
);

export const mapUserHierarchy = createAsyncThunk(
  "mapUserHierarchy",
  async (req) => {
    const res = await PatientDataService.mapUserHierarchy(req);
    return res.data;
  }
);

export const mapUserPermission = createAsyncThunk(
  "mapUserPermission",
  async (req) => {
    const res = await PatientDataService.mapUserPermission(req);
    return res.data;
  }
);

export const updateAdminDetails = createAsyncThunk(
  "updateAdminDetails",
  async (req) => {
    const res = await PatientDataService.updateAdminDetails(req);
    return res.data;
  }
);

export const setEditAdminData = createAsyncThunk(
  "isAdminEdit",
  async (data) => data
);
