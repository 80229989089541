import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Checkbox,
  ListItemText,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  FormGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  Button,
  Snackbar,
} from "@mui/material";

import "../styles/DataEntry.scss";
import { XRAY_DIAGNOSIS, APENA, RESP_SUPPORT } from "../utils/mockData";
import { ToastContainer, toast } from "react-toastify";
const Numerics = /^[0-9 ]*$/;

import {
  babyRespAddition,
  setBabyRespiratoryData,
  getBabyRespiratoryByReadingId,
  updatePatientFormData,
  setFormTab,
  getPatientLastReadingData,
  getPatientBasicDetails,
} from "../reducers/patientDataThunk";
import * as _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";

const Respiratory = (props) => {
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patient);

  // const respipdata = patient?.babyRespiratoryData;
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);
  const [addRespiratoryData, setAddRespiratoryData] = useState({
    baby_respiratory_support_if_yes: [],
  });
  const [updateRecord, setUpdateRecord] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [
    showOxygenSaturationErrorMessage,
    setShowOxygenSaturationErrorMessage,
  ] = useState();
  const [showBreathingRateErrorMessage, setShowBreathingRateErrorMessage] =
    useState();
  const [isLoading, setIsLoading] = useState(true);
  console.log('addRespiratoryData',addRespiratoryData)

  let url = `patient/update/baby_resp`;
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const hospitalId = user.userProfile.hospital_id;
  const loggedUserId = user.userProfile.user_id;
  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  const reading = patient?.Selectedreadingid;
  const readingId =
    JSON.stringify(reading) != "{}" ? reading : patient?.bmrndata?.reading_id;
    const ptbmrn = patient.bmrndata?.baby_medical_record_number;
    const [dischargeFlag, setDischargeFlag] = useState("1");
    
  useEffect(() => {
   
    const req = { baby_medical_record_number: ptbmrn,hospitalId };
    dispatch(getPatientBasicDetails(req)).unwrap()
    .then((resdata) => {
      const disFlag = resdata.response.discharge_flag;
      setDischargeFlag(disFlag);
    });
}, [ptbmrn]);

  useEffect(() => {
    if (patient?.bmrndata?.reading_id && patient.bmrndata?.study_id) {
      updateData(patient?.bmrndata?.reading_id, patient.bmrndata?.study_id);
    }
  }, [patient?.bmrndata?.reading_id, patient.bmrndata?.study_id]);

  const updateData = (readingId, studyid) => {
    const req = { readingId, studyid };
    dispatch(getBabyRespiratoryByReadingId(req))
      .unwrap()
      .then((resdata) => {
        if(resdata.response){
        let respiratoryData = {
          response: Object.assign({}, resdata.response),
        };

        const data =
          respiratoryData.response &&
          respiratoryData.response.baby_respiratory_support_if_yes
            ? (respiratoryData.response.baby_respiratory_support_if_yes =
                JSON.parse(
                  respiratoryData.response.baby_respiratory_support_if_yes
                ))
            : undefined;

        const data1 =
          respiratoryData.response && respiratoryData.response.id
            ? setUpdateRecord(true)
            : setUpdateRecord(false);
        if (respiratoryData.response) {
          setAddRespiratoryData(
            respiratoryData.response &&
              JSON.stringify(respiratoryData.response) != "{}"
              ? respiratoryData.response
              : { baby_respiratory_support_if_yes: [] }
          );
        }
      }
      else{
        const study_id = patient?.bmrndata?.study_id;
        //const req = { studyid };
    dispatch(getPatientLastReadingData(study_id)) .unwrap()
    .then((resdata) => {
      let respiratoryData = {
        response: Object.assign({}, resdata.response.baby_resp),
      };

      const data =
        respiratoryData.response &&
        respiratoryData.response.baby_respiratory_support_if_yes
          ? (respiratoryData.response.baby_respiratory_support_if_yes =
              JSON.parse(
                respiratoryData.response.baby_respiratory_support_if_yes
              ))
          : undefined;

      const data1 =
        respiratoryData.response && respiratoryData.response.id
          ? setUpdateRecord(false)
          : setUpdateRecord(false);
      if (respiratoryData.response) {
        setAddRespiratoryData(
          respiratoryData.response &&
            JSON.stringify(respiratoryData.response) != "{}"
            ? respiratoryData.response
            : { baby_respiratory_support_if_yes: [] }
        );
      }

      })
      }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (event) => {
    const fieldname = event.target.name;
    let fieldvalue = event.target.value;
    if (fieldname == "baby_respiratory_support_if_yes") {
      let baby_respiratory = event.target.value.map((data) => {
        return data.itemName ? data.itemName : data;
      });
      let value = baby_respiratory;
      baby_respiratory.indexOf(baby_respiratory[baby_respiratory.length - 1]) !=
      baby_respiratory.length - 1
        ? (value = baby_respiratory.filter(
            (br) => br !== baby_respiratory[baby_respiratory.length - 1]
          ))
        : null;
      fieldvalue = value;
    }

    const newFormData = { ...addRespiratoryData };
    newFormData[fieldname] = fieldvalue;
    // newFormData[fieldname] = fieldvalue.name;
    setAddRespiratoryData(newFormData);
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;

    const fieldvalue = event.target.value;

    const newFormData = { ...addRespiratoryData };
    newFormData[fieldname] = fieldvalue;
    setAddRespiratoryData(newFormData);
    setBabyRespiratoryData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    await submitData(5);
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);

  const submitData = async (nextTab) => {
    const newFormData = { ...addRespiratoryData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }

    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    newFormData.id = "";
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;

    newFormData.baby_respiratory_support_if_yes = JSON.stringify(
      newFormData.baby_respiratory_support_if_yes
    );
    if (!updateRecord) {
      dispatch(babyRespAddition(newFormData))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyRespiratoryByReadingId(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const req = {
        url,
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updatePatientFormData(req))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyRespiratoryByReadingId(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const verifyOxygenSaturation = (event) => {
   
    if (event.target.value >= 0 && event.target.value <= 100) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          oxygen_saturation: false,
        })
      );
      setShowOxygenSaturationErrorMessage("");
    } else {
      setShowOxygenSaturationErrorMessage(
        "Oxygen Saturation Value Should be Between 0 To 100"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          oxygen_saturation: true,
        })
      );
    }
  };

  const verifyBreathingRate = (event) => {
    if (event.target.value >= 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          breathing_rate: false,
        })
      );
      setShowBreathingRateErrorMessage("");
    } else {
      setShowBreathingRateErrorMessage(
        "Breathing Rate Value Should be Between 0 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          breathing_rate: true,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      {isLoading ? (
        <div
          style={{
            height: "200px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <Card variant="none">
          <CardContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Record ID"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Record Id
                  </label>
                  <TextField
                    id="study_id"
                    name="study_id"
                    label=""
                    variant="outlined"
                    value={patient.bmrndata?.study_id}
                    onChange={handleAddFormChange}
                    className="dataentry_input"
                    size="small"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Groaning"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Groaning
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    name="groaning"
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addRespiratoryData?.groaning}
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="groaning"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="groaning"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="groaning"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Grunting"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Grunting
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="grunting"
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addRespiratoryData?.grunting}
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="grunting"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="grunting"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="grunting"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Stridor"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Stridor
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="stridor"
                    id="stridor"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addRespiratoryData?.stridor}
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="stridor"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="stridor"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="stridor"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Retraction"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Retraction
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="retraction"
                    id="retraction"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addRespiratoryData?.retraction}
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="retraction"
                      value="Yes"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="retraction"
                      value="No"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="retraction"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Fast Breathing"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Fast Breathing
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="fast_breathing"
                    id="fast_breathing"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addRespiratoryData?.fast_breathing}
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="fast_breathing"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="fast_breathing"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="fast_breathing"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Oxygen Saturation"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Oxygen Saturation
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <FormGroup row>
                    <TextField
                      id="OxygenSaturation"
                      label=""
                      name="oxygen_saturation"
                      variant="outlined"
                      size="small"
                      disabled={dischargeFlag=="0"}
                      className={
                        "dataentry_input " +
                        (errorFields.oxygen_saturation ? "errorField" : "")
                      }
                      value={addRespiratoryData?.oxygen_saturation}
                      InputProps={{
                        
                        inputProps: {
                          maxLength: 3,
                        },
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        handleAddFormChange(e);
                        verifyOxygenSaturation(e);
                      }}
                      error={
                        errorFields.oxygen_saturation &&
                        showOxygenSaturationErrorMessage
                      }
                      errorText={showOxygenSaturationErrorMessage}
                      helperText={
                        errorFields.oxygen_saturation &&
                        showOxygenSaturationErrorMessage
                          ? showOxygenSaturationErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (
                          !Numerics.test(event.key) &&
                          event.key != "Backspace"
                        ) {
                          event.preventDefault();
                          setShowOxygenSaturationErrorMessage(
                            "Only Numerics are Acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              oxygen_saturation: true,
                            })
                          );
                        }
                      }}
                      
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Breathing Rate"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Breathing Rate (Breaths Per Minute)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="BreathingRate"
                    name="breathing_rate"
                    variant="outlined"
                    size="small"
                    disabled={dischargeFlag=="0"}
                    className={
                      "dataentry_input " +
                      (errorFields.breathing_rate ? "errorField" : "")
                    }
                    value={addRespiratoryData?.breathing_rate}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBreathingRate(e);
                    }}
                    error={
                      errorFields.breathing_rate &&
                      showBreathingRateErrorMessage
                    }
                    errorText={showBreathingRateErrorMessage}
                    helperText={
                      errorFields.breathing_rate &&
                      showBreathingRateErrorMessage
                        ? showBreathingRateErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBreathingRateErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            breathing_rate: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        maxLength: 3,
                      },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Per Minute
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Chest In-drawing"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Chest In-drawing
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="baby_chest_indrawing"
                    id="baby_chest_indrawing"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addRespiratoryData?.baby_chest_indrawing}
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="baby_chest_indrawing"
                      value="Yes"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="baby_chest_indrawing"
                      value="No"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="baby_chest_indrawing"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="X-Ray Status"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    X-Ray Status (Done)
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="x_ray_status_done"
                    id="x_ray_status_done"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addRespiratoryData?.x_ray_status_done}
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="x_ray_status_done"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="x_ray_status_done"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="x_ray_status_done"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                {addRespiratoryData?.x_ray_status_done == "YES" && (
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="X-Ray Result"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    X-Ray Result
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="x_ray_result"
                    id="x_ray_result"
                    exclusive
                    //required
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addRespiratoryData?.x_ray_result}
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="x_ray_result"
                      value="Normal"
                      size="small"
                      className="dataentry_input"
                    >
                      Normal
                    </ToggleButton>
                    <ToggleButton
                      name="x_ray_result"
                      value="AbNormal"
                      size="small"
                      className="dataentry_input"
                    >
                      Abnormal
                    </ToggleButton>
                    <ToggleButton
                      name="x_ray_result"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                )}
                
                {addRespiratoryData?.x_ray_result == "AbNormal" && (
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="X-Ray Diagnosis (if Abnormal)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    X-Ray Diagnosis (if Abnormal)
                  </label>
                  {addRespiratoryData ? (
                    <Select
                      labelId="X-Ray Diagnosis"
                      id="x_ray_diagnosis_any_other"
                      name="x_ray_diagnosis_any_other"
                      label=""
                      disabled={dischargeFlag=="0"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select_edu"
                      value={addRespiratoryData?.x_ray_diagnosis_any_other}
                    >
                      {XRAY_DIAGNOSIS?.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  ) : undefined}
                </Grid>
)}
                {addRespiratoryData?.x_ray_diagnosis_any_other === "Other" && (
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="X-Ray Diagnosis (if Other)"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      X-Ray Diagnosis (if Other)
                    </label>
                    <TextField
                      id="x_ray_status"
                      name="x_ray_status"
                      variant="outlined"
                      size="small"
                      className="dataentry_input"
                      value={addRespiratoryData?.x_ray_status}
                      onChange={handleAddFormChange}
                      disabled={dischargeFlag=="0"}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Apnea Status (Presence)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Apnea Status (Presence)
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="apnea_status"
                    id="apnea_status"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addRespiratoryData?.apnea_status}
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="apnea_status"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="apnea_status"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="apnea_status"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                {addRespiratoryData?.apnea_status === "YES" && (
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Apnea Diagnosis"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Apnea Diagnosis
                  </label>
                  {addRespiratoryData ? (
                    <Select
                      labelId="X-Ray Diagnosis"
                      id="apnea_diagnosis"
                      name="apnea_diagnosis"
                      label=""
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select_edu"
                      value={addRespiratoryData?.apnea_diagnosis}
                      disabled={dischargeFlag=="0"}
                    >
                      {APENA.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  ) : undefined}
                </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Respiratory Support"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Respiratory Support
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    name="baby_respiratory_support"
                    id="baby_respiratory_support"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    disabled={dischargeFlag=="0"}
                    value={addRespiratoryData?.baby_respiratory_support}
                  >
                    <ToggleButton
                      name="baby_respiratory_support"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="baby_respiratory_support"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="baby_respiratory_support"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              
              {addRespiratoryData && addRespiratoryData?.baby_respiratory_support === "YES" && (
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Respiratory Support (Type)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Respiratory Support (Type)
                  </label>
                  {addRespiratoryData ? (
                    <Select
                      labelId="baby_respiratory_support_if_yes"
                      id="baby_respiratory_support_if_yes"
                      className="dataentry_select_edu"
                      name="baby_respiratory_support_if_yes"
                      onChange={handleChange}
                      multiple
                      size="small"
                      disabled={dischargeFlag=="0"}
                      value={
                        addRespiratoryData?.baby_respiratory_support_if_yes
                          ? addRespiratoryData?.baby_respiratory_support_if_yes
                          : []
                      }
                      renderValue={(selected) => {
                        return selected.map((s) => s).join(",");
                      }}
                      /* renderValue={(selected) => {
                        return selected && selected.length
                          ? selected.map((s) => s).join(",")
                          : [];
                      }} */
                    >
                      {RESP_SUPPORT.map((name) => (
                        <MenuItem key={name.id} value={name}>
                          <Checkbox
                            checked={
                              addRespiratoryData?.baby_respiratory_support_if_yes?.indexOf(
                                name.itemName
                              ) > -1
                            }
                          />
                          <ListItemText primary={name?.itemName} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : undefined}
                </Grid>
              )}
              </Grid>

              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {isValid ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    className="buttonStyle sign-up-button"
                    type="submit"
                    disabled={dischargeFlag=="0"}
                    style={{cursor:"pointer"}}
                  >
                    Save {"&"} Next
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="gray"
                    aria-label="add"
                    className="buttonStyle1 sign-up-button"
                    disabled
                  >
                    Save {"&"} Next
                  </Button>
                )}
              </div>
            </form>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Respiratory;
