import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  AlertTitle,
  Pagination,
} from "@mui/material";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import AddIcon from "@mui/icons-material/Add";
import "../../dataEntry/styles/DataEntry.scss";
import ReadingSummary from "./ReadingSummary";
import ViewTrend from "./ViewTrend";
import ViewReport from "./reportsview";
import DeleteRec from "./DeleteRec";
import moment from "moment";
import DeleteImg from "../../common/assets/images/components/Deleteimg";
import { DBDATEFILTER, FILTERORDER } from "../../dataEntry/utils/mockData";
import {
  getDashboardListing,
  getListingByBranch,
  getDashboardListingBySearch,
  getDataEntryScreenByStudyID,
  addNewGlobalRecord,
  setSelectedreading,
  getBabyBranch,
} from "../../dataEntry/reducers/patientDataThunk";
import { getmapedBranchDetail } from "../../Admin/reducers/wardThunk";
import {
  getBranchDetail,
  getBranchDetailByUser,
  getdataentryscreen,
  getWardDetailbyBranch,
  getAdminDetailByBranch,
  updateDashboardBranch,
  updateDashboardWard,
} from "../reducers/Dashboardthunk";
import Stack from "@mui/material/Stack";

import CircularProgress from "@mui/material/CircularProgress";

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      shape="rounded"
      showFirstButton
      showLastButton
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "0.5rem",
      }}
    />
  );
}

const DashboardScreen = (props) => {
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);
  const patient = useSelector((state) => state.patient);
  const dbbranchdata = useSelector((state) => state.dashboard);
  const loggedUserId = user.userProfile?.user_id;
  const [branchdata, setbranchdata] = React.useState([]);
  const [warddata, setwardData] = React.useState([]);
  const [adminData, setAdminData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [trend, setTrend] = React.useState(false);
  const [deleteselrec, setDelete] = React.useState(false);
  const [report, setReport] = React.useState(false);
  const [bmrnOptions, setBmrnOptions] = React.useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [sortType, setSortType] = React.useState("desc");
  const [sortOrder, setSortOrder] = useState("desc");
  const [subScrDialog, setSubScrDialog] = React.useState(false);
  const [selectedBranch, setSelectedBranch] = React.useState(
    dbbranchdata.dashboardBranch ? dbbranchdata.dashboardBranch : -1
  );
  const [selectedWard, setSelectedWard] = React.useState(
    dbbranchdata.dashboardWard ? dbbranchdata.dashboardWard : -1
  );
    const [branch, setBranch] = useState({});
  const [selectedAdmin, setSelectedAdmin] = React.useState(-1);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const userdata = sessionStorage.getItem("user");
  const hospital_id = user.userProfile.hospital_id;
  const subScription = user.subScriptionStatus?.isSubscribed;

  const is_admin = userdata ? JSON.parse(userdata).is_admin : false;
  const allowedPermissions = userdata ? JSON.parse(userdata).permissions : {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const readsummary = (params) => {
    setSelectedPatient(params.row);
    setOpen(true);
  };
  const rptview = (params) => {
    setSelectedPatient(params.row);
    setReport(true);
  };
  const TrendView = (params) => {
    setSelectedPatient(params.row);
    setTrend(true);
  };
  const Deletereading = (e, params) => {
    e.preventDefault();
    setSelectedPatient(params.row);
    setDelete(true);
    getDashboardData();
  };

  const openSubScrDialog = () => {
    setSubScrDialog(true);
  };
  const handleClose = () => {
    setSubScrDialog(false);
  };

  const selectedreading = (e, params) => {
    e.preventDefault();
    const reading = params.row.reading;
    const studyid = params.row.study_id;
    console.log('oooooooooooooo',studyid,reading)
    dispatch(setSelectedreading(reading));

    const req = { reading, studyid };
    dispatch(getDataEntryScreenByStudyID(req))
      .unwrap()
      .then((resdata) => {
        sessionStorage.setItem(
          "dataEntry",
          JSON.stringify({
            baby_medical_record_number: params.row.baby_medical_record_number,
            study_id: params.row.study_id,
            reading: params.row.reading,
            activeTab: 3,
            hospitalId:hospital_id,
          })
        );

        navigate("/dataentry");
      });
  };

  const openaddbmrn = () => {
    dispatch(getdataentryscreen());
    navigate("/NewBMRN");
  };
  const addnewreading = (e, params) => {
    e.preventDefault();
    const bmrn = params.row;

            dispatch(getBabyBranch({ studyid: bmrn?.study_id})).then(
            (data) => {
              if (data?.payload?.response && data?.payload?.response[0]) {
                console.log('lllyyyyyyyyyy',data?.payload?.response[0])
                setBranch(data?.payload?.response[0]);
              }
            }
          );

    const hospitalId = user.userProfile.hospital_id;

    const hospitalBranchId = branch?.hospital_branch_id;
    //const hospitalBranchId = bmrn?.branch_id;
    const req = {
      hospitalId,
      hospitalBranchId,
      addBMRNData: { ...bmrn, created_by: loggedUserId },
    };

    console.log('lllllllllllll',req)
    dispatch(addNewGlobalRecord(req)).then((resdata) => {
      const reading = resdata.payload.response.reading_id;
      const readingId = resdata.payload.response.reading_id;
      const studyid = params.row.study_id;

      const req = { reading, studyid };

      dispatch(setSelectedreading(readingId));
      dispatch(getDataEntryScreenByStudyID(req))
        .unwrap()
        .then((resdata) => {
          sessionStorage.setItem(
            "dataEntry",
            JSON.stringify({
              baby_medical_record_number: params.row.baby_medical_record_number,
              study_id: params.row.study_id,
              reading: reading,
              activeTab: 3,
            })
          );

          navigate("/dataentry");
        });
    });
  };

  const closeAndReloadDialog = () => {
    closeDialog();
    getDashboardData();
  };

  const closeDialog = () => {
    setOpen(false);
    setTrend(false);
    setReport(false);
    setDelete(false);
  };

  const getColorCode = (score) => {
    if (score >= 0 && score <= 5) {
      return "#74D976";
    }
    if (score > 5 && score <= 8) {
      return "#F9D372";
    }
    if (score > 8 && score <= 10) {
      return "#F56D6D";
    }
  };

  useEffect(() => {
    if (user?.userProfile?.hospital_id) {
      dispatch(getmapedBranchDetail(user.userProfile.hospital_id));

      if (is_admin) {
        const req1 = { userId: user.userProfile?.user_id };
        dispatch(getBranchDetailByUser(req1));
      } else {
        const req1 = { hospital_id: user.userProfile.hospital_id };
        dispatch(getBranchDetail(req1));
      }
    }
  }, [user?.userProfile?.hospital_id, user?.globalSearchString]);

  useEffect(() => {
    getDashboardData();
  }, [
    user?.userProfile?.hospital_id,
    user?.globalSearchString,
    sortType,
    sortOrder,
    selectedBranch,
    selectedWard,
    selectedAdmin,
  ]);

  const getDashboardData = () => {
    setDataLoaded(false);
    const req = {
      hospitalId: user.userProfile.hospital_id,
      sortType,
      sortOrder,
      searchString: user.globalSearchString,
    };

    if (selectedBranch && selectedBranch != -1) {
      req.branch_id = selectedBranch;
    }
    if (selectedWard && selectedWard != -1) {
      req.ward_id = selectedWard;
    }
    if (selectedAdmin && selectedAdmin != -1) {
      req.admin_id = selectedAdmin;
    }

    if (user.userProfile.hospital_id) {
      if (user.globalSearchString && user.globalSearchString.length > 0) {
        dispatch(getDashboardListingBySearch(req))
          .unwrap()
          .then((resdata) => {
            setTimeout(() => {
              setDataLoaded(true);
            }, 100);
          });
      } else {
        dispatch(getDashboardListing(req))
          .unwrap()
          .then((resdata) => {
            setDataLoaded(true);
          });
      }
    }
  };

  useEffect(() => {
    const data = patient?.patientList?.map((patient) => {
      return { ...patient };
    });
    setBmrnOptions(data.concat([]));
  }, [patient.patientList]);

  const onBranchSelected = (e) => {
    dispatch(updateDashboardBranch(e.target.value));
    setSelectedBranch(e.target.value);
    const branch_id = e.target.value;
    const ward_id = "";
    const req = { hospital_id, branch_id, ward_id };
    dispatch(getWardDetailbyBranch(req));
    const req1 = { hospital_id, branch_id };
    dispatch(getAdminDetailByBranch(req1));
  };
  const onWardSelected = (e) => {
    dispatch(updateDashboardWard(e.target.value));
    const branch_id = selectedBranch;
    const ward_id = e.target.value;
    setSelectedWard(e.target.value);
    const req = { hospital_id, branch_id, ward_id };
  };
  const onAdminSelected = (e) => {
    const branch_id = selectedBranch;
    const admin_id = e.target.value;
    setSelectedAdmin(e.target.value);
    const req = { hospital_id, branch_id, admin_id };
  };

  const onSortSelected = (e) => {
    setSortOrder(e.target.value);
  };

  useEffect(() => {
    if (is_admin) {
      setbranchdata(dbbranchdata.branchList?.branches);
    } else {
      setbranchdata(dbbranchdata.branchList);
    }
  }, [dbbranchdata.branchList]);

  useEffect(() => {
    setwardData(dbbranchdata.wardListbyBranch);
  }, [dbbranchdata.wardListbyBranch]);
  useEffect(() => {
    setAdminData(dbbranchdata.AdminListByBranch);
  }, [dbbranchdata.AdminListByBranch]);

  React.useEffect(() => {
    if (bmrnOptions && bmrnOptions.length) {
      bmrnOptions.map((bmrnOption) => {
        const data = bmrnOption.score
          ? (bmrnOption.color = getColorCode(bmrnOption.score))
          : undefined;
      });
    }
  }, [bmrnOptions]);

  const scoreAction = (e, params) => {
    e.preventDefault();
    const reading = params.row.reading;
    const studyid = params.row.study_id;

    const req = { reading, studyid };
    dispatch(getDataEntryScreenByStudyID(req))
      .unwrap()
      .then((resdata) => {
        sessionStorage.setItem(
          "dataEntry",
          JSON.stringify({
            baby_medical_record_number: params.row.baby_medical_record_number,
            study_id: params.row.study_id,
            reading: reading,
            //activeTab: 12,
            explanation: resdata.response.explanation,
          })
        );
        navigate("/dataentry", {
          state: {
            fromDashboard: true,
            score: params.row.score,
            explanation: resdata.response.explanation,
          },
        });
      });
  };

  const columns = [
    {
      field: "createdAt",
      headerName: <span className="gridheader_font">Date & Time</span>,
      width: 120,
      renderCell: (params) => (
        <span className="dadata">
          {moment(params.row.createdAt).format("DD - MM - YY")}
          <div
            className="dadata"
            style={{ textAlign: "center", color: "#6A6A6A", fontWeight: 400 }}
          >
            {moment(params.row.createdAt).format("hh:mm:ss")}
          </div>
        </span>
      ),
    },
    {
      field: "baby_medical_record_number",
      headerName: <span className="gridheader_font">BMRN</span>,
      flex: 1,
      width: 170,
      renderCell: (params) => (
        <span className="dadata">{params.row.baby_medical_record_number}</span>
      ),
    },
    {
      field: "mother_name",
      headerName: <span className="gridheader_font">Mother's Name</span>,
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">{params.row.mother_name}</span>
      ),
    },

    {
      field: "entity_type",
      headerName: <span className="gridheader_font">Entity Type</span>,
      width: 200,
      renderCell: (params) => (
        <span className="dadata">{params.row.entity_type}</span>
      ),
    },
    /*  {
      field: "baby_mother_medical_record_number",
      headerName: "MMRN",
      width: 155,

      renderCell: (params) => (
        <span className="dadata">
          {params.row.baby_mother_medical_record_number}
        </span>
      ),
    }, */
    {
      field: "score",
      headerName: <span className="gridheader_font">Score</span>,
      flex: 0.6,
      width: 90,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {!subScription ? (
            <Button onClick={openSubScrDialog}>
              <span
                style={{
                  backgroundColor: params.row.color,
                  borderRadius: 5,
                  padding: 7,
                  width: "40px",
                  color: "#ffffff",
                  opacity: "50%",
                }}
              >
                {params.row.score ? params.row.score.toFixed(1) : ""}
              </span>
            </Button>
          ) : (
            <Button onClick={(e) => scoreAction(e, params)}>
              <span
                style={{
                  backgroundColor: params.row.color,
                  borderRadius: 5,
                  padding: 7,
                  width: "40px",
                  color: "#ffffff",
                }}
              >
                {params.row.score ? params.row.score.toFixed(1) : ""}
              </span>
            </Button>
          )}
        </>
      ),
    },
    {
      field: "reading_id",
      headerName: <span className="gridheader_font">Reading</span>,
      width: 120,
      renderCell: (params) => (
        <span>
          {allowedPermissions &&
          allowedPermissions.bmrn ? (
            <span>
              {!subScription ? (
                <p
                  className="dbtablereading"
                  style={{
                    cursor: "pointer",
                    padding: "7px 0px",
                    width: "40px",
                    opacity: "50%",
                  }}
                  onClick={openSubScrDialog}
                >
                  {"R"}
                  {params.row.reading}
                </p>
              ) : (
                <p
                  className="dbtablereading"
                  style={{
                    cursor: "pointer",
                    padding: "7px 0px",
                    width: "40px",
                  }}
                  onClick={(e) => selectedreading(e, params)}
                >
                  {"R"}
                  {params.row.reading}
                </p>
              )}
            </span>
          ) : (
            <p
              className="dbtablereading"
              style={{ padding: "7px 0px", width: "40px" }}
            >
              {"R"}
              {params.row.reading}
            </p>
          )}
          {allowedPermissions &&
            allowedPermissions.bmrn && (
              <>
              {params.row.discharge_flag == 1 ?(
              <span>
                {!subScription ? (
                  <IconButton
                    style={{ cursor: "pointer" }}
                    className="addicon"
                    onClick={openSubScrDialog}
                  >
                    <AddIcon
                      style={{
                        backgroundColor: "rgba(101, 114, 228, 0.2)",
                        color: "#6572e4",
                        border: "1px solid #6572e4",
                        opacity: "50%",
                      }}
                    />
                  </IconButton> 
                   
                ) : (
                  <IconButton
                    style={{ cursor: "pointer" }}
                    className="addicon"
                    onClick={(e) => addnewreading(e, params)}
                  >
                    <AddIcon
                      style={{
                        backgroundColor: "rgba(101, 114, 228, 0.2)",
                        color: "#6572e4",
                        border: "1px solid #6572e4",
                      }}
                    />
                  </IconButton>
                )}
              </span>
              ) : (
                <p
              className="dbtablereading" 
              style={{
                backgroundColor: "rgba(101, 114, 228, 0.2)",
                color: "#6572e4",
                border: "1px solid #6572e4",
                margin:"0px 10px",
                opacity: "80%",
                padding:"5px 0px",
                width: "30px"
              }}
              //style={{ padding: "7px 0px", width: "40px" }}
            >
              D
            </p>
              )}
              </>
            )}
        </span>
      ),
    },
    {
      field: "created_by_user",
      headerName: <span className="gridheader_font">Created By</span>,
      width: 200,
      renderCell: (params) => (
        <span className="dadata">{params.row.created_by_user}</span>
      ),
    },
    {
      field: "action",
      headerName: <span className="gridheader_font">Action</span>,
      width: 350,
      renderCell: (params) => (
        <strong>
          {!subScription ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#F98F60",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
                opacity: "50%",
              }}
              className="view_Reports"
              onClick={openSubScrDialog}
            >
              View Reports
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#F98F60",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => rptview(params)}
            >
              View Reports
            </Button>
          )}

          {!subScription ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#6572E4",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
                opacity: "50%",
              }}
              onClick={openSubScrDialog}
            >
              View Opinion
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#6572E4",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              onClick={() => readsummary(params)}
            >
              View Opinion
            </Button>
          )}

          {!subScription ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#A3AAEF",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
                opacity: "50%,",
              }}
              onClick={openSubScrDialog}
            >
              View Trend
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#A3AAEF",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              onClick={() => TrendView(params)}
            >
              View Trend
            </Button>
          )}
          {allowedPermissions &&
            allowedPermissions.dashboard &&
            allowedPermissions.dashboard.indexOf("D") != -1 && (
              <span>
                {!subScription ? (
                  <IconButton
                    //style={{ cursor: "pointer" }}
                    className="addicon"
                    onClick={openSubScrDialog}
                    style={{
                      backgroundColor: "rgba(244, 131, 81, 0.15)",
                      cursor: "pointer",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      opacity: "50%",
                    }}
                  >
                    <DeleteImg />
                  </IconButton>
                ) : (
                  <IconButton
                    //style={{ cursor: "pointer" }}
                    className="addicon"
                    onClick={(e) => Deletereading(e, params)}
                    style={{
                      backgroundColor: "rgba(244, 131, 81, 0.15)",
                      cursor: "pointer",
                      marginLeft: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <DeleteImg />
                  </IconButton>
                )}
              </span>
            )}
        </strong>
      ),
    },
  ];

  const SortSelectId = document.getElementById("ward");
  return (
    <React.Fragment>
      {report && (
        <ViewReport
          selectedPatient={selectedPatient}
          open={report}
          onClose={closeDialog}
        />
      )}
      {trend && (
        <ViewTrend
          selectedPatient={selectedPatient}
          open={trend}
          onClose={closeDialog}
        />
      )}
      {open && (
        <ReadingSummary
          selectedPatient={selectedPatient}
          open={open}
          onClose={closeDialog}
        />
      )}
      {deleteselrec && (
        <DeleteRec
          selectedPatient={selectedPatient}
          open={deleteselrec}
          onClose={closeAndReloadDialog}
        />
      )}
      <Grid container>
        <Grid item xs={12} sm={2}>
          <p className="dashboard-title">Medical Records</p>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Select
            labelId="Branch"
            id="Bramch"
            name="Bramch"
            label=""
            size="small"
            className="select_usertype2"
            value={selectedBranch}
            onChange={onBranchSelected}
            disabled={!subScription}
          >
            <MenuItem value="-1">All Branches</MenuItem>
            {branchdata && branchdata.length
              ? branchdata.map((num) => (
                  <MenuItem value={num.hospital_branch_id}>
                    {num.branch_name}
                  </MenuItem>
                ))
              : undefined}
          </Select>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Select
            labelId="Ward"
            id="ward"
            name="ward"
            label=""
            size="small"
            className="select_usertype2"
            value={selectedWard}
            onChange={onWardSelected}
            disabled={!subScription}
          >
            <MenuItem value="-1">All Wards</MenuItem>
            {warddata && warddata.length
              ? warddata.map((num) => (
                  <MenuItem value={num.ward_id}>{num.ward_name}</MenuItem>
                ))
              : undefined}
          </Select>
        </Grid>

        {!is_admin ? (
          <Grid item xs={12} sm={2}>
            <Select
              labelId="Admin"
              id="admin"
              name="admin"
              label=""
              size="small"
              className="select_usertype2"
              value={selectedAdmin}
              onChange={onAdminSelected}
              disabled={!subScription}
            >
              <MenuItem value="-1">All Admins</MenuItem>
              {adminData && adminData.length
                ? adminData.map((num) => (
                    <MenuItem value={num.id}>{num.admin_name}</MenuItem>
                  ))
                : undefined}
            </Select>
          </Grid>
        ) : (
          <Grid item sm={2}></Grid>
        )}

        <Grid item xs={12} sm={2}>
          <Select
            labelId="Ward"
            id="sorting"
            name="sorting"
            label=""
            value={sortOrder}
            size="small"
            className="select_usertype2"
            onChange={onSortSelected}
            disabled={!subScription}
            //onChange={(e) => setSortOrder(JSON.stringify(e.target.value))}
          >
            <MenuItem value="-1">Sort By Date</MenuItem>
            {FILTERORDER.map((type) => (
              <MenuItem value={type.value}>{type.label}</MenuItem>
            ))}
          </Select>
          {/* </label> */}
        </Grid>
        {allowedPermissions &&
          allowedPermissions.bmrn &&
          allowedPermissions.bmrn.indexOf("C") != -1 && (
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              style={{
                justifyContent: "flex-end",
                display: "flex",
                height: "40px",
              }}
            >
              {!subScription ? (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    marginTop: 5,
                    background: "#6572E4",
                    borderRadius: 4,
                    fontSize: 12,
                    padding: "0.4rem 1.5rem",
                    textTransform: "none",
                    color: "#ffffff",
                    opacity: "50%",
                  }}
                  onClick={openSubScrDialog}
                >
                  + Create BMRN
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    marginTop: 5,
                    background: "#6572E4",
                    borderRadius: 4,
                    fontSize: 12,
                    padding: "0.4rem 1.5rem",
                    textTransform: "none",
                  }}
                  onClick={openaddbmrn}
                >
                  + Create BMRN
                </Button>
              )}
            </Grid>
          )}
      </Grid>
      <hr className="tag-line-border" />
      {dataLoaded ? (
        <div style={{ height: 618, width: "100%" }}>
          <DataGrid
            rows={bmrnOptions}
            columns={columns}
            pageSize={10}
            // rowsPerPageOptions={[10]}
            rowsPerPageOptions={[10, 50, { value: -1, label: "All" }]}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            getRowId={(row) => row.patient_id}
            className="tableborder"
            components={{
              Footer: CustomPagination,
              NoRowsOverlay: () => (
                <Stack
                  height="579px"
                  alignItems="center"
                  justifyContent="center"
                >
                  No readings found
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="579px"
                  alignItems="center"
                  justifyContent="center"
                >
                  No readings found
                </Stack>
              ),
            }}
          />
          {/* <Pagination count={10} shape="rounded" /> */}
        </div>
      ) : (
        <div style={{ height: "370px", paddingTop: "230px" }}>
          <CircularProgress color="success" />
        </div>
      )}

      <Dialog
        fullWidth="70%"
        maxWidth={props.maxWidth}
        open={subScrDialog}
        onClose={handleClose}
      >
        <DialogTitle
          style={{
            borderBottom: "0.8px solid #f0f1fc",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Link
              to="/"
              className="FIle-left"
              onClick={handleClose}
              style={{ marginRight: "20px" }}
            >
              <img
                src={Back}
                className="file-img-left"
                alt=""
                style={{ width: "12px" }}
              />
            </Link>
            <span>Subscription Validity</span>
          </div>
          <div>
            <Link to="/" className="FIle-right-extreme" onClick={handleClose}>
              <img src={Close} alt="" />
            </Link>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box>
            <form id="bmrn-form">
              <Card style={{ marginTop: 10 }} variant="none">
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Alert severity="error">
                        <AlertTitle>
                          Your Subscription plan expired. To restart your
                          services please renew your account.
                        </AlertTitle>
                      </Alert>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DashboardScreen;
