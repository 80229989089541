import React, { useState, useEffect } from "react";
import {
  Alert,
  Snackbar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import {
  verifyLoginOtp,
  logindetailsget,
  loginDetailsByAdmin,
  loginResendOTP,
} from "../reducers/loginThunk";
import OtpInput from "react-otp-input";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";
import logo from "../assets/images/avyantra_logo.png";
import prelogo from "../assets/images/presco_logo.png";
import { ToastContainer, toast } from "react-toastify";

import {
  OTPlogin,
  setErrorMessage,
  setsuccessMessage,
} from "../../dataEntry/reducers/patientDataThunk";
import Loginform from "./loginform";

const LoginOTP = (props) => {
  const [OTPForm, setOTPForm] = React.useState("");
  const [OTP, setOTP] = useState("");

  const user = useSelector((state) => state.user.userProfile);
  const patient = useSelector((state) => state.patient);
  const [errMsg, setErrMsg] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(0)
  const [maxSeconds, setMaxSeconds] = useState(1)
  const [attempts, setAttempts] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const maxAttempts = 3;


  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
          clearInterval(interval);
        } else {
          setSeconds(seconds - 1);
        }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 3000);
    }
  }, [patient.errorMessage]);
  useEffect(() => {
    if (patient.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 3000);
    }
  }, [patient.successMessage]);

  const resendOTP = () => {
    setSeconds(19);
    const username = user?.response.username;
    const req = { username };
    dispatch(loginResendOTP(req)).then((resdata) => {
      toast.success(resdata.payload.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //dispatch(setsuccessMessage(resdata.payload.message));
    });
  };

  const handleFormChange = (otp) => {
    const fieldname = otp;

    const fieldvalue = otp;

    const newFormData = { ...OTPForm };
    newFormData[fieldname] = fieldvalue;
    setOTP(otp);
  };
  const loginSubmit = (e) => {
    e.preventDefault();

    const user_id = user?.response?.user_id;
    const req = { user_id, otp: OTP };
    dispatch(verifyLoginOtp(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.error === false) {
          const req = { user_id };
          const is_admin = resdata.response?.is_admin;
          if (is_admin === false) {
            dispatch(loginDetailsByAdmin(req))
              .unwrap()
              .then((resdata) => {
                navigate("/Dashboard");
              });
          } else {
            dispatch(logindetailsget(req))
              .unwrap()
              .then((resdata) => {
                navigate("/Dashboard");
              });
          }
        } else {
          setAttempts(attempts + 1);
          if (attempts > maxAttempts) {
            setMaxSeconds(30);
            //console.log('Maximum attempts reached!',attempts,maxAttempts);
            toast.error(`Max invalid attempts reached, please wait for 30 Seconds.`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setIsTimerActive(true);
            setTimeout(() => {
              setAttempts(0);
              setIsTimerActive(false);
              const username = user?.response.username;
              const req = { username };
              dispatch(loginResendOTP(req)).then((resdata) => {
                toast.success(resdata.payload.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              });
            }, 30000);
            
          } else {
            // Display an error message
            console.log('Incorrect OTP. Please try again.',attempts,maxAttempts);
          
          toast.error(`Invalid OTP. You have ${maxAttempts - attempts} attempts left.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        }
      });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Grid container>
        <Grid item xs={12} sm={12}>
          
        <Grid container style={{
              marginTop: "3%",
            }}>
            <Grid item xs={12} sm={12}>
              <img src={prelogo} className="pre_img" />
              <div className="pre_caption">
                Neonatal Sepsis Prediction Platform
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
        <Box className="login-container">
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              marginTop: "3%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              //columns={{ xs: 1, sm: 12, md: 6 }}
            >
              <Card variant="outlined" className="login_radius">
                <CardContent style={{ padding: 0 }}>
                  <p className="login_welcome">WELCOME!</p>
                  <p className="login_cont">Log in to continue</p>
                  <p
                    className="forgot_cont"
                    style={{
                      fontSize: "15px",
                      marginTop: "10%",
                      fontWeight: 600,
                    }}
                  >
                    Verification Code
                  </p>
                  <p className="forgot_cont">
                    Please enter the verification code that has <br /> been sent
                    to your registered mobile number
                  </p>
                  <form id="mother-medical-form" onSubmit={loginSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className="OTP_Grid">
                        <OtpInput
                          numInputs={6}
                          separator={<span style={{ paddingRight: 10 }}></span>}
                          onChange={(otp) => handleFormChange(otp)}
                          otpType="number"
                          value={OTP}
                          size="Large"
                          id="loginOTP"
                          name="loginOTP"
                          inputStyle={{
                            width: "2.4em",
                            height: "2.4em",
                            borderRadius: "7px",
                            background: "#F6F5FA",
                            border: "0.75px",
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <p className="newAccReDirect">
                        {seconds > 0 ? (
                            <p>
                              Time Remaining: 
                              {seconds < 20 ? `${seconds}` : seconds}
                            </p>
                          ) : (
                            <p>Didn't recieve code?</p>
                          )}
                          <Link
                            onClick={() => seconds > 0 ? "" : resendOTP()}
                            disabled={seconds > 0}
                            style={{
                              color: seconds > 0 ? "#gray" : ""
                            }}

                            className="signup_link"
                          >
                            Resend
                          </Link>
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        className="OTP_Grid"
                        style={{ marginBottom: "10%" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          className="sendcode_button sign-up-button"
                          disabled={isTimerActive}
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Grid>

                    {/* <Grid container spacing={2}>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <p className="newAccReDirect">
                      Don't have an account?
                      <Link to="/Signup" className="signup_link">
                        Sign Up
                      </Link>
                    </p>
                  </Grid>
                </Grid> */}
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <div className="signUpBg">
        <Grid className="copy-posi">
          <p className="Copyright">
            Copyright © 2019-2024 Avyantra Health Technologies. All Rights
            Reserved
          </p>
        </Grid>
      </div>
      {patient.errorMessage && (
        <Snackbar
          open={patient.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {patient.errorMessage}
          </Alert>
        </Snackbar>
      )}
      {patient.successMessage && (
        <Snackbar
          open={patient.successMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {patient.successMessage}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default LoginOTP;
