import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  InputAdornment,
  FormGroup,
} from "@mui/material";
import "../styles/DataEntry.scss";
import { ToastContainer, toast } from "react-toastify";
import {
  setFormTab,
  getMotherProfileByReadingId,
  getBabyProfileByReadingId,
  createBabySemiInvasive,
  updateBabySemiInvasive,
  getBabySemiInvasive,
  getPatientLastReadingData,
  getPatientBasicDetails,
  getBabyBranch,
  getWardsByBranch,
} from "../reducers/patientDataThunk";
import { SKIN_COLOR, CRYSOUND, URINE_OUTPUT } from "../utils/mockData";
import CircularProgress from "@mui/material/CircularProgress";
const Numerics1 = /^[0-9]*$/;
const Numerics = /^[0-9 .]*$/;

const BabySemiInvasive = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);
  const dbbranchdata = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const hospitalId = user.userProfile.hospital_id;
  const hospital_id = user.userProfile.hospital_id;
  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  const reading = patient?.Selectedreadingid;
  const ptbmrn = patient.bmrndata?.baby_medical_record_number;
  const [dischargeFlag, setDischargeFlag] = useState("1");
  const readingId =
    JSON.stringify(reading) != "{}" ? reading : patient?.bmrndata?.reading_id;
  const loggedUserId = user.userProfile?.user_id;
  const adddata = patient.babyHealthParameters;
  const [addFormData, setAddFormData] = useState(adddata || {});
  const [updateRecord, setUpdateRecord] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [warddata, setWardData] = React.useState([]);
  const [
    showFrequencyOfStoolsErrorMessage,
    setShowFrequencyOfStoolsErrorMessage,
  ] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showBPLLErrorMessage, setShowBPLLErrorMessage] = useState();
  const [showBPULErrorMessage, setShowBPULErrorMessage] = useState();
  const [showBPMAErrorMessage, setShowBPMAErrorMessage] = useState();
  const [showHeartRateErrorMessage, setShowHeartRateErrorMessage] = useState();
  const [showBreathingRateErrorMessage, setShowBreathingRateErrorMessage] =
    useState();
  const [
    showOxygenSaturationErrorMessage,
    setShowOxygenSaturationErrorMessage,
  ] = useState();
  const [
    showAbsoluteNeutrophilCountErrorMessage,
    setShowAbsoluteNeutrophilCountErrorMessage,
  ] = useState();
  const [
    showTotalLeucocuteCountErrorMessage,
    setShowTotalLeucocuteCountErrorMessage,
  ] = useState();
  const [
    showBabyBloodGlucoseErrorMessage,
    setShowBabyBloodGlucoseErrorMessage,
  ] = useState();
  const [showCRPLevelErrorMessage, setShowCRPLevelErrorMessage] = useState();
  const [showBilirubinLevelsErrorMessage, setShowBilirubinLevelsErrorMessage] =
    useState();
  const [showChlorineErrorMessage, setShowChlorineErrorMessage] = useState();
  const [showCalciumErrorMessage, setShowCalciumErrorMessage] = useState();
  const [showCreatinineErrorMessage, setShowCreatinineErrorMessage] =
    useState();
  const [showSodiumErrorMessage, setShowSodiumErrorMessage] = useState();
  const [showPotassiumErrorMessage, setShowPotassiumErrorMessage] = useState();
  const [showUreaErrorMessage, setShowUreaErrorMessage] = useState();
  const [
    showThrombocytopeniaErrorMessage,
    setShowThrombocytopeniaErrorMessage,
  ] = useState();

  const [
    showBabyHaemoglobinLevelsErrorMessage,
    setShowBabyHaemoglobinLevelsErrorMessage,
  ] = useState();
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  useEffect(() => {
    const req = { baby_medical_record_number: ptbmrn,hospitalId };

    dispatch(getPatientBasicDetails(req))
      .unwrap()
      .then((resdata) => {
        const disFlag = resdata.response.discharge_flag;
        setDischargeFlag(disFlag);
      });
  }, [ptbmrn]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);


  useEffect(() => {
    if (patient?.bmrndata?.study_id && user.userProfile.hospital_id) {
      dispatch(getBabyBranch({ studyid: patient?.bmrndata?.study_id })).then(
        (data) => {
          if (data?.payload?.response && data?.payload?.response[0]) {
            getWardDetailsByBranch(
              data?.payload?.response[0].hospital_branch_id
            );
          }
        }
      );
    }
  }, [patient?.bmrndata?.study_id, user.userProfile.hospital_id]);

 const getWardDetailsByBranch = (branch_id) => {
    const req = { hospital_id, branch_id };
    dispatch(getWardsByBranch(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.response) {
          setWardData(resdata.response);
        } else {
          setWardData([]);
        }
      });
  };

  
  useEffect(() => {
    const req = { study_id };
    dispatch(getMotherProfileByReadingId(req));
    dispatch(getBabyProfileByReadingId(req));
  },[]);

  useEffect(() => {
    if (patient?.bmrndata?.reading_id && patient.bmrndata?.study_id) {
      updateData(patient?.bmrndata?.reading_id, patient.bmrndata?.study_id);
    }
  }, [patient?.bmrndata?.reading_id, patient.bmrndata?.study_id]);

  const updateData = (readingId, studyid) => {
    const req = { readingId, studyid };

    dispatch(getBabySemiInvasive(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.data.response[0]) {
          let SemiInvData = {
            response: Object.assign({}, resdata.data.response[0]),
          };
          const data =
            SemiInvData.response && SemiInvData.response
              ? SemiInvData.response
              : undefined;
          const data1 =
            SemiInvData.response && SemiInvData.response.groaning
              ? setUpdateRecord(true)
              : setUpdateRecord(false);
          if (SemiInvData.response) {
            setAddFormData(
              SemiInvData.response &&
                JSON.stringify(SemiInvData.response) != "{}"
                ? SemiInvData.response
                : {}
            );
          }
        } else {
          const req = { readingId, studyid };

          const study_id = patient?.bmrndata?.study_id;
          dispatch(getPatientLastReadingData(study_id))
            .unwrap()
            .then((resdata) => {
              let SemiInvData = {
                response: Object.assign(
                  {},
                  resdata.response.baby_investigation,
                  resdata.response.baby_git,
                  resdata.response.baby_appears,
                  resdata.response.baby_cns,
                  resdata.response.baby_resp,
                  resdata.response.baby_cv
                ),
              };
              const data =
                SemiInvData.response && SemiInvData.response
                  ? SemiInvData.response
                  : undefined;
              const data1 =
                SemiInvData.response && SemiInvData.response.groaning
                  ? setUpdateRecord(false)
                  : setUpdateRecord(false);
              if (SemiInvData.response) {
                setAddFormData(
                  SemiInvData.response &&
                    JSON.stringify(SemiInvData.response) != "{}"
                    ? SemiInvData.response
                    : {}
                );
              }
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;
    const fieldvalue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldname] = fieldvalue;
    setAddFormData(newFormData);
  };
  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    await submitData(12);
    /* const req = { study_id };
    dispatch(getMotherProfileByReadingId(req));
    dispatch(getBabyProfileByReadingId(req)); */
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);
  const submitData = async (nextTab) => {
    const newFormData = { ...addFormData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    newFormData.id = "";
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;
    if (!updateRecord) {
      const req = {
        study_id,
        data: newFormData,
        readingId,
      };
      dispatch(createBabySemiInvasive(req))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabySemiInvasive(req)).then(() => {
            if (props.triggerChildFunction) {
              dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
            } else {
              toast.success("Data Updated Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          });
        });
    } else {
      newFormData.reading = patient.Selectedreadingid;
      const req = {
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updateBabySemiInvasive(req)).then((resdata) => {
        const req = { readingId, studyid };
        dispatch(getBabySemiInvasive(req)).then(() => {
          if (props.triggerChildFunction) {
            dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          } else {
            toast.success("Data Updated Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      });
    }
  };

  const verifyBreathingRate = (event) => {
    if (event.target.value >= 1 && event.target.value <= 250) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          breathing_rate: false,
        })
      );
      setShowBreathingRateErrorMessage("");
    } else {
      setShowBreathingRateErrorMessage(
        "Breathing Rate Value Should be Between 1 - 250"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          breathing_rate: true,
        })
      );
    }
  };
  const verifyOxygenSaturation = (event) => {
    if (event.target.value >= 1 && event.target.value <= 100) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          oxygen_saturation: false,
        })
      );
      setShowOxygenSaturationErrorMessage("");
    } else {
      setShowOxygenSaturationErrorMessage(
        "Oxygen Saturation Value Should be Between 1 - 100"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          oxygen_saturation: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureUpperLimb = (event) => {
    if (event.target.value >= 1 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_upper_limb: false,
        })
      );
      setShowBPULErrorMessage("");
    } else {
      setShowBPULErrorMessage("BP (Systolic) Value Should be Between 1 To 200");
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_upper_limb: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureLowerLimb = (event) => {
    if (event.target.value >= 1 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_lower_limb: false,
        })
      );
      setShowBPLLErrorMessage("");
    } else {
      setShowBPLLErrorMessage("BP (Diastolic) Value Should be Between 1 - 200");
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_lower_limb: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureMeanArterial = (event) => {
    if (event.target.value >= 1 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_mean_arterial_bp: false,
        })
      );

      setShowBPMAErrorMessage("");
    } else {
      setShowBPMAErrorMessage(
        "BP (Mean Arterial) Value Should be Between 1 - 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_mean_arterial_bp: true,
        })
      );
    }
  };
  const verifyFrequencyOfStools = (event) => {
    if (event.target.value >= 1 && event.target.value <= 12) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          frequency_of_stools: false,
        })
      );
      setShowFrequencyOfStoolsErrorMessage("");
    } else {
      setShowFrequencyOfStoolsErrorMessage(
        "Frequency Of Stools Value Should be Between 1 - 12"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          frequency_of_stools: true,
        })
      );
    }
  };

  const verifyTotalLeucocytesCount = (event) => {
    if (event.target.value > 100 && event.target.value <= 500000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          total_leucocute_count: false,
        })
      );
      setShowTotalLeucocuteCountErrorMessage("");
    } else {
      setShowTotalLeucocuteCountErrorMessage(
        "Total Leucocytes Count Value Should be Between 100 - 500000."
      );
      //document.getElementById("TotalLeucocytesCount").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          total_leucocute_count: true,
        })
      );
    }
  };
  const verifyAbsoluteNeutrophilCount = (event) => {
    if (event.target.value >= 1 && event.target.value <= 500000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          absolute_neutrophil_count: false,
        })
      );
      setShowAbsoluteNeutrophilCountErrorMessage("");
    } else {
      setShowAbsoluteNeutrophilCountErrorMessage(
        "Absolute Neutrophil Count Value Should be Between 1 - 500000"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          absolute_neutrophil_count: true,
        })
      );
    }
  };

  const verifyBabyBloodGlucose = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_glucose: false,
        })
      );
      setShowBabyBloodGlucoseErrorMessage("");
    } else {
      setShowBabyBloodGlucoseErrorMessage(
        "Baby Blood Glucose Value Should be Between 1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("BabyBloodGlucose").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_glucose: true,
        })
      );
    }
  };

  const verifyBabyHaemoglobinLevels = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0.1,
      maxValue = 45;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_haemoglobin_levels: false,
        })
      );
      setShowBabyHaemoglobinLevelsErrorMessage("");
    } else {
      setShowBabyHaemoglobinLevelsErrorMessage(
        "Baby Haemoglobin Levels Value Should be Between 0.1 - 45, it should have only two decimal positions."
      );
      //document.getElementById("BabyHaemoglobinLevels").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_haemoglobin_levels: true,
        })
      );
    }
  };

  const verifyCReactiveProtien = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_c_reactive_protien_levels: false,
        })
      );
      setShowCRPLevelErrorMessage("");
    } else {
      setShowCRPLevelErrorMessage(
        "C Reactive Protien Value Should be Between 0 - 20, it should have only two decimal positions."
      );
      //document.getElementById("CReactiveProtien").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_c_reactive_protien_levels: true,
        })
      );
    }
  };

  const verifyBilirubinLevels = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 200;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          bilirubin_levels: false,
        })
      );
      setShowBilirubinLevelsErrorMessage("");
    } else {
      setShowBilirubinLevelsErrorMessage(
        "Bilirubin Levels Value Should be Between 0.1 - 200, it should have only two decimal positions."
      );
      //document.getElementById("BilirubinLevels").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          bilirubin_levels: true,
        })
      );
    }
  };

  const verifyChlorine = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0.1,
      maxValue = 200;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          chlorine: false,
        })
      );
      setShowChlorineErrorMessage("");
    } else {
      setShowChlorineErrorMessage(
        "Chlorine Value Should be Between 0.1 - 200, it should have only two decimal positions."
      );
      //document.getElementById("Chlorine").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          chlorine: true,
        })
      );
    }
  };

  const verifyCalcium = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");

    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          calcium: false,
        })
      );
      setShowCalciumErrorMessage("");
    } else {
      setShowCalciumErrorMessage(
        "Calcium Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Calcium").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          calcium: true,
        })
      );
    }
  };

  const verifyCreatinine = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0.1,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          creatinine: false,
        })
      );
      setShowCreatinineErrorMessage("");
    } else {
      setShowCreatinineErrorMessage(
        "Creatinine Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Creatinine").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          creatinine: true,
        })
      );
    }
  };

  const verifySodium = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0.1,
      maxValue = 200;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          sodium: false,
        })
      );
      setShowSodiumErrorMessage("");
    } else {
      setShowSodiumErrorMessage(
        "Sodium Value Should be Between 0.1 - 200, it should have only two decimal positions."
      );
      //document.getElementById("Sodium").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          sodium: true,
        })
      );
    }
  };

  const verifyUrea = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          urea: false,
        })
      );
      setShowUreaErrorMessage("");
    } else {
      setShowUreaErrorMessage(
        "Urea Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          urea: true,
        })
      );
    }
  };

  const verifyPotassium = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");

    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          potassium: false,
        })
      );
      setShowPotassiumErrorMessage("");
    } else {
      setShowPotassiumErrorMessage(
        "Potassium Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Potassium").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          potassium: true,
        })
      );
    }
  };

  const verifyHeartRate = (event) => {
    if (event.target.value >= 1 && event.target.value <= 250) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          heart_rate: false,
        })
      );
      setShowHeartRateErrorMessage("");
    } else {
      setShowHeartRateErrorMessage(
        "Heart Rate Value Should be Between 1 - 250"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          heart_rate: true,
        })
      );
    }
  };

  const verifyThrombocytopenia = (event) => {
    if (event.target.value >= 1 && event.target.value <= 300000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          thrombocytopenia: false,
        })
      );
      setShowThrombocytopeniaErrorMessage("");
    } else {
      setShowThrombocytopeniaErrorMessage(
        "Thrombocytopenia Should be Between 1 - 300000"
      );
      //document.getElementById("Thrombocytopenia").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          thrombocytopenia: true,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {isLoading ? (
        <div
          style={{
            height: "370px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <Card variant="none">
          <CardContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container> <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="ward"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Ward
                    </label>
                    <Select
                      labelId="Ward"
                      id="ward"
                      name="ward_id"
                      label=""
                      size="small"
                      className="dataentry_select_edu"
                      value={addFormData?.ward_id}
                      onChange={handleAddFormChange}
                      disabled={dischargeFlag == "0"}
                    >
                      {/* <MenuItem value="Select Ward">Select Ward</MenuItem> */}
                      {warddata && warddata.length
                        ? warddata.map((num) => (
                            <MenuItem value={num.ward_id}>
                              {num.ward_name}
                            </MenuItem>
                          ))
                        : undefined}
                    </Select>
                  </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Groaning"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Groaning
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    name="groaning"
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    value={addFormData?.groaning}
                    disabled={dischargeFlag == "0"}
                  >
                    <ToggleButton
                      name="groaning"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="groaning"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="groaning"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Oxygen Saturation"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Oxygen Saturation
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <FormGroup row>
                    <TextField
                      id="OxygenSaturation"
                      label=""
                      name="oxygen_saturation"
                      variant="outlined"
                      size="small"
                      className={
                        "dataentry_input " +
                        (errorFields.oxygen_saturation ? "errorField" : "")
                      }
                      value={addFormData?.oxygen_saturation}
                      InputProps={{
                          inputProps: {
                            maxLength: 3,
                          },
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      disabled={dischargeFlag == "0"}
                      onChange={(e) => {
                        handleAddFormChange(e);
                        verifyOxygenSaturation(e);
                      }}
                      error={
                        errorFields.oxygen_saturation &&
                        showOxygenSaturationErrorMessage
                      }
                      errorText={showOxygenSaturationErrorMessage}
                      helperText={
                        errorFields.oxygen_saturation &&
                        showOxygenSaturationErrorMessage
                          ? showOxygenSaturationErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (
                          !Numerics1.test(event.key) &&
                          event.key != "Backspace"
                        ) {
                          event.preventDefault();
                          setShowOxygenSaturationErrorMessage(
                            "Only Numerics are Acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              oxygen_saturation: true,
                            })
                          );
                        }
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Breathing Rate"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Breathing Rate (Breaths Per Minute)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="BreathingRate"
                    name="breathing_rate"
                    variant="outlined"
                    size="small"
                    disabled={dischargeFlag == "0"}
                    className={
                      "dataentry_input " +
                      (errorFields.breathing_rate ? "errorField" : "")
                    }
                    value={addFormData?.breathing_rate}
                    InputProps={{
                        inputProps: {
                          maxLength: 3,
                        },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Per Minute
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBreathingRate(e);
                    }}
                    error={
                      errorFields.breathing_rate &&
                      showBreathingRateErrorMessage
                    }
                    errorText={showBreathingRateErrorMessage}
                    helperText={
                      errorFields.breathing_rate &&
                      showBreathingRateErrorMessage
                        ? showBreathingRateErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBreathingRateErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            breathing_rate: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="HeartRate"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Heart Rate
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="heart_rate"
                    label=""
                    name="heart_rate"
                    variant="outlined"
                    size="small"
                    className="dataentry_input"
                    disabled={dischargeFlag == "0"}
                    value={addFormData?.heart_rate}
                    InputProps={{
                        inputProps: {
                          maxLength: 3,
                        },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          bpm
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyHeartRate(e);
                    }}
                    error={errorFields.heart_rate && showHeartRateErrorMessage}
                    errorText={showHeartRateErrorMessage}
                    helperText={
                      errorFields.heart_rate && showHeartRateErrorMessage
                        ? showHeartRateErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowHeartRateErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            heart_rate: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BP (Systolic)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    BP (Systolic)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="BabyBloodPressureUpperLimb"
                    name="baby_blood_pressure_upper_limb"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_pressure_upper_limb
                        ? "errorField"
                        : "")
                    }
                    variant="outlined"
                    disabled={dischargeFlag == "0"}
                    size="small"
                    value={addFormData?.baby_blood_pressure_upper_limb}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodPressureUpperLimb(e);
                    }}
                    error={
                      errorFields.baby_blood_pressure_upper_limb &&
                      showBPULErrorMessage
                    }
                    errorText={showBPULErrorMessage}
                    helperText={
                      errorFields.baby_blood_pressure_upper_limb &&
                      showBPULErrorMessage
                        ? showBPULErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBPULErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_pressure_upper_limb: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                        inputProps: {
                          maxLength: 3,
                        },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmHg
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BP (Diastolic)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    BP (Diastolic)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="baby_blood_pressure_lower_limb"
                    id="BabyBloodPressureLowerLimb"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_pressure_lower_limb
                        ? "errorField"
                        : "")
                    }
                    variant="outlined"
                    size="small"
                    disabled={dischargeFlag == "0"}
                    value={addFormData?.baby_blood_pressure_lower_limb}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodPressureLowerLimb(e);
                    }}
                    error={
                      errorFields.baby_blood_pressure_lower_limb &&
                      showBPLLErrorMessage
                    }
                    errorText={showBPLLErrorMessage}
                    helperText={
                      errorFields.baby_blood_pressure_lower_limb &&
                      showBPLLErrorMessage
                        ? showBPLLErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBPLLErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_pressure_lower_limb: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                        inputProps: {
                          maxLength: 3,
                        },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmHg
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BP (Mean Arterial)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    BP (Mean Arterial)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="baby_blood_pressure_mean_arterial_bp"
                    id="BabyBloodPressureMeanArterial"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_pressure_mean_arterial_bp
                        ? "errorField"
                        : "")
                    }
                    variant="outlined"
                    disabled={dischargeFlag == "0"}
                    size="small"
                    value={addFormData?.baby_blood_pressure_mean_arterial_bp}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodPressureMeanArterial(e);
                    }}
                    error={
                      errorFields.baby_blood_pressure_mean_arterial_bp &&
                      showBPMAErrorMessage
                    }
                    errorText={showBPMAErrorMessage}
                    helperText={
                      errorFields.baby_blood_pressure_mean_arterial_bp &&
                      showBPMAErrorMessage
                        ? showBPMAErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setshowBPMAErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_pressure_mean_arterial_bp: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                        inputProps: {
                          maxLength: 3,
                        },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mmHg
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="AbdominalDistension"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Abdominal Distension
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.abdominal_dystension}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    disabled={dischargeFlag == "0"}
                  >
                    <ToggleButton
                      name="abdominal_dystension"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="abdominal_dystension"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="abdominal_dystension"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Frequency of Stools)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Frequency of Stools
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="frequency_of_stools"
                    id="FrequencyOfStools"
                    className={
                      "dataentry_input " +
                      (errorFields.frequency_of_stools ? "errorField" : "")
                    }
                    variant="outlined"
                    size="small"
                    disabled={dischargeFlag == "0"}
                    value={addFormData?.frequency_of_stools}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyFrequencyOfStools(e);
                    }}
                    error={
                      errorFields.frequency_of_stools &&
                      showFrequencyOfStoolsErrorMessage
                    }
                    errorText={showFrequencyOfStoolsErrorMessage}
                    helperText={
                      errorFields.frequency_of_stools &&
                      showFrequencyOfStoolsErrorMessage
                        ? showFrequencyOfStoolsErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowFrequencyOfStoolsErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            frequency_of_stools: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        maxLength: 2,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Thrombocytopenia (Platelet Count)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Thrombocytopenia (Platelet Count)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="Thrombocytopenia"
                    label=""
                    name="thrombocytopenia"
                    value={addFormData?.thrombocytopenia}
                    variant="outlined"
                    size="small"
                    disabled={dischargeFlag == "0"}
                    className={
                      "dataentry_input " +
                      (errorFields.thrombocytopenia ? "errorField" : "")
                    }
                    InputProps={{
                        inputProps: {
                          maxLength: 6,
                        },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Count
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyThrombocytopenia(e);
                    }}
                    error={
                      errorFields.thrombocytopenia &&
                      showThrombocytopeniaErrorMessage
                    }
                    errorText={showThrombocytopeniaErrorMessage}
                    helperText={
                      errorFields.thrombocytopenia &&
                      showThrombocytopeniaErrorMessage
                        ? showThrombocytopeniaErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowThrombocytopeniaErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            thrombocytopenia: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Total Leucocytes Count"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Total Leucocytes Count
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="TotalLeucocytesCount"
                        name="total_leucocute_count"
                        label=""
                        variant="outlined"
                        disabled={dischargeFlag == "0"}
                        value={addFormData?.total_leucocute_count}
                        className={
                          "dataentry_input " +
                          (errorFields.total_leucocute_count
                            ? "errorField"
                            : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyTotalLeucocytesCount(e);
                        }}
                        error={
                          errorFields.total_leucocute_count &&
                          showTotalLeucocuteCountErrorMessage
                        }
                        errorText={showTotalLeucocuteCountErrorMessage}
                        helperText={
                          errorFields.total_leucocute_count &&
                          showTotalLeucocuteCountErrorMessage
                            ? showTotalLeucocuteCountErrorMessage
                            : ""
                        }
                        InputProps={{
                            inputProps: {
                              maxLength: 6,
                            },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className="InputAdornment"
                            >
                              mm
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={(event) => {
                          if (
                            !Numerics1.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowTotalLeucocuteCountErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                total_leucocute_count: true,
                              })
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="TotalLeucocytesCountUnit"
                        id="TotalLeucocytesCountUnit"
                        name="total_leucocute_count_unit"
                        value={
                          addFormData?.total_leucocute_count_unit
                            ? addFormData?.total_leucocute_count_unit
                            : "µL"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="µL">µL</MenuItem>
                        <MenuItem value="mL">mL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Absolute Neutrophil Count"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Absolute Neutrophil Count
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="AbsoluteNeutrophilCount"
                        name="absolute_neutrophil_count"
                        label=""
                        variant="outlined"
                        disabled={dischargeFlag == "0"}
                        value={addFormData?.absolute_neutrophil_count}
                        className={
                          "dataentry_input " +
                          (errorFields.absolute_neutrophil_count
                            ? "errorField"
                            : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyAbsoluteNeutrophilCount(e);
                        }}
                        error={
                          errorFields.absolute_neutrophil_count &&
                          showAbsoluteNeutrophilCountErrorMessage
                        }
                        errorText={showAbsoluteNeutrophilCountErrorMessage}
                        helperText={
                          errorFields.absolute_neutrophil_count &&
                          showAbsoluteNeutrophilCountErrorMessage
                            ? showAbsoluteNeutrophilCountErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics1.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowAbsoluteNeutrophilCountErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                absolute_neutrophil_count: true,
                              })
                            );
                          }
                        }}

                    InputProps={{
                      inputProps: {
                        maxLength: 6,
                      },
                    }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="AbsoluteNeutrophilCountUnit"
                        id="AbsoluteNeutrophilCountUnit"
                        name="absolute_neutrophil_count_unit"
                        value={
                          addFormData?.absolute_neutrophil_count_unit
                            ? addFormData?.absolute_neutrophil_count_unit
                            : "µL"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="µL">µL</MenuItem>
                        <MenuItem value="mL">mL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Blood Glucose"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Blood Glucose
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="BabyBloodGlucose"
                        name="baby_blood_glucose"
                        label=""
                        variant="outlined"
                        disabled={dischargeFlag == "0"}
                        value={addFormData?.baby_blood_glucose}
                        className={
                          "dataentry_input " +
                          (errorFields.baby_blood_glucose ? "errorField" : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyBabyBloodGlucose(e);
                        }}
                        error={
                          errorFields.baby_blood_glucose &&
                          showBabyBloodGlucoseErrorMessage
                        }
                        errorText={showBabyBloodGlucoseErrorMessage}
                        helperText={
                          errorFields.baby_blood_glucose &&
                          showBabyBloodGlucoseErrorMessage
                            ? showBabyBloodGlucoseErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowBabyBloodGlucoseErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                baby_blood_glucose: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 5,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="BabyBloodGlucoseUnit"
                        id="BabyBloodGlucoseUnit"
                        name="baby_blood_glucose_unit"
                        value={
                          addFormData?.baby_blood_glucose_unit
                            ? addFormData?.baby_blood_glucose_unit
                            : "mmol/L"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="mmol/L">mmol/L</MenuItem>
                        <MenuItem value="mg/dL">mg/dL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Haemoglobin Level"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Haemoglobin Level
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="BabyHaemoglobinLevels"
                        name="baby_haemoglobin_levels"
                        label=""
                        variant="outlined"
                        disabled={dischargeFlag == "0"}
                        value={addFormData?.baby_haemoglobin_levels}
                        className={
                          "dataentry_input " +
                          (errorFields.baby_haemoglobin_levels
                            ? "errorField"
                            : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyBabyHaemoglobinLevels(e);
                        }}
                        error={
                          errorFields.baby_haemoglobin_levels &&
                          showBabyHaemoglobinLevelsErrorMessage
                        }
                        errorText={showBabyHaemoglobinLevelsErrorMessage}
                        helperText={
                          errorFields.baby_haemoglobin_levels &&
                          showBabyHaemoglobinLevelsErrorMessage
                            ? showBabyHaemoglobinLevelsErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowBabyHaemoglobinLevelsErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                baby_haemoglobin_levels: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 5,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="BabyHaemoglobinLevelsUnit"
                        id="BabyHaemoglobinLevelsUnit"
                        name="baby_haemoglobin_levels_unit"
                        value={
                          addFormData?.baby_haemoglobin_levels_unit
                            ? addFormData?.baby_haemoglobin_levels_unit
                            : "g/dL"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="g/dL">g/dL</MenuItem>
                        <MenuItem value="g/L">g/L</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="C-Reactive Protein Level"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    C-Reactive Protein Level
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="CReactiveProtien"
                        name="baby_c_reactive_protien_levels"
                        label=""
                        disabled={dischargeFlag == "0"}
                        variant="outlined"
                        value={addFormData?.baby_c_reactive_protien_levels}
                        className={
                          "dataentry_input " +
                          (errorFields.baby_c_reactive_protien_levels
                            ? "errorField"
                            : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyCReactiveProtien(e);
                        }}
                        error={
                          errorFields.baby_c_reactive_protien_levels &&
                          showCRPLevelErrorMessage
                        }
                        errorText={showCRPLevelErrorMessage}
                        helperText={
                          errorFields.baby_c_reactive_protien_levels &&
                          showCRPLevelErrorMessage
                            ? showCRPLevelErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowCRPLevelErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                baby_c_reactive_protien_levels: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 5,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="BabyCRPLevelUnit"
                        id="BabyCRPLevelUnit"
                        name="baby_c_reactive_protien_levels_unit"
                        value={
                          addFormData?.baby_c_reactive_protien_levels_unit
                            ? addFormData?.baby_c_reactive_protien_levels_unit
                            : "mg/L"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="mg/L">mg/L</MenuItem>
                        <MenuItem value="mg/dL">mg/dL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Bilirubin level"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Bilirubin level
                    {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="BilirubinLevels"
                        name="bilirubin_levels"
                        label=""
                        variant="outlined"
                        disabled={dischargeFlag == "0"}
                        value={addFormData?.bilirubin_levels}
                        className={
                          "dataentry_input " +
                          (errorFields.bilirubin_levels ? "errorField" : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyBilirubinLevels(e);
                        }}
                        error={
                          errorFields.bilirubin_levels &&
                          showBilirubinLevelsErrorMessage
                        }
                        errorText={showBilirubinLevelsErrorMessage}
                        helperText={
                          errorFields.bilirubin_levels &&
                          showBilirubinLevelsErrorMessage
                            ? showBilirubinLevelsErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowBilirubinLevelsErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                bilirubin_levels: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 6,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="bilirubinUnit"
                        id="bilirubinUnit"
                        name="bilirubin_unit"
                        value={
                          addFormData?.bilirubin_unit
                            ? addFormData?.bilirubin_unit
                            : "mmol/L"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="mmol/L">mmol/L</MenuItem>
                        <MenuItem value="mg/dL">mg/dL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Chloride"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Chlorine Level
                    {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="Chlorine"
                        name="chlorine"
                        label=""
                        variant="outlined"
                        value={addFormData?.chlorine}
                        className={
                          "dataentry_input " +
                          (errorFields.chlorine ? "errorField" : "")
                        }
                        size="small"
                        disabled={dischargeFlag == "0"}
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyChlorine(e);
                        }}
                        error={errorFields.chlorine && showChlorineErrorMessage}
                        errorText={showChlorineErrorMessage}
                        helperText={
                          errorFields.chlorine && showChlorineErrorMessage
                            ? showChlorineErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowChlorineErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                chlorine: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 5,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="chlorineUnit"
                        id="chlorineUnit"
                        name="chlorine_unit"
                        value={
                          addFormData?.chlorine_unit
                            ? addFormData?.chlorine_unit
                            : "mmol/L"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="mmol/L">mmol/L</MenuItem>
                        <MenuItem value="mEq/L">mEq/L</MenuItem>
                        <MenuItem value="mg/dL">mg/dL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Calcium (Ca)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Calcium Level(Ca)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="Calcium"
                        name="calcium"
                        label=""
                        variant="outlined"
                        value={addFormData?.calcium}
                        disabled={dischargeFlag == "0"}
                        className={
                          "dataentry_input " +
                          (errorFields.calcium ? "errorField" : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyCalcium(e);
                        }}
                        error={errorFields.calcium && showCalciumErrorMessage}
                        errorText={showCalciumErrorMessage}
                        helperText={
                          errorFields.calcium && showCalciumErrorMessage
                            ? showCalciumErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowCalciumErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                calcium: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength:6,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="calciumUnit"
                        id="calciumUnit"
                        name="calcium_unit"
                        value={
                          addFormData?.calcium_unit
                            ? addFormData?.calcium_unit
                            : "mmol/L"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="mmol/L">mmol/L</MenuItem>
                        <MenuItem value="mEq/L">mEq/L</MenuItem>
                        <MenuItem value="mg/dL">mg/dL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Sodium (Na)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Sodium Level (Na)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="Sodium"
                        name="sodium"
                        label=""
                        variant="outlined"
                        value={addFormData?.sodium}
                        disabled={dischargeFlag == "0"}
                        className={
                          "dataentry_input " +
                          (errorFields.sodium ? "errorField" : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifySodium(e);
                        }}
                        error={errorFields.sodium && showSodiumErrorMessage}
                        errorText={showSodiumErrorMessage}
                        helperText={
                          errorFields.sodium && showSodiumErrorMessage
                            ? showSodiumErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowSodiumErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                sodium: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 6,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="SodiumUnit"
                        id="SodiumUnit"
                        name="Sodium_unit"
                        value={
                          addFormData?.Sodium_unit
                            ? addFormData?.Sodium_unit
                            : "mEq/L"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="mEq/L">mEq/L</MenuItem>
                        <MenuItem value="mmol/L">mmol/L</MenuItem>
                        <MenuItem value="mg/dL">mg/dL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Potassium (K)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Potassium Level(K)
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="Potassium"
                        name="potassium"
                        label=""
                        variant="outlined"
                        disabled={dischargeFlag == "0"}
                        value={addFormData?.potassium}
                        className={
                          "dataentry_input " +
                          (errorFields.potassium ? "errorField" : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyPotassium(e);
                        }}
                        error={
                          errorFields.potassium && showPotassiumErrorMessage
                        }
                        errorText={showPotassiumErrorMessage}
                        helperText={
                          errorFields.potassium && showPotassiumErrorMessage
                            ? showPotassiumErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowPotassiumErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                potassium: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 5,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="PotassiumUnit"
                        id="PotassiumUnit"
                        name="potassium_unit"
                        value={
                          addFormData?.potassium_unit
                            ? addFormData?.potassium_unit
                            : "mmol/L"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="mmol/L">mmol/L</MenuItem>
                        <MenuItem value="mEq/L">mEq/L</MenuItem>
                        <MenuItem value="mg/dL">mg/dL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Urea"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Urea Level
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="Urea"
                        name="urea"
                        label=""
                        variant="outlined"
                        disabled={dischargeFlag == "0"}
                        value={addFormData?.urea}
                        className={
                          "dataentry_input " +
                          (errorFields.urea ? "errorField" : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyUrea(e);
                        }}
                        error={errorFields.urea && showUreaErrorMessage}
                        errorText={showUreaErrorMessage}
                        helperText={
                          errorFields.urea && showUreaErrorMessage
                            ? showUreaErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowUreaErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                urea: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 5,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="ureaUnit"
                        id="ureaUnit"
                        name="urea_unit"
                        value={
                          addFormData?.urea_unit
                            ? addFormData?.urea_unit
                            : "mmol/L"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="mmol/L">mmol/L</MenuItem>
                        <MenuItem value="mEq/L">mEq/L</MenuItem>
                        <MenuItem value="mg/dL">mg/dL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Creatinine"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Creatinine Level
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="Creatinine"
                        name="creatinine"
                        label=""
                        variant="outlined"
                        disabled={dischargeFlag == "0"}
                        value={addFormData?.creatinine}
                        className={
                          "dataentry_input " +
                          (errorFields.creatinine ? "errorField" : "")
                        }
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyCreatinine(e);
                        }}
                        error={
                          errorFields.creatinine && showCreatinineErrorMessage
                        }
                        errorText={showCreatinineErrorMessage}
                        helperText={
                          errorFields.creatinine && showCreatinineErrorMessage
                            ? showCreatinineErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowCreatinineErrorMessage(
                              "Only Numerics are Acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                creatinine: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 6,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        labelId="creatinineUnit"
                        id="creatinineUnit"
                        name="creatinine_unit"
                        value={
                          addFormData?.creatinine_unit
                            ? addFormData?.creatinine_unit
                            : "mmol/L"
                        }
                        onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select1"
                        disabled={dischargeFlag == "0"}
                      >
                        <MenuItem value="mmol/L">mmol/L</MenuItem>
                        <MenuItem value="mEq/L">mEq/L</MenuItem>
                        <MenuItem value="mg/dL">mg/dL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {isValid ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    className="buttonStyle sign-up-button"
                    type="submit"
                    style={{ cursor: "pointer" }}
                    disabled={dischargeFlag == "0"}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="gray"
                    aria-label="add"
                    className="buttonStyle1 sign-up-button"
                    disabled
                  >
                    Save
                  </Button>
                )}
              </div>
            </form>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};

export default BabySemiInvasive;
