import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Snackbar,
  IconButton,
  CircularProgress,
  Box,
  Fade,
} from "@mui/material";
import Download from "../../common/assets/images/Download.svg";
import { Buffer } from "buffer";
//import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Chart as ChartJS, registerables } from "chart.js";
import Chart from "react-apexcharts";
import { Line } from "react-chartjs-2";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "../../poppins/Poppins-Regular-normal";
import "../../poppins/Poppins-Bold-bold";

import "../../dataEntry/styles/DataEntry.scss";
import { GetSepsisStatusData } from "../reducers/ReportsDataThunk";
import NotificationPdf from "./NotificationPdf";
import ChartLegends from "./ChartLegends";
//import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

const SepsisStatus = () => {
  ChartJS.register(...registerables);
  const user = useSelector((state) => state.user);
  const dbbranchdata = useSelector((state) => state.dashboard);
  const hospital_name = user.userProfile.hospital_name;

  const hospital_id = user.userProfile.hospital_id;

  const branch = useSelector((state) => state.Reports?.SelectedBranchdata);
  //const branch_id = user.branchdata.branch_id;
  const dispatch = useDispatch();
  const [doughnutData, setDoughnutData] = React.useState();
  const [lineChartData, setLineChartData] = React.useState();
  const [chartData, setChartData] = React.useState();
  const [notificationData, setNotificationData] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [enableDownload, setEnableDownload] = React.useState(false);
  const [rangeType, setRangeType] = React.useState("Select Dates");
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(1, "month").format()
  );
  const [toDate, setToDate] = React.useState(moment().format());
  const [fromYear, setFromYear] = React.useState(
    moment().subtract(1, "year").format()
  );
  const [toYear, setToYear] = React.useState(moment().format());
  const [month, setMonth] = React.useState(moment().format());
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (chartData && chartData.length) {
      const labels = [],
        positiveSepsis = [],
        negativeSepsis = [];

      chartData.map((record) => {
        labels.push(record.label);
        positiveSepsis.push(record.positiveSepsis);
        negativeSepsis.push(record.negativeSepsis);
      });

      const LineChartResponse = {
        labels,
        datasets: [
          {
            label: "Positive Sepsis",
            borderColor: "#F48351",
            data: positiveSepsis,
          },
          {
            label: "Negative Sepsis",
            borderColor: "#68D46A",
            data: negativeSepsis,
          },
        ],
      };
      setLineChartData(LineChartResponse);
    }
  }, [chartData]);

  useEffect(() => {
    getReports();
  }, [
    rangeType,
    fromDate,
    toDate,
    month,
    fromYear,
    toYear,
    branch?.hospital_branch_id,
  ]);

  const getReports = () => {
    setLoading(true);
    let params;
    switch (rangeType) {
      case "Select Dates":
        params = {
          range: "custom",
          start: moment(fromDate).format("MM-DD-YYYY"),
          end: moment(toDate).format("MM-DD-YYYY"),
        };
        break;
      case "Monthly":
        params = {
          range: "monthly",
          start: moment(month).format("MM-DD-YYYY"),
        };
        break;
      case "Quarterly":
        params = {
          range: "quarterly",
        };
        break;
      case "Yearly":
        params = {
          range: "yearly",
          start: moment(fromYear).format("MM-DD-YYYY"),
          end: moment(toYear).format("MM-DD-YYYY"),
        };
        break;
    }

    const req = { hospital_id, branch_id: branch.hospital_branch_id, params };
    dispatch(GetSepsisStatusData(req))
      .unwrap()
      .then((resdata) => {
        const doughnutChartData = {
          options: {
            labels: ["Positive Sepsis", "Negative Sepsis"],
            dataLabels: {
              enabled: true,
              style: {
                fontFamily: "Poppins",
              },
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      showAlways: true,
                      show: true,
                      fontFamily: "Poppins",
                      color: "#aeaeae",
                    },
                    value: {
                      fontFamily: "Poppins",
                      fontSize: "24px",
                    },
                  },
                },
              },
            },
            fill: { colors: ["#F48351", "#68D36A"] },
            colors: ["#F48351", "#68D36A"],
            gridLines: {
              display: false,
            },
            legend: {
              show: false,
            },
          },
          series: [
            resdata.response.positiveCount,
            resdata.response.negativeCount,
          ],
        };

        setEnableDownload(
          resdata.response.positiveCount > 0 ||
            resdata.response.negativeCount > 0
            ? true
            : false
        );
        setDoughnutData(doughnutChartData);
        setLoading(false);
        setChartData(resdata.response.graphData);
      });
  };

  const div2PDF = async (e) => {
    e.preventDefault();

    let doughnutChart =
      window.document.getElementsByClassName("doughnutChart")[0];
    const doughnutChartCanvas = await html2canvas(doughnutChart);
    const doughnutChartImage = doughnutChartCanvas.toDataURL("image/png");

    let lineChart =
      window.document.getElementsByClassName("genderLineChart")[0];
    const lineChartCanvas = await html2canvas(lineChart);
    const lineChartImage = lineChartCanvas.toDataURL("image/png");

    const pdf = new jsPDF("l", "pt", "a4");
    pdf.setFont("Poppins");
    pdf.setFillColor(244, 131, 81);
    pdf.rect(50, 90, 740, 40, "F");

    pdf.setFont("Poppins-Bold", "bold");
    pdf.setFontSize(20);
    const heading = "Sepsis Status Report";
    pdf.setTextColor(255, 255, 255);
    pdf.text(heading, 320, 115);

    var avyantraImage = new Image();
    avyantraImage.src = "./assets/avyantra.jpg";
    pdf.addImage(avyantraImage, "jpg", 10, 10, 60, 13);

    pdf.setFontSize(10);
    pdf.setTextColor(135, 135, 135);
    pdf.text(`Generated on: ${moment().format("DD-MM-YY")}`, 700, 20);

    var prescoImage = new Image();
    prescoImage.src = "./assets/presco.png";
    pdf.addImage(prescoImage, "png", 380, 30, 53.5, 14.12);
    pdf.text("Neonatal Sepsis Prediction Platform", 330, 60);

    pdf.setFontSize(15);
    pdf.setTextColor(135, 135, 135);

    let rangeValue;
    switch (rangeType) {
      case "Select Dates":
        rangeValue =
          moment(fromDate).format("DD-MM-YY") +
          " To " +
          moment(toDate).format("DD-MM-YY");
        break;
      case "Yearly":
        rangeValue =
          moment(fromYear).format("YYYY") +
          " To " +
          moment(toYear).format("YYYY");
        break;
      case "Monthly":
        rangeValue =
          moment(month).startOf("month").format("DD-MM-YY") +
          " To " +
          moment(month).endOf("month").format("DD-MM-YY");
        break;
      case "Quarterly":
        rangeValue =
          moment().startOf("quarter").format("DD-MM-YY") +
          " To " +
          moment().endOf("quarter").format("DD-MM-YY");
        break;
    }

    // Calculate the width of each label text
    const labelWidths = {
      "Hospital: ":
        (pdf.getStringUnitWidth("Hospital: ") * pdf.internal.getFontSize()) /
        pdf.internal.scaleFactor,
      "Branch: ":
        (pdf.getStringUnitWidth("Branch: ") * pdf.internal.getFontSize()) /
        pdf.internal.scaleFactor,
      "Range: ":
        (pdf.getStringUnitWidth("Range: ") * pdf.internal.getFontSize()) /
        pdf.internal.scaleFactor,
    };

    const totalWidth =
      labelWidths["Hospital: "] +
      labelWidths["Branch: "] +
      labelWidths["Range: "];
    const availableSpace = pdf.internal.pageSize.getWidth() - 110 - totalWidth; // Total width - x-coordinate of first label

    const spaceBetweenLabels = availableSpace / 2;

    const labelX = {
      "Hospital: ": 55,
      "Branch: ": 55 + spaceBetweenLabels,
      "Range: ":
        pdf.internal.pageSize.getWidth() -
        labelWidths["Range: "] -
        `${rangeType !== "Yearly" ? 230 : 155}`,
    };

    // Set the font to bold
    pdf.setFont("Poppins", "normal");
    pdf.text(`Hospital :`, labelX["Hospital: "], 160);
    pdf.text(`Branch :`, labelX["Branch: "], 160);
    pdf.text(`Range :`, labelX["Range: "], 160);

    pdf.setFont("Poppins-Bold", "bold");
    pdf.text(`${hospital_name}`, labelX["Hospital: "] + 75, 160);
    pdf.text(`${branch.branch_name}`, labelX["Branch: "] + 70, 160);
    pdf.text(`${rangeValue}`, labelX["Range: "] + 70, 160);

    /* pdf.text(`Hospital : ${hospital_name}`, labelX["Hospital: "], 140);
    pdf.text(`Range : ${rangeValue}`, labelX["Range: "], 140);
    pdf.text(`Branch : ${branch.branch_name}`, labelX["Branch: "], 140); */

    pdf.setTextColor(0, 0, 0);
    pdf.addImage(doughnutChartImage, "png", 60, 190, 290, 250);
    pdf.addImage(lineChartImage, "png", 370, 190, 390, 250);
    const blobData = pdf.output("arraybuffer");
    const buffer = Buffer.from(blobData);
    setNotificationData(buffer);
    setDialogOpen(true);
  };

  const lineOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: `${rangeType !== "Select Dates" ? "Year" : "Date"}`,
          // text: `${rangeType === 'Yearly' ? 'Yearly' : 'Date'}`,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const shouldDisableEndDate = (date) =>
    date.get("year") === dayjs().get("year");

  return (
    <>
      <React.Fragment>
        <NotificationPdf
          data={notificationData}
          open={dialogOpen}
          action={setDialogOpen}
          title="Sepsis Status Report"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div>
            <Card variant="none">
              <CardContent>
                <div>
                  <div className="reports_chart_title">
                    {/* <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                          margin: "0px auto",
                          textAlign: "center",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        Sepsis Status Report
                      </Grid>
                    </Grid>
                    <hr /> */}
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={2}
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          fontWeight: "600",
                          marginTop: 30,
                        }}
                      >
                        Sepsis Status
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Grid item xs={12} sm={3} md={3}>
                            <label
                              htmlFor="RANGE"
                              className="dataentry_label1"
                              style={{ display: "block" }}
                            >
                              Range
                            </label>
                            <Select
                              labelId="range"
                              id="range"
                              name="range"
                              label=""
                              size="small"
                              className="dataentry_input"
                              style={{ fontSize: "14px", padding: "3px 0px" }}
                              value={rangeType}
                              onChange={(e) => {
                                setRangeType(e.target.value);
                              }}
                            >
                              <MenuItem value="Select Dates">
                                Select Dates
                              </MenuItem>
                              <MenuItem value="Yearly">Yearly</MenuItem>
                              <MenuItem value="Monthly">Monthly</MenuItem>
                              <MenuItem value="Quarterly">Quarterly</MenuItem>
                            </Select>
                          </Grid>

                          {rangeType === "Quarterly" && (
                            <Grid item xs={12} sm={4}></Grid>
                          )}

                          {rangeType === "Yearly" ? (
                            <>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="From"
                                  className="dataentry_label1"
                                  style={{ display: "block" }}
                                >
                                  From
                                </label>
                                <DatePicker
                                  size="small"
                                  id="from_year"
                                  name="from_year"
                                  views={["year"]}
                                  minDate="01-01-2020"
                                  maxDate={toYear}
                                  value={fromYear}
                                  className="dataentry_input"
                                  onChange={(date) => {
                                    setFromYear(date["$d"]);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      className="dataentry_input"
                                      helperText={null}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                     }}
                                     autocomplete="off"
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="To"
                                  className="dataentry_label1"
                                  style={{ display: "block" }}
                                >
                                  To
                                </label>
                                <DatePicker
                                  size="small"
                                  views={["year"]}
                                  id="to_year"
                                  name="to_year"
                                  value={toYear}
                                  minDate={fromYear}
                                  maxDate={moment().format()}
                                  className="dataentry_input"
                                  // shouldDisableDate={shouldDisableEndDate}
                                  onChange={(date) => {
                                    setToYear(date["$d"]);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      className="dataentry_input"
                                      size="small"
                                      helperText={null}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                     }}
                                     autocomplete="off"
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          ) : null}

                          {rangeType === "Select Dates" ? (
                            <>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="From"
                                  className="dataentry_label1"
                                  style={{ display: "block" }}
                                >
                                  From
                                </label>
                                <DatePicker
                                  id="from_month"
                                  name="from_month"
                                  className="dataentry_input"
                                  size="small"
                                  minDate="01-01-2020"
                                  maxDate={toDate}
                                  value={fromDate}
                                  inputFormat="DD-MM-YYYY"
                                  onChange={(date) => {
                                    setFromDate(date["$d"]);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      className="dataentry_input"
                                      size="small"
                                      helperText={null}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                     }}
                                     autocomplete="off"
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="To"
                                  className="dataentry_label1"
                                  style={{ display: "block" }}
                                >
                                  To
                                </label>
                                <DatePicker
                                  id="to_month"
                                  name="to_month"
                                  size="small"
                                  className="dataentry_input"
                                  minDate={fromDate}
                                  maxDate={moment().format()}
                                  inputFormat="DD-MM-YYYY"
                                  value={toDate}
                                  onChange={(date) => {
                                    setToDate(date["$d"]);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      className="dataentry_input"
                                      size="small"
                                      helperText={null}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                     }}
                                     autocomplete="off"
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          ) : null}

                          {rangeType === "Monthly" ? (
                            <>
                              <Grid item xs={12} sm={3}>
                                <label
                                  htmlFor="From"
                                  className="dataentry_label1"
                                  style={{ display: "block" }}
                                >
                                  Month
                                </label>
                                <DatePicker
                                  id="from_month"
                                  name="from_month"
                                  size="small"
                                  className="dataentry_input"
                                  value={month}
                                  minDate="01-01-2020"
                                  maxDate={moment().format()}
                                  views={["year", "month"]}
                                  inputFormat="DD-MM-YYYY"
                                  onChange={(date) => {
                                    setMonth(date["$d"]);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      className="dataentry_input"
                                      size="small"
                                      helperText={null}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                     }}
                                     autocomplete="off"
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}></Grid>
                            </>
                          ) : null}
                          <Grid item xs={12} sm={2}>
                            <Button
                              variant="contained"
                              size="small"
                              className="report-download-button"
                              disabled={loading || !enableDownload}
                              onClick={(e) => div2PDF(e)}
                            >
                              Download Report
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <hr className="tag-line-border" />
                  {loading ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        height: "480px",
                      }}
                    >
                      <Grid sm={1}>
                        <CircularProgress color="success" />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      {(doughnutData && doughnutData.series[0] !== 0) ||
                      (doughnutData && doughnutData.series[1] !== 0) ? (
                        <>
                          <Grid item xs={12} sm={5} className="doughnutChart">
                            <div className="chart_data">
                              <p className="chart_title">
                                Percentage of Cases of selected interval
                              </p>
                              <div className="chart-body">
                                {doughnutData ? (
                                  <Chart
                                    series={doughnutData.series}
                                    options={doughnutData.options}
                                    type="donut"
                                    width="380"
                                  />
                                ) : null}
                              </div>
                            </div>
                            <ChartLegends
                              labelOne={" Positive Sepsis"}
                              labelTwo={"Negative Sepsis"}
                              colorOne={"#F48352"}
                              colorTwo={"#68D46A"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={7} className="genderLineChart">
                            <div className="chart_data">
                              <p className="chart_title">
                                Sepsis Status of selected interval
                              </p>
                              {lineChartData ? (
                                <Line
                                  data={lineChartData}
                                  options={lineOptions}
                                  className="donut_class"
                                />
                              ) : null}
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              height: "480px",
                            }}
                          >
                            <Grid sm={4}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                  alignItems: "center",
                                  margin: "1rem 0",
                                }}
                              >
                                No data Found
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        </LocalizationProvider>
      </React.Fragment>
    </>
  );
};

export default SepsisStatus;
